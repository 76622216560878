<script>
import AppNotificationItem from '@shared/components/AppNotificationItem.vue';

export default {
  components: { AppNotificationItem },
  props: {
    icons: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      default: null,
    },
    getListAction: {
      type: Function,
      required: true,
    },
    updateAction: {
      type: Function,
      required: true,
    },
    clearAction: {
      type: Function,
      required: true,
    },
  },
  static: {
    icons: {
      PostCommentNotification: {
        pack: 'fas',
        icon: 'comment-dots',
        to: (notif) => ({
          name: 'community_group_post',
          params: {
            uuid: notif.data.group.uuid,
            postUUID: notif.data.post.uuid,
          },
          query: {
            comment: notif.data.uuid,
          },
        }),
      },
      PostNotification: {
        pack: 'fas',
        icon: 'comment-alt',
        to: (notif) => ({
          name: 'community_group_post',
          params: {
            uuid: notif.data.group.uuid,
            postUUID: notif.data.uuid,
          },
        }),
      },
      AbuseNotification: {
        pack: 'fas',
        icon: 'flag',
        to: (notif) => ({
          name: 'community_group_abuses',
          params: { uuid: notif.data.group.uuid },
        }),
      },
    },
  },
  data() {
    return {
      notifs: { data: [] },
      isOpen: false,
      isLoading: false,
    };
  },
  computed: {
    allIcons() {
      return {
        ...this.icons,
        ...this.$options.static.icons,
      };
    },
    btnClass() {
      return {
        'has-background-danger has-text-white': this.nbUnread,
      };
    },
    nbUnread() {
      const { meta } = this.notifs;

      if (!meta) {
        return 0;
      }

      return meta.total_unread;
    },
  },
  beforeMount() {
    this.load();
    this.checkForUpdates();
  },
  destroyed() {
    clearInterval(this.timer);
  },
  watch: {
    isOpen(val) {
      val ? clearInterval(this.timer) : this.checkForUpdates();
    },
  },
  methods: {
    checkForUpdates() {
      clearInterval(this.timer);

      if (!this.user) {
        return;
      }

      this.timer = setInterval(() => this.load(), 300000);
    },
    formatNotifs(data) {
      return data.map((v) => ({ ...v, data: JSON.parse(v.data) }));
    },
    load(reload = true) {
      if (reload) {
        this.isLoading = true;
        this.getListAction()
          .then((notifs) => {
            notifs.data = this.formatNotifs(notifs.data);
            this.notifs = notifs;
          })
          .finally(() => {
            this.isLoading = false;
          });

        return;
      }

      if (this.notifs.links && this.notifs.links.next) {
        this.isLoading = true;
        this.getListAction(this.notifs.meta.current_page + 1)
          .then((notifs) => {
            this.notifs.data = [...this.notifs.data, ...this.formatNotifs(notifs.data)];
            this.notifs.links = notifs.links;
            this.notifs.meta = notifs.meta;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    onNotifClick(notif) {
      this.isOpen = false;

      if (notif.read_at || notif.isLoading) {
        return;
      }

      notif.isLoading = true;
      const loader = this.$buefy.loading.open({ container: this.$refs.list });
      this.updateAction(notif.id, { read: true })
        .then(() => {
          notif.read_at = new Date();
          this.notifs.meta.total_unread--;
        })
        .finally(() => {
          loader.close();
          notif.isLoading = false;
        });
    },
    markAllAsRead() {
      const loader = this.$buefy.loading.open({ container: this.$refs.list });
      this.clearAction()
        .then(() => {
          this.notifs.meta.total_unread = 0;
          this.notifs.data = this.notifs.data.map((notif) => {
            notif.read_at = new Date();
            return notif;
          });
        })
        .finally(() => loader.close());
    },
  },
  i18n: {
    messages: {
      en: {
        notifications: 'Notifications',
        mark_all_as_read: 'Mark all as read',
        see_more_notifications: 'See more notifications',
        no_notifications: 'No notifications',
        loading: 'Loading...',
      },
      fr: {
        notifications: 'Notifications',
        mark_all_as_read: 'Tout marquer comme lu',
        see_more_notifications: 'Voir plus de notifications',
        no_notifications: 'Aucune notification',
        loading: 'Chargement...',
      },
    },
  },
};
</script>

<template>
  <div class="comp">
    <b-tooltip :label="$t('notifications')" position="is-bottom">
      <b-button type="is-text is-custom" :class="btnClass" @click="isOpen = !isOpen">
        <span v-if="nbUnread">{{ nbUnread }}</span>
        <b-icon v-else size="is-medium" pack="far" icon="bell" />
      </b-button>
    </b-tooltip>
    <b-modal
      class="comp_modal"
      :active.sync="isOpen"
      :can-cancel="['escape', 'outside']"
      full-screen
    >
      <div ref="list" class="comp_list px-5 pt-5 pb-20">
        <div class="level is-mobile">
          <div class="level-left">
            <b-button
              type="is-text tdecoration-underline"
              @click="markAllAsRead()"
            >
              {{ $t('mark_all_as_read') }}
            </b-button>
          </div>
          <div class="level-right">
            <b-button
              type="is-text"
              icon-left="times"
              size="is-medium"
              @click="isOpen = false"
            />
          </div>
        </div>

        <template v-if="notifs.data.length">
          <AppNotificationItem
            v-for="n in notifs.data"
            :key="n.id"
            class="mb-2"
            :notif="n"
            :notif-icon="allIcons[n.type]"
            @chose="onNotifClick"
          />

          <b-button
            v-if="notifs.links && notifs.links.next"
            type="is-text"
            class="has-text-centered tdecoration-underline"
            expanded
            :loading="isLoading"
            @click.stop="load(false)"
          >
            {{ $t('see_more_notifications') }}
          </b-button>
          <p v-else class="has-text-centered">
            {{ $t('no_notifications') }}
          </p>
        </template>
        <p v-else class="has-text-centered">
          {{ $t('no_notifications') }}
        </p>
      </div>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
.comp {
  &_modal {
    ::v-deep .animation-content {
      background: transparent;
      pointer-events: none;
    }
  }

  &_list {
    position: relative;
    overflow: auto;
    margin-left: auto;
    height: 100%;
    width: 520px;
    background: $theme_color_white_dark;
    pointer-events: all;

    @include mobile {
      width: 100%;
    }
  }
};
</style>
