<script>
import ContentBlockEmbedSimpleView from '@shared/components/ContentBlocks/Views/EmbedSimple.vue';
import ContentBlockMixin from '@shared/mixins/ContentBlock';

export default {
  components: { ContentBlockEmbedSimpleView },
  mixins: [ContentBlockMixin],
  static: {
    data: {
      url: '',
      caption: '',
    },
  },
  data: () => ({ isForm: true }),
  computed: {
    linkLabel() {
      return `${this.$t('link')} ${this.label}`;
    },
  },
  i18n: {
    messages: {
      en: {
        link: 'Link',
        full_width: 'Full width',
        caption: 'Caption',
      },
      fr: {
        link: 'Lien',
        full_width: 'Pleine largeur',
        caption: 'Légende',
      },
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <b-field :label="linkLabel">
        <b-input v-model="newData.url" required />
      </b-field>
      <div class="field">
        <b-switch v-model="newData.__isWide">
          {{ $t('full_width') }}
        </b-switch>
      </div>
      <b-field :label="$t('caption')">
        <b-input v-model="newData.caption" />
      </b-field>
    </template>
    <template #view>
      <ContentBlockEmbedSimpleView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
