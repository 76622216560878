<script>
import { required, url } from 'vuelidate/lib/validators';
import ContentBlockWithUploadMixin from '@shared/mixins/ContentBlockWithUpload';
import ContentBlockAudioView from '@shared/components/ContentBlocks/Views/Audio.vue';

export default {
  mixins: [ContentBlockWithUploadMixin],
  components: { ContentBlockAudioView },
  i18n: {
    messages: {
      en: {
        audio_source: 'Audio file source',
        upload: 'Upload',
        external_url: 'External URL (Amazon S3, Dropbox, private server...)',
        audio_file: 'Audio file',
        enter_external_url: 'Enter the external URL of the audio file',
        select_audio_file: 'Select an audio file',
        format_mp3: 'Format .mp3',
        max_size: 'max 512 MB',
        caption: 'Caption',
        processing_media: 'Processing media...',
        no_audio: 'No audio',
        delete: 'Delete',
        file_too_large: 'The file exceeds 512 MB (too large). <br> Long audios are hard to follow and load on the page, feel free to split your audio file into several parts.',
      },
      fr: {
        audio_source: 'Source du fichier audio',
        upload: 'Téléversez',
        external_url: 'URL externe (Amazon S3, Dropbox, serveur privé...)',
        audio_file: 'Fichier audio',
        enter_external_url: 'Entrez l\'URL externe du fichier audio',
        select_audio_file: 'Sélectionnez un fichier audio',
        format_mp3: 'Format .mp3',
        max_size: 'max 512 Mo',
        caption: 'Légende',
        processing_media: 'Traitement du média...',
        no_audio: 'Pas de audio',
        delete: 'Supprimer',
        file_too_large: 'Le fichier dépasse 512 Mo (trop volumineux). <br> Les audios trop longs sont durs à suivre et à charger dans la page, n\'hésitez pas à découper votre fichier audio en plusieurs parties.',
      },
    },
  },
  static: {
    data: {
      path: '',
      srcType: 'upload',
      caption: '',
    },
  },
  data: () => ({
    isForm: true,
  }),
  validations() {
    const path = { required };

    if (this.newData.srcType === 'url') {
      path.url = url;
    }

    return {
      newData: {
        path,
        srcType: { required },
      },
    };
  },
  computed: {
    audioSourceLabel() {
      return this.$t('audio_source');
    },
    audioFileLabel() {
      return this.$t('audio_file');
    },
    selectAudioFileLabel() {
      return this.$t('select_audio_file');
    },
    captionLabel() {
      return this.$t('caption');
    },
    deleteLabel() {
      return this.$t('delete');
    },
  },
  methods: {
    handleFile() {
      if (this.local.file.size > this.$constants.MAX_AUDIO_SIZE) {
        this.$buefy.dialog.alert(this.$t('file_too_large'));
        return;
      }

      this.uploadFile(this.viewProps.store, 'audio');
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <div class="columns is-multiline">
        <div class="column is-12">
          <b-field :label="audioSourceLabel" v-bind="$getErrorProps($v.newData.srcType, ['required'])">
            <b-select v-model="newData.srcType" @input="newData.path = ''" expanded>
              <option value="upload">{{ $t('upload') }}</option>
              <option value="url">{{ $t('external_url') }}</option>
            </b-select>
          </b-field>
        </div>
        <div
          class="column"
          :class="{
            'is-narrow': newData.srcType == 'upload',
            'is-6': newData.srcType == 'url',
          }"
        >
          <b-field
            v-if="newData.srcType == 'url'"
            :label="audioFileLabel"
            v-bind="$getErrorProps($v.newData.path, ['required', 'url'])"
          >
            <b-input v-model="newData.path" :placeholder="$t('enter_external_url')" />
          </b-field>
          <b-field v-else :label="audioFileLabel" v-bind="$getErrorProps($v.newData.path, ['required'])">
            <b-upload accept="audio/mp3,audio/mpeg" v-model="local.file" expanded @input="handleFile">
              <b-button tag="a" class="button" icon-left="file-audio" outlined>
                {{ selectAudioFileLabel }}
              </b-button>
            </b-upload>
          </b-field>
          <p class="help is-success">
            {{ $t('format_mp3') }}
            <template v-if="newData.srcType == 'upload'">
              , {{ $t('max_size') }}
            </template>
          </p>
        </div>
        <div class="column">
          <b-field :label="captionLabel">
            <b-input v-model="newData.caption" />
          </b-field>
        </div>
        <div class="column is-12 has-text-centered">
          <template v-if="local.file">
            <b-progress
              v-if="!local.progressValue || local.progressValue < local.progressTotal"
              class="mb-2"
              type="is-success"
              :value="local.progressValue"
              :max="local.progressTotal"
              format="percent"
              show-value
            />
            <p v-else-if="saveIsDisabled">
              {{ $t('processing_media') }}
            </p>
            <p class="is-size-8 mb-5">
              {{ local.file.name }} - {{ localFileSize }} Mo
            </p>
          </template>
          <figure class="p-3 has-background-grey bradius-8">
            <div v-if="newData.path">
              <vue-plyr>
                <audio
                  class="is-block mx-auto bradius-8"
                  :src="localPath"
                />
              </vue-plyr>
            </div>
            <p v-else style="line-height:140px">
              {{ $t('no_audio') }}
            </p>
          </figure>
          <p v-show="newData.path">
            <b-button type="is-text has-text-danger" @click="newData.path = ''">
              {{ deleteLabel }}
            </b-button>
          </p>
        </div>
      </div>
    </template>
    <template #view>
      <ContentBlockAudioView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
