<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    passwordHintIsOpen: false,
  }),
  computed: {
    passwordHasValidations() {
      return {
        digit: (/[0-9]/).test(this.value),
        special: (/[^A-Za-z0-9]/).test(this.value),
        min: this.value.length >= 8,
      };
    },
    placeholderText() {
      return this.$t('base_password_input.placeholder');
    },
  },
  i18n: {
    messages: {
      en: {
        base_password_input: {
          at_least_one_digit: 'Minimum 1 digit',
          at_least_one_special: 'Minimum 1 special character',
          at_least_eight_chars: 'Minimum 8 characters',
          placeholder: 'Minimum 8 characters',
        },
      },
      fr: {
        base_password_input: {
          at_least_one_digit: 'Au moins 1 chiffre',
          at_least_one_special: 'Au moins 1 caractère spécial',
          at_least_eight_chars: 'Au moins 8 caractères',
          placeholder: '8 caractères minimum',
        },
      },
    },
  },
};
</script>

<template>
  <b-tooltip class="is-flex" :active="passwordHintIsOpen" always multilined>
    <template #content>
      <p class="has-text-left">
        <b-icon pack="far" :icon="passwordHasValidations.digit ? 'check-square' : 'square'" />
        {{ $t('base_password_input.at_least_one_digit') }}
        <br>
        <b-icon pack="far" :icon="passwordHasValidations.special ? 'check-square' : 'square'" />
        {{ $t('base_password_input.at_least_one_special') }}
        <br>
        <b-icon pack="far" :icon="passwordHasValidations.min ? 'check-square' : 'square'" />
        {{ $t('base_password_input.at_least_eight_chars') }}
      </p>
    </template>
    <b-input
      :value="value"
      type="password"
      minlength="8"
      maxlength="32"
      :placeholder="placeholderText"
      pattern=".*([^A-Za-z0-9].*[0-9]|[0-9].*[^A-Za-z0-9]).*"
      :has-counter="false"
      required
      password-reveal
      expanded
      v-bind="$attrs"
      @input="$emit('input', $event)"
      @focus="passwordHintIsOpen = true"
      @blur="passwordHintIsOpen = false"
    />
  </b-tooltip>
</template>
