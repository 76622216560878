<script>
import Stripe from '@shared/services/Stripe';
import StripeElementsSplitted from '@shared/components/StripeElementsSplitted.vue';
import APICustomer from '@school/services/API/Customer';

export default {
  components: { StripeElementsSplitted },
  data: () => ({
    isLoading: false,
    stripeError: null,
    stripeIsCompleted: false,
  }),
  computed: {
    store() {
      return this.$store.state.store.data;
    },
    authCustomer() {
      return this.$store.getters['auth/customer'];
    },
    hasPaymentMethod() {
      const customer = this.authCustomer;

      return (
        customer.card_exp_month != null
        && customer.card_exp_year != null
        && customer.card_last4 != null
      );
    },
  },
  methods: {
    handle() {
      if (!this.stripeIsCompleted) {
        return;
      }

      this.isLoading = true;
      this.$refs.card.createPaymentMethod()
        .then((res) => APICustomer.updatePaymentMethod(
          { payment_method: res.paymentMethod.id },
        ))
        .then(({ data: { customer, setup_intent } }) => {
          let promise = Promise.resolve({ data: { customer, setup_intent } });
          const { id: setup_intent_id, status, client_secret } = setup_intent;

          if (status === 'requires_action' || status === 'requires_confirmation') {
            let confirmCardSetup;

            if (this.hasPaymentMethod) {
              confirmCardSetup = Stripe.init(this.$env.stripePK, this.store.stripe_token)
                .then(() => Stripe.confirmCardSetup(client_secret, {
                  payment_method: setup_intent.payment_method,
                }));
            } else {
              confirmCardSetup = this.$refs.card.confirmCardSetup(client_secret, {
                payment_method: {
                  billing_details: {
                    name: `${this.authCustomer.firstname} ${this.authCustomer.lastname || ''}`.trim(),
                  },
                },
              });
            }

            promise = confirmCardSetup.then(() => APICustomer.finalizePaymentMethod({ setup_intent_id }));
          }

          return promise.then(({ data: { customer, setup_intent } }) => {
            this.$store.commit('auth/update', customer);
            this.$refs.card.clear();
            this.$showMessage.success();
            this.$emit('success', { customer, setup_intent });
          });
        })
        .finally(() => (this.isLoading = false));
    },
  },
  i18n: {
    messages: {
      en: {
        update_credit_card: 'Update your credit card',
        current_credit_card: 'Your current credit card',
        card_number: 'Number:',
        expiration: 'Exp:',
        enter_new_credit_card: 'Enter your new credit card',
        update: 'Update',
      },
      fr: {
        update_credit_card: 'Mettre à jour votre carte bancaire',
        current_credit_card: 'Votre carte bancaire actuelle',
        card_number: 'Numéro :',
        expiration: 'Exp :',
        enter_new_credit_card: 'Renseignez votre nouvelle carte bancaire',
        update: 'Modifier',
      },
    },
  },
};
</script>

<template>
  <form class="box" @submit.prevent="handle">
    <h2 class="title is-5">
      {{ $t('update_credit_card') }}
    </h2>
    <div class="notification content">
      <h3 class="is-size-6">
        {{ $t('current_credit_card') }}
      </h3>
      <p class="is-flex is-justify-content-space-between is-flex-wrap-wrap">
        <span>
          <strong class="pr-1">{{ $t('card_number') }}</strong>
          XXXX XXXX XXXX {{ authCustomer.card_last4 }}
        </span>
        <span>
          <strong class="pr-1">{{ $t('expiration') }}</strong>
          {{ authCustomer.card_exp_month > 9 ? authCustomer.card_exp_month : `0${authCustomer.card_exp_month}` }}
          / {{ authCustomer.card_exp_year }}
        </span>
      </p>
    </div>
    <b-field
      :label="$t('enter_new_credit_card')"
      :type="{'is-danger': stripeError && stripeError.message}"
      :message="stripeError && stripeError.message"
    >
      <StripeElementsSplitted
        ref="card"
        :stripe-account="store.stripe_token"
        @error="stripeError = $event"
        @complete="stripeIsCompleted = $event"
      />
    </b-field>
    <div class="buttons">
      <b-button
        type="is-primary"
        native-type="submit"
        :loading="isLoading"
        expanded
      >
        {{ $t('update') }}
      </b-button>
    </div>
  </form>
</template>
