<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import utilsMixin from '@shared/mixins/utils';
import CommunityMemberCard from '@shared/components/CommunityMemberCard.vue';
import CommunityMembersImportForm from '@shared/components/CommunityMembersImportForm.vue';

export default {
  mixins: [utilsMixin],
  components: {
    CommunityMemberCard,
    CommunityMembersImportForm,
  },
  head() {
    return {
      title: this.$t('pages.members.title'),
    };
  },
  data: () => ({
    q: '',
    isLoading: false,
    importFormIsOpen: false,
  }),
  computed: {
    ...mapState('community', {
      currentGroup: 'currentGroup',
      members: 'currentGroupMembers',
    }),
  },
  methods: {
    ...mapMutations('community', [
      'showMemberForm',
    ]),
    ...mapActions('community', [
      'loadMembers',
      'removeMember',
      'removeMembers',
    ]),
    onRemove(member) {
      this.showAsyncConfirm({
        message: this.$t('pages.members.remove.confirmation', {
          name: member.firstname,
        }),
      }, ({ done }) => (
        this.removeMember({ uuid: member.uuid })
          .then(() => done())
      ));
    },
    localLoadMembers(url = null, append = false) {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      const q = this.q || undefined;
      this.loadMembers({ q, url, append })
        .finally(() => (this.isLoading = false));
    },
    removeAllMembers() {
      this.showAsyncConfirm({
        message: this.$t('pages.members.remove.all_confirmation'),
      }, ({ done }) => (
        this.removeMembers()
          .then(() => done())
      ));
    },
    onImportDone() {
      this.importFormIsOpen = false;
      this.localLoadMembers();
    },
  },
  created() {
    this.localLoadMembers();
  },
};
</script>

<template>
  <div class="px-3-mobile">
    <header class="flex-between-center mb-5">
      <h2 class="title is-5">
        {{ $t('pages.members.title') }}
      </h2>

      <nav v-if="isStoreAdmin">
        <b-button
          type="is-primary"
          icon-left="user-plus"
          @click="showMemberForm"
        >
          <span class="is-hidden-mobile">{{ $t('pages.members.add.button') }}</span>
        </b-button>
        <b-dropdown class="ml-3">
          <template #trigger>
            <b-button icon-left="plus">
              <span class="is-hidden-mobile">
                {{ $t('pages.members.actions.title') }}
              </span>
            </b-button>
          </template>
          <b-dropdown-item @click="importFormIsOpen = true">
            {{ $t('pages.members.actions.import') }}
          </b-dropdown-item>
          <b-dropdown-item
            class="has-text-danger"
            @click="removeAllMembers"
          >
            {{ $t('pages.members.actions.remove_all') }}
          </b-dropdown-item>
        </b-dropdown>
      </nav>
    </header>
    <div class="is-relative">
      <p
        v-if="!isLoading && members.data.length <= 0"
        class="box has-text-centered">
        {{ $t('pages.members.empty') }}
      </p>

      <ul v-if="members.data.length > 0">
        <CommunityMemberCard
          v-for="member in members.data"
          :key="member.uuid"
          tag="li"
          :member="member"
          @remove="onRemove"
        />
      </ul>
      <p
        v-if="members.links && members.links.next"
        class="mt-5 has-text-centered"
      >
        <b-button @click="localLoadMembers(members.links.next, true)">
          {{ $t('pages.members.load_more') }}
        </b-button>
      </p>
      <b-loading :active="isLoading" :is-full-page="false" />
    </div>

    <b-modal
      :active="importFormIsOpen"
      :can-cancel="false"
      has-modal-card
      @close="importFormIsOpen = false"
    >
      <CommunityMembersImportForm
        v-if="importFormIsOpen"
        class="mw-640"
        :group="currentGroup"
        @cancel="importFormIsOpen = false"
        @done="onImportDone"
      />
    </b-modal>
  </div>
</template>
