<script>
import anchorme from 'anchorme';
import DOMPurify from 'dompurify';
import { htmlEntities } from '@shared/services/Utils';

const sanitizeComment = (comment) => {
  comment._originalComment = comment._originalComment || comment.comment;
  comment.comment = DOMPurify.sanitize(
    anchorme(htmlEntities(comment._originalComment)),
    { ALLOWED_TAGS: ['a'] },
  );
};
const urlTextToHyperLink = (comment) => {
  sanitizeComment(comment);

  if (comment.children) {
    comment.children.forEach(urlTextToHyperLink);
  }
};

export default {
  props: {
    api: {
      type: Object,
      require: true,
    },
    store: {
      type: Object,
      required: true,
    },
    author: {
      type: Object,
      default: null,
    },
    training: {
      type: Object,
      required: true,
    },
    trainingIdentifier: {
      type: String,
      required: true,
    },
    trainingItem: {
      type: Object,
      default: null,
    },
    trainingItems: {
      type: Array,
      default: null,
    },
  },
  i18n: {
    messages: {
      fr: {
        comments_off: 'La publication de nouveaux commentaires est désactivée.',
        no_comments_yet: 'Il n\'y a pas encore de commentaire.',
        your_comment: 'Votre commentaire',
        send: 'Envoyer',
        cancel: 'Annuler',
        reply: 'Répondre',
        delete: 'Supprimer',
        delete_confirmation: 'Attention ! <br> Cette action est définitive.',
        posted: 'Posté',
        trainer: 'Le Formateur',
        in_lesson: 'Dans la leçon',
        see_more_comments: 'Voir plus de commentaires',
        comments_disabled: 'Les commentaires sont désactivés.',
        manage_comments_info: 'Vous pouvez gérer les commentaires de cette formation depuis votre interface formateur.',
        select_placeholder: 'Que voulez-vous voir ?',
        all_training_comments: 'Tous les commentaires de la formation',
        in_this_lesson: 'Dans cette leçon',
        lesson_prefix: 'Leçon :',
        ok: 'Ok !',
      },
      en: {
        comments_off: 'New comments are not open.',
        no_comments_yet: 'There are no comments yet.',
        your_comment: 'Your comment',
        send: 'Send',
        cancel: 'Cancel',
        reply: 'Reply',
        delete: 'Delete',
        delete_confirmation: 'Warning! <br> This action is permanent.',
        posted: 'Posted',
        trainer: 'Your trainer',
        in_lesson: 'In the lesson',
        see_more_comments: 'See more comments',
        comments_disabled: 'Comments are disabled.',
        manage_comments_info: 'You can manage comments for this training from your trainer interface.',
        select_placeholder: 'What do you want to see?',
        all_training_comments: 'All comments for the training',
        in_this_lesson: 'In this lesson',
        lesson_prefix: 'Lesson:',
        ok: 'Ok!',
      },
    },
  },
  data() {
    return {
      isLoading: false,
      isSending: false,
      isLoadingMore: false,
      entityId: this.trainingItem ? this.trainingItem.id : '',
      comments: { data: [], links: {} },
      newComment: '',
    };
  },
  computed: {
    canSeeComments() {
      return (
        !this.store.feature_options.comments.disabled
        && ['ON', 'LOCKED'].includes(this.training.comments_status)
      );
    },
  },
  methods: {
    loadComments(entityId) {
      if (!this.author || !this.canSeeComments) {
        return;
      }

      this.isLoading = true;
      (entityId ? this.getTrainingItemComments() : this.getTrainingComments())
        .then(this.setComments)
        .finally(() => (this.isLoading = false));
    },
    addComment(commentable = null, entityId = null, parentId = null) {
      commentable = commentable || this;
      const data = { comment: commentable.newComment, parent_id: parentId };

      commentable.isSending = true;
      this.api.addTrainingItemComment(this.trainingIdentifier, entityId, data)
        .then((data) => {
          const comments = (commentable.children || commentable.comments.data);
          const comment = this.setComment(data.data);
          parentId ? comments.push(comment) : comments.unshift(comment);
        })
        .finally(() => {
          commentable.isSending = false;
          commentable.newComment = '';
        });
    },
    setComments(comments, parent, append = false, scrollTo) {
      comments.data = comments.data.map(this.setComment);

      if (parent) {
        comments.data = comments.data.reverse();
        parent.children = append ? [...comments.data, ...parent.children] : comments.data;
      } else {
        append && (comments.data = [...this.comments.data, ...comments.data]);
        this.comments = comments;
      }

      comments.data.forEach(urlTextToHyperLink);

      this.$nextTick(() => (scrollTo && window.scroll(0, scrollTo)));
    },
    setComment(comment) {
      if (!comment.parent_id) {
        comment.isSending = false;
        comment.formIsOpen = false;
        comment.newComment = '';
        comment.page = 1;
        comment.children = comment.children.map(this.setComment).reverse() || [];
      }

      sanitizeComment(comment);

      return comment;
    },
    getTrainingComments() {
      return this.api.getTrainingComments(this.trainingIdentifier);
    },
    getTrainingItemComments() {
      return this.api.getTrainingItemComments(this.trainingIdentifier, this.entityId);
    },
    loadMoreComments(entityId, parent = null) {
      const oldScrollY = window.scrollY;
      let method = entityId || parent ? 'getTrainingItemComments' : 'getTrainingComments';
      method = this.api[method];

      this.isLoadingMore = true;
      let params = [this.comments.links.next];

      if (parent) {
        const page = ++parent.page;
        params = [this.trainingIdentifier, parent.training_item_id, { parent_id: parent.id, page }];
      }

      method(...params)
        .then((data) => this.setComments(data, parent, true, oldScrollY))
        .finally(() => (this.isLoadingMore = false));
    },
    deleteComment(comment, commentable = null) {
      this.$buefy.dialog.confirm({
        message: this.$t('delete_confirmation'),
        type: 'is-danger',
        focusOn: 'cancel',
        confirmText: this.$t('ok'),
        cancelText: this.$t('cancel'),
        onConfirm: () => {
          if (commentable) {
            commentable.children = commentable.children.filter((one) => one.id !== comment.id);
            commentable.children_count -= 1;
          } else {
            this.comments.data = this.comments.data.filter((one) => one.id !== comment.id);
          }

          this.api.deleteTrainingItemComment(
            this.trainingIdentifier, comment.training_item_id, comment.id,
          );
        },
      });
    },
  },
  watch: {
    entityId(val) {
      this.loadComments(val);
    },
  },
  mounted() {
    this.loadComments(this.entityId);
  },
};
</script>

<template>
  <div class="clcomp has-text-left">
    <template v-if="author && canSeeComments">
      <b-field>
        <b-select v-model="entityId" :placeholder="$t('select_placeholder')">
          <option value="">{{ $t('all_training_comments') }}</option>
          <option v-if="trainingItem && !trainingItems" :value="trainingItem.id">
            {{ $t('in_this_lesson') }}
          </option>
          <template v-if="trainingItems">
            <template v-for="item in trainingItems">
              <template v-if="item.type != 'SECTION'">
                <option :key="item.id" :value="item.id">
                  {{ $t('lesson_prefix') }} {{ item.name }}
                </option>
              </template>
              <template v-else>
                <optgroup v-if="item.children.length" :label="item.name" :key="item.id">
                  <option v-for="child in item.children" :key="child.id" :value="child.id">
                    {{ $t('lesson_prefix') }} {{ child.name }}
                  </option>
                </optgroup>
              </template>
            </template>
          </template>
        </b-select>
      </b-field>

      <b-skeleton v-if="isLoading" height="400" />
      <div v-else class="box">
        <p v-if="!comments.data.length" class="media">
          {{ $t('no_comments_yet') }}
        </p>

        <form
          v-if="training.comments_status == 'ON' && entityId"
          class="media"
          @submit.prevent="addComment(null, entityId)">
          <figure class="media-left">
            <p
              class="
                image is-40x40 bradius-4 is-flex is-align-items-center
                is-justify-content-center has-background-light is-custom
              "
            >
              <img
                v-if="author.picture"
                class="ofit-cover w-full h-full"
                :src="author.picture"
                :alt="author.firstname"
              />
              <span v-else>{{ author.firstname[0] }}</span>
            </p>
          </figure>
          <div class="media-content">
            <b-field>
              <b-input
                type="textarea"
                rows="2"
                :placeholder="$t('your_comment')"
                :disabled="isSending"
                v-model.trim="newComment"
              />
            </b-field>
            <p class="buttons">
              <b-button
                native-type="submit"
                type="is-primary is-custom"
                :loading="isSending"
                :disabled="!newComment"
              >
                {{ $t('send') }}
              </b-button>
            </p>
          </div>
        </form>
        <div v-else-if="training.comments_status != 'ON'" class="notification is-warning mt-3">
          <b-icon class="flex-none mr-3" icon="info-circle" />
          <span v-t="'comments_off'" />
        </div>

        <article v-for="comment in comments.data" :key="comment.id" class="media">
          <figure class="media-left">
            <p
              class="
                image is-40x40 bradius-4 is-flex is-align-items-center
                is-justify-content-center has-background-light is-custom
              "
            >
              {{ comment.comment_creator[0] }}
            </p>
          </figure>
          <div class="media-content">
            <div class="content break-words">
              <h3 class="is-size-8 mb-2 mt-0">
                {{ comment.comment_creator }}
              </h3>
              <p class="wspace-preline" v-html="comment.comment" />
              <p class="columns is-size-9">
                <span class="column">
                  <template v-if="comment.is_teacher">
                    <span class="has-text-primary is-custom">{{ $t('trainer') }}</span> •
                  </template>
                  <span>{{ $t('posted') }} {{ comment.created_at | momentFromUTC | moment('from') }}</span>
                  <template v-if="!entityId">
                    •
                    {{ $t('in_lesson') }} <span class="has-text-primary is-custom">{{ comment.training_item_name }}</span>
                  </template>
                </span>
                <span v-if="comment.is_comment_creator" class="column is-narrow">
                  <a
                    class="color-inherit tdecoration-underline"
                    href="#"
                    @click.prevent="deleteComment(comment)">
                    {{ $t('delete') }}
                  </a>
                </span>
              </p>
              <p v-show="comment.children.length < comment.children_count" class="media">
                <b-button
                  type="is-text"
                  class="is-custom tdecoration-underline is-shadowless"
                  :loading="isLoadingMore"
                  expanded
                  @click="loadMoreComments(entityId, comment)"
                >
                  {{ $t('see_more_comments') }}
                </b-button>
              </p>
            </div>

            <article v-for="subcomment in comment.children" :key="subcomment.id" class="media">
              <figure class="media-left">
                <p
                  class="
                    image is-40x40 bradius-4 is-flex is-align-items-center
                    is-justify-content-center has-background-light is-custom
                  "
                >
                  {{ subcomment.comment_creator[0] }}
                </p>
              </figure>
              <div class="media-content">
                <div class="content break-words">
                  <h3 class="is-size-8 mb-2">
                    {{ subcomment.comment_creator }}
                  </h3>
                  <p class="wspace-preline" v-html="subcomment.comment" />
                  <p class="columns is-size-9">
                    <span class="column">
                      <template v-if="subcomment.is_teacher">
                        <span class="has-text-primary is-custom">{{ $t('trainer') }}</span> •
                      </template>
                      {{ $t('posted') }} {{ subcomment.created_at | momentFromUTC | moment('from') }}
                    </span>

                    <span v-if="subcomment.is_comment_creator" class="column is-narrow">
                      <a
                        class="color-inherit decoration-underline"
                        href="#"
                        @click.prevent="deleteComment(subcomment, comment)">
                        {{ $t('delete') }}
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </article>

            <template v-if="training.comments_status == 'ON'">
              <p v-if="!comment.formIsOpen" class="mt-5 is-size-8">
                <a href="#" @click.prevent="comment.formIsOpen = true">
                  <b-icon icon="comment" />
                  {{ $t('reply') }}
                </a>
              </p>

              <form
                class="media"
                v-show="comment.formIsOpen"
                @submit.prevent="addComment(comment, comment.training_item_id, comment.id)"
              >
                <figure class="media-left">
                  <p
                    class="
                      image is-40x40 bradius-4 is-flex is-align-items-center
                      is-justify-content-center has-background-light is-custom
                    "
                  >
                    <img
                      v-if="author.picture"
                      class="ofit-cover w-full h-full"
                      :src="author.picture"
                      :alt="author.firstname"
                    />
                    <span v-else>{{ author.firstname[0] }}</span>
                  </p>
                </figure>
                <div class="media-content">
                  <b-field>
                    <b-input
                      type="textarea"
                      rows="2"
                      :placeholder="$t('your_comment')"
                      :disabled="comment.isSending"
                      v-model.trim="comment.newComment"
                    />
                  </b-field>
                  <p class="buttons">
                    <b-button
                      native-type="submit"
                      type="is-primary is-custom"
                      :loading="comment.isSending"
                      :disabled="!comment.newComment"
                    >
                      {{ $t('send') }}
                    </b-button>
                    <b-button type="is-text" @click="comment.formIsOpen = false">
                      {{ $t('cancel') }}
                    </b-button>
                  </p>
                </div>
              </form>
            </template>
            <div v-else class="notification is-warning mt-3">
              <b-icon class="flex-none mr-3" icon="info-circle" />
              <span v-t="'comments_off'" />
            </div>
          </div>
        </article>

        <p v-show="comments.links.next" class="media">
          <b-button
            type="is-text"
            class="is-custom tdecoration-underline is-shadowless"
            :loading="isLoadingMore"
            expanded
            @click="loadMoreComments(entityId)"
          >
            {{ $t('see_more_comments') }}
          </b-button>
        </p>
      </div>
    </template>
    <template v-else-if="author && !canSeeComments">
      <div class="notification is-warning is-flex">
        <b-icon class="flex-none mr-3" icon="info-circle" />
        <span>
          {{ $t('comments_disabled') }}
        </span>
      </div>
    </template>
    <div v-else class="notification is-warning is-flex">
      <b-icon class="flex-none mr-3" icon="info-circle" />
      <span>
        {{ $t('manage_comments_info') }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.clcomp {
  a {
    color: inherit;
  }
}
</style>
