<script>
import transform from 'lodash/transform';
import blockTypes from '@shared/assets/data/training_item_content_block_types';
import EditorContentBlockSelector from '@shared/components/EditorContentBlockSelector.vue';
import { editorWithBlocks } from '@app/mixins/trainings';
import EditorLayout from '@app/layouts/EditorLayout.vue';
import TrainingItemResourcesEditor from '@app/components/TrainingItemResourcesEditor.vue';
import TrainingItemEditorStatusField from '@app/components/TrainingItemEditorStatusField.vue';

export default {
  mixins: [editorWithBlocks],
  components: {
    EditorContentBlockSelector,
    TrainingItemResourcesEditor,
    TrainingItemEditorStatusField,
    EditorLayout,
    ...transform(blockTypes, (result, v, k) => (result[`ContentBlock${k}`] = v.component), {}),
  },
  i18n: {
    messages: {
      fr: {
        statusOption: {
          draft: 'Mettre la leçon en brouillon',
          published: 'Publier la leçon',
        },
      },
    },
  },
  static: { blockTypes },
  data() {
    return {
      trainingItem: {
        id: null,
        parent_id: null,
        type: 'GENERIC',
        name: '',
        draft: false,
        min_complete_duration: 0,
      },
      showMinCompleteDuration: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    this.handleBeforeRouteLeave(to, from, next);
  },
  methods: {
    save(silent = false) {
      this.onSave(silent, (trainingItem) => {
        if (!this.showMinCompleteDuration) {
          trainingItem.min_complete_duration = 0;
        }

        return trainingItem;
      });
    },
  },
};
</script>

<template>
  <EditorLayout class="compe" ref="layout" :trainingItem="trainingItem">
    <template #sidenav>
      <b-skeleton v-if="initialLoading" height="300" />
      <b-tabs v-else v-model="activeTab" size="is-small" :animated="false" expanded>
        <b-tab-item label="Leçon">
          <b-field label="Renseignez le titre de cette leçon">
            <b-input
              ref="trainingItemName"
              v-model="trainingItem.name"
              :has-counter="false"
              maxlength="128"
              required
              autofocus
              @keyup.native.enter="isDirty && dataIsValid(isLoading) && save()"
            />
          </b-field>
          <b-field v-if="isNotPercentPlan">
            <b-switch class="w-full" size="is-small" v-model="showMinCompleteDuration" @input="isDirty = true">
              Définir la durée minimum
              <b-tooltip class="ml-3" position="is-left" multilined>
                <template #content>
                  <p>
                    En cochant cette option, vous allez définir le temps minimum
                    que vos apprenants doivent passer sur cette leçon.
                  </p>
                </template>
                <b-icon icon="question-circle" pack="far" />
              </b-tooltip>
              <AppFeatureBadge class="ml-2" />
            </b-switch>
          </b-field>
          <b-field v-if="isNotPercentPlan && showMinCompleteDuration" label="Renseignez la durée (en minutes)">
            <b-input
              v-model.number="trainingItem.min_complete_duration"
              type="number"
              step="1"
              min="1"
              required
              @keyup.native.enter="isDirty && dataIsValid(isLoading) && save()"
            />
          </b-field>
          <TrainingItemEditorStatusField
            class="field"
            v-model="trainingItem.draft"
          >
            <template #draft>
              <span v-t="'statusOption.draft'" />
            </template>
            <template #published>
              <span v-t="'statusOption.published'" />
            </template>
          </TrainingItemEditorStatusField>

          <draggable v-if="contentBlocks.length" v-model="contentBlocks">
            <b-button
              v-for="contentBlock in contentBlocks"
              :key="contentBlock.id"
              class="mb-2 cursor-grab is-justify-content-flex-start"
              type="is-text border-dashed"
              :icon-left="$options.static.blockTypes[contentBlock.type].icon"
              :icon-pack="$options.static.blockTypes[contentBlock.type].pack"
              :label="$t($options.static.blockTypes[contentBlock.type].text)"
              expanded
              @click.prevent="scrollToBlock(contentBlock)"
            />
          </draggable>

          <b-button type="is-text" icon-left="plus" expanded @click="activeTab = 1">
            Ajouter un bloc
          </b-button>
        </b-tab-item>
        <b-tab-item label="Blocs">
          <h2 class="title is-8 has-text-centered has-text-weight-normal">
            Cliquez sur un des blocs ci-dessous pour ajouter du contenu
            à cette leçon.
          </h2>

          <EditorContentBlockSelector :autofocus="activeTab == 1" @add="addBlock" />
        </b-tab-item>
        <b-tab-item label="Annexes">
          <TrainingItemResourcesEditor
            :training="currentTraining"
            :training-item="trainingItem"
            @change="onResourceChange"
            @delete="onResourceDelete"
          />
        </b-tab-item>
      </b-tabs>
    </template>
    <template #header_right>
      <b-button
        v-if="pageURL"
        type="is-text"
        size="is-small"
        icon-left="eye"
        @click="onViewDraft">
        Voir la page
      </b-button>
      <b-tooltip label="Déjà sauvegardé !" position="is-bottom" :active="!!(!isDirty && trainingItem.id)">
        <b-button
          type="is-primary"
          size="is-small"
          native-type="submit"
          :loading="isLoading"
          :disabled="!isDirty"
          @click="dataIsValid(isLoading) && save()"
        >
          Sauvegarder
        </b-button>
      </b-tooltip>
    </template>
    <template #footer>
      <b-button
        type="is-primary"
        native-type="submit"
        :loading="isLoading"
        :disabled="!isDirty"
        @click="dataIsValid(isLoading) && save()"
      >
        Sauvegarder les modifications
      </b-button>
    </template>
    <template #main>
      <b-skeleton v-if="initialLoading" height="500" />
      <div v-else>
        <div class="container mb-6">
          <h1 class="title is-2 has-text-centered">
            {{ trainingItem.name }}
          </h1>
        </div>
        <draggable
          v-if="contentBlocks.length"
          v-model="contentBlocks"
          handle=".handle"
        >
          <div
            v-for="(contentBlock, k) in contentBlocks"
            :key="contentBlock.id"
            :class="{
              container: contentBlock.isEditing || !contentBlock.data.__isWide
            }"
          >
            <Component
              :is="`ContentBlock${contentBlock.type}`"
              :ref="`block${contentBlock.id}`"
              :contentBlock="contentBlock"
              :meta="{position: k, total: contentBlocks.length}"
              :label="$t($options.static.blockTypes[contentBlock.type].text)"
              :viewProps="{
                type: contentBlock.type,
                store: authStore,
                training: currentTraining,
                trainingItem: trainingItem,
                trainingItems: currentTraining.items,
              }"
              :training="currentTraining"
              :trainingItem="trainingItem"
              :notification="$options.static.blockTypes[contentBlock.type].notification"
              :notificationClass="$options.static.blockTypes[contentBlock.type].notificationClass"
              @cancel="cancelBlock(contentBlock)"
              @remove="removeBlock(contentBlock)"
              @save="saveBlock(contentBlock, $event)"
              @edit="editBlock(contentBlock)"
              @moveup="moveBlockUp(contentBlock, k)"
              @movedown="moveBlockDown(contentBlock, k)"
            />
          </div>
        </draggable>
        <div class="border-dashed bradius-4 py-20 has-text-centered">
          <p>
            Cliquez sur un des blocs disponibles dans la liste <br>
            à gauche ou ci-dessous pour ajouter du contenu à cette leçon.
          </p>
          <p class="mt-5">
            <button
              type="button"
              class="compe_btn bradius-4 cursor-pointer"
              @click="addBlock('video')"
            >
              <b-icon :icon="$options.static.blockTypes.video.icon" size="is-large" />
              <br />
              <span class="is-size-7">
                {{ $t($options.static.blockTypes.video.text) }}
              </span>
            </button>
            <button
              type="button"
              class="compe_btn ml-3 bradius-4 cursor-pointer"
              @click="addBlock('richtext')"
            >
              <b-icon :icon="$options.static.blockTypes.richtext.icon" size="is-large" />
              <br />
              <span class="is-size-7">
                {{ $t($options.static.blockTypes.richtext.text) }}
              </span>
            </button>
            <button
              type="button"
              class="compe_btn ml-3 bradius-4 cursor-pointer"
              @click="addBlock('image')"
            >
              <b-icon :icon="$options.static.blockTypes.image.icon" size="is-large" />
              <br />
              <span class="is-size-7">
                {{ $t($options.static.blockTypes.image.text) }}
              </span>
            </button>
            <button
              type="button"
              class="compe_btn ml-3 bradius-4 cursor-pointer"
              @click="addBlock('url')"
            >
              <b-icon :icon="$options.static.blockTypes.url.icon" size="is-large" />
              <br />
              <span class="is-size-7">
                {{ $t($options.static.blockTypes.url.text) }}
              </span>
            </button>
            <button
              type="button"
              class="compe_btn ml-3 bradius-4 cursor-pointer"
              @click="activeTab = 1"
            >
              <b-icon icon="plus" size="is-large" />
              <br />
              <span class="is-size-7">
                Plus
              </span>
            </button>
          </p>
        </div>
      </div>
    </template>
  </EditorLayout>
</template>

<style lang="scss" scoped>
.compe {
  .container {
    max-width: 728px;
  }

  &_btn {
    height: 100px;
    width: 112px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: $white;

    &:hover {
      color: $white;
      background: $primary;
    }
  }
}
</style>
