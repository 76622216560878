<script>
import { timecode } from '@shared/config/vuelidate';
import ContentBlockEmbedView from '@shared/components/ContentBlocks/Views/Embed.vue';
import ContentBlockMixin from '@shared/mixins/ContentBlock';
import ContentBlockPartialTimecodes from '@shared/components/ContentBlocks/ContentBlockPartialTimecodes.vue';

export default {
  components: {
    ContentBlockEmbedView,
    ContentBlockPartialTimecodes,
  },
  mixins: [ContentBlockMixin],
  i18n: {
    messages: {
      en: {
        youtube_live_notification: 'To create your "{label}", use a streaming software like OBS (free and available {here})',
        chatgpt_notification: 'To share a conversation with ChatGPT, click on the conversation share icon and insert the link below.',
        general_notification: 'Remember to make your "{label}" content accessible from the outside (hidden, unlisted, public...)',
        link_label: '{label} Link *',
        caption: 'Caption',
        enable_youtube_chat: 'Enable YouTube chat',
        default_player_appearance: 'Default {player} player appearance',
        here: 'here',
      },
      fr: {
        youtube_live_notification: 'Pour créer votre "{label}", utilisez un logiciel de streaming comme OBS (gratuit et accessible {here})',
        chatgpt_notification: 'Pour partager une conversation avec ChatGPT, cliquez sur le pictogramme de partage de la conversation et insérez le lien ci-dessous.',
        general_notification: 'Pensez à rendre votre contenu "{label}" accessible depuis l\'exterieur (masqué, non répertorié, public...)',
        link_label: 'Lien {label} *',
        caption: 'Légende',
        enable_youtube_chat: 'Activer le tchat YouTube',
        default_player_appearance: 'Apparence par défaut du lecteur {player}',
        here: 'ici',
      },
    },
  },
  static: {
    data: {
      url: '',
      caption: '',
      nativePlayer: false,
      hasChatEnabled: false,
      timecodes: [],
      __isWide: false,
    },
  },
  data: () => ({ isForm: true }),
  computed: {
    isYTOrVimeo() {
      return ['youtube', 'vimeo']
        .includes(this.contentBlock.type);
    },
    isYTOrYTLive() {
      return ['youtube', 'youtube_live']
        .includes(this.contentBlock.type);
    },
    isYTOrYTLiveOrVimeo() {
      return ['youtube', 'youtube_live', 'vimeo']
        .includes(this.contentBlock.type);
    },
    linkLabel() {
      return this.$t('link_label', { label: this.label });
    },
    defaultPlayerAppearance() {
      return this.$t('default_player_appearance', { player: this.isYTOrYTLive ? 'YouTube' : 'Vimeo' });
    },
    notificationMessage() {
      if (this.contentBlock.type === 'youtube_live') {
        return this.$t('youtube_live_notification', {
          label: this.label,
          here: `<a href="https://obsproject.com/fr" target="_blank">${this.$t('here')}</a>`,
        });
      }
      if (this.contentBlock.type === 'chatgpt') {
        return this.$t('chatgpt_notification');
      }
      return this.$t('general_notification', { label: this.label });
    },
  },
  validations: {
    newData: {
      timecodes: {
        $each: {
          time: {
            timecode,
          },
        },
      },
    },
  },
  methods: {
    handle() {
      if (this.contentBlock.type === 'youtube_live') {
        this.newData.__isWide = this.newData.hasChatEnabled;
        console.log(this.newData.__isWide);
      }

      return Promise.resolve();
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <p v-if="contentBlock.type == 'youtube_live'" class="notification" v-html="notificationMessage" />
      <p v-else-if="contentBlock.type == 'chatgpt'" class="notification">
        {{ $t('chatgpt_notification') }}
      </p>
      <p v-else class="notification">
        {{ notificationMessage }}
      </p>
      <b-field :label="linkLabel">
        <b-input v-model="newData.url" required />
      </b-field>
      <b-field :label="$t('caption')">
        <b-input v-model="newData.caption" />
      </b-field>
      <div v-if="contentBlock.type == 'youtube_live'" class="field">
        <b-switch v-model="newData.hasChatEnabled">
          {{ $t('enable_youtube_chat') }}
        </b-switch>
      </div>
      <div v-if="isYTOrYTLiveOrVimeo" class="field">
        <b-switch v-model="newData.nativePlayer">
          {{ defaultPlayerAppearance }}
        </b-switch>
      </div>
      <ContentBlockPartialTimecodes
        v-if="isYTOrVimeo && !newData.nativePlayer"
        v-model="newData.timecodes"
        :v="$v.newData.timecodes"
      />
    </template>
    <template #view>
      <ContentBlockEmbedView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
