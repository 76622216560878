<script>
import utilsMixin from '@shared/mixins/utils';
import { mapGetters, mapState } from 'vuex';

export default {
  mixins: [utilsMixin],
  computed: {
    ...mapState('store', {
      store: 'data',
    }),
    ...mapGetters('auth', {
      authCustomer: 'customer',
    }),
    commissionRate() {
      let amount = this.store.affiliation_options.default_commission_amount;
      let percentage = this.store.affiliation_options.default_commission_percentage;
      const applications = this.authCustomer?.affiliate_data?.applications;

      if (applications?.store) {
        const hasSpecific = !!(
          applications.store_amount
          || applications.store_percentage
        );

        amount = hasSpecific ? applications.store_amount : amount;
        percentage = hasSpecific ? applications.store_percentage : percentage;
      }

      return amount ? `${amount}€` : `${percentage}%`;
    },
  },
  i18n: {
    messages: {
      en: {
        commission_per_sale: 'Standard commission per sale (specific exceptions may apply):',
        commission_tooltip_1: 'This is the amount or percentage you will earn for each sale made through your affiliate link.',
        commission_tooltip_2: 'The trainer may define specific commission rates on a case-by-case basis.',
        commission_tooltip_3: 'You can view the commissions applicable to your links by navigating to Affiliation > Profile & Links in your account.',
        tracking_link_validity: 'Tracking link validity duration of',
        tracking_link_tooltip: 'When a visit is generated through one of your affiliate links, the sale will be attributed to you if it occurs within the tracking link\'s validity period.',
        minimum_payout_delay: 'Minimum delay before a payment request:',
        minimum_payout_delay_tooltip: 'This is the minimum time required between the generation of a commission and the moment you can request the payment for it.',
        minimum_payout_amount: 'Minimum payment amount:',
        minimum_payout_amount_tooltip: 'This is the minimum amount of commissions you must collect before you can request payment.',
        sale_attribution_rule: 'Sale attribution based on the',
        first_clicked_link: 'first clicked link',
        last_clicked_link: 'last clicked link',
        sale_attribution_rule_tooltip: 'If a sale is concluded after visits from multiple affiliate links, this rule determines which affiliate will be credited for the sale.',
        day: 'day | days',
      },
      fr: {
        commission_per_sale: 'Commission par vente (sauf cas particuliers) :',
        commission_tooltip_1: 'Il s\'agit du montant ou du pourcentage qui vous sera versé en cas de vente via vos liens d\'affiliation.',
        commission_tooltip_2: 'Le formateur peut également définir ces commissions au cas par cas.',
        commission_tooltip_3: 'Vous pouvez vérifier les commissions qui s\'appliquent à vos liens dans Affiliation > Profil & liens.',
        tracking_link_validity: 'Durée de validité du lien de tracking :',
        tracking_link_tooltip: 'Lorsqu\'une visite est générée à partir d\'un de vos liens d\'affiliation, nous vous attribuons la vente si elle intervient pendant la période de validité du tracking.',
        minimum_payout_delay: 'Délai minimum avant demande de paiement :',
        minimum_payout_delay_tooltip: 'Il s\'agit du délai minimum entre le moment où vous générez une commission et le moment où vous pouvez demander le paiement de cette commission.',
        minimum_payout_amount: 'Montant minimum de paiement :',
        minimum_payout_amount_tooltip: 'Il s\'agit du montant minimum de commissions générées que vous devez atteindre pour pouvoir demander leur paiement.',
        sale_attribution_rule: 'Règle d\'attribution des ventes :',
        first_clicked_link: 'premier lien cliqué',
        last_clicked_link: 'dernier lien cliqué',
        sale_attribution_rule_tooltip: 'Si une vente est conclue suite à des visites provenant de plusieurs liens d\'affiliation, il s\'agit de la règle qui détermine à quel affilié la vente est attribuée.',
        day: 'jour | jours',
      },
    },
  },
};
</script>

<template>
  <ul>
    <li>
      {{ $t('commission_per_sale') }}
      <strong>{{ commissionRate }}</strong>

      <b-tooltip multilined>
        <template #content>
          <p>{{ $t('commission_tooltip_1') }}</p>
          <p class="mt-3">{{ $t('commission_tooltip_2') }}</p>
          <p class="mt-3">{{ $t('commission_tooltip_3') }}</p>
        </template>
        <b-icon pack="far" icon="question-circle" />
      </b-tooltip>
    </li>
    <li>
      {{ $t('tracking_link_validity') }}
      <strong>
        {{ store.affiliation_options.max_tracking_duration }} {{ $tc('day', store.affiliation_options.max_tracking_duration) }}
      </strong>

      <b-tooltip multilined>
        <template #content>
          {{ $t('tracking_link_tooltip') }}
        </template>
        <b-icon pack="far" icon="question-circle" />
      </b-tooltip>
    </li>
    <li>
      {{ $t('minimum_payout_delay') }}
      <strong>
        {{ store.affiliation_options.min_payout_delay }} {{ $tc('day', store.affiliation_options.min_payout_delay) }}
      </strong>

      <b-tooltip multilined>
        <template #content>
          {{ $t('minimum_payout_delay_tooltip') }}
        </template>
        <b-icon pack="far" icon="question-circle" />
      </b-tooltip>
    </li>
    <li>
      {{ $t('minimum_payout_amount') }}
      <strong>
        {{ store.affiliation_options.min_payout_amount | formatPrice }}
      </strong>

      <b-tooltip multilined>
        <template #content>
          {{ $t('minimum_payout_amount_tooltip') }}
        </template>
        <b-icon pack="far" icon="question-circle" />
      </b-tooltip>
    </li>
    <li>
      {{ $t('sale_attribution_rule') }}
      <strong
        v-if="store.affiliation_options.referer_priority === 'first'"
      >
        {{ $t('first_clicked_link') }}
      </strong>
      <strong v-else>
        {{ $t('last_clicked_link') }}
      </strong>

      <b-tooltip multilined>
        <template #content>
          {{ $t('sale_attribution_rule_tooltip') }}
        </template>
        <b-icon pack="far" icon="question-circle" />
      </b-tooltip>
    </li>
  </ul>
</template>
