<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    resource: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tag() {
      return (this.item.blocked_by_date || this.item.blocked_by_delay) ? 'div' : 'a';
    },
    href() {
      if (this.item.blocked_by_date || this.item.blocked_by_delay || this.item.blocked_manually) {
        return false;
      }

      if (this.resource.type === 'LINK' || this.resource.path.startsWith('http')) {
        return this.resource.path;
      }

      return `${this.$env.apiResourcesURL}/${this.resource.path}`;
    },
  },
  i18n: {
    messages: {
      en: {
        accessible_from: 'Accessible on',
        accessible_manually: 'Accessible upon trainer\'s validation',
        at: 'at',
      },
      fr: {
        accessible_from: 'Accessible le',
        accessible_manually: 'Accessible quand votre formateur ouvrira l\'accès',
        at: 'à',
      },
    },
  },
};
</script>

<template>
  <component :is="tag" :href="href" target="_blank" rel="noopener noreferrer">
    <span v-if="item.blocked_by_date" class="has-text-black">
      <b-icon class="mr-1" icon="lock" />
      ({{ $t('accessible_from') }} {{ item.blocked_by_date | momentFromUTC | moment('DD/MM/YY') }} {{ $t('at') }} {{ item.blocked_by_date | momentFromUTC | moment('HH:mm') }})
    </span>
    <span v-else-if="item.blocked_by_delay" class="has-text-black">
      <b-icon class="mr-1" icon="lock" />
      ({{ $t('accessible_from') }} {{ item.blocked_by_delay | momentFromUTC | moment('DD/MM/YY') }} {{ $t('at') }} {{ item.blocked_by_delay | momentFromUTC | moment('HH:mm') }})
    </span>
    <span v-else-if="item.blocked_manually" class="has-text-black">
      <b-icon class="mr-1" icon="lock" />
      ({{ $t('accessible_manually') }})
    </span>
    <template v-else>
      {{ resource.name }}
    </template>
  </component>
</template>
