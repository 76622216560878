<script>
import { sameAs } from 'vuelidate/lib/validators';
import BasePasswordInput from '@shared/components/BasePasswordInput.vue';

export default {
  components: { BasePasswordInput },
  head() {
    return {
      title: this.$t('reset_password_title'),
    };
  },
  props: {
    to: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    isLinkValid: true,
    password: '',
    passwordConfirm: '',
  }),
  validations: {
    passwordConfirm: { sameAs: sameAs('password') },
  },
  methods: {
    handle() {
      this.isLoading = true;
      const { password } = this;
      const { token } = this.$route.query;
      this.$store.dispatch('auth/resetPassword', { password, token })
        .then(() => {
          this.$buefy.dialog.alert(this.$t('reset_password_success'));
          this.$router.push(this.to);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  created() {
    if (!this.$route.query.token) {
      this.isLinkValid = false;
    }
  },
};
</script>

<template>
  <div>
    <form
      v-if="isLinkValid"
      class="p-5"
      @submit.prevent="dataIsValid(isLoading) && handle()">
      <h1 class="title is-4">
        {{ $t('reset_password_heading') }}
      </h1>
      <b-field :label="$t('new_password_label')">
        <BasePasswordInput
          v-model="password"
        />
      </b-field>
      <b-field
        :label="$t('confirm_password_label')"
        v-bind="$getErrorProps($v.passwordConfirm, [{ name: 'isSame', message: $t('passwords_not_match') }])">
        <b-input
          v-model="passwordConfirm"
          type="password"
          :placeholder="$t('password_placeholder')"
          min="8"
          required
          password-reveal
        />
      </b-field>
      <div class="buttons">
        <b-button
          native-type="submit"
          type="is-primary is-custom"
          :loading="isLoading"
          expanded>
          {{ $t('change_password_button') }}
        </b-button>
      </div>
    </form>
    <div v-else class="content p-5">
      <p>
        {{ $t('reset_link_invalid') }}
      </p>
      <p>
        {{ $t('reset_link_request_new') }}
      </p>
    </div>
  </div>
</template>
