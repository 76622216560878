<script>
import { url } from 'vuelidate/lib/validators';

export default {
  props: {
    href: {
      type: String,
      default: null,
    },
  },
  i18n: {
    messages: {
      en: {
        enter_your_link: 'Enter your link',
        delete_link: 'Delete link',
        buttons: {
          save: 'Save',
        },
      },
      fr: {
        enter_your_link: 'Entrez votre lien',
        delete_link: 'Supprimer le lien',
        buttons: {
          save: 'Enregistrer',
        },
      },
    },
  },
  data() {
    return {
      newHref: this.href,
    };
  },
  computed: {
    saveButtonText() {
      return this.$t('buttons.save');
    },
    deleteLinkText() {
      return this.$t('delete_link');
    },
  },
  validations: {
    newHref: { url },
  },
};
</script>

<template>
  <form class="box" @submit.prevent="dataIsValid() && $emit('save', newHref)">
    <b-field :label="$t('enter_your_link')" v-bind="$getErrorProps($v.newHref, ['url'])">
      <b-input v-model="newHref" />
    </b-field>
    <div class="buttons is-right">
      <b-button
        v-if="href"
        type="is-danger"
        :text="deleteLinkText"
        inverted
        @click="$emit('save', null)"
      />
      <b-button
        native-type="submit"
        type="is-primary"
        :text="saveButtonText"
      />
    </div>
  </form>
</template>
