<script>
import utilsMixin from '@shared/mixins/utils';
import { commentForm } from '@shared/mixins/community';
import TiptapEditor from '@shared/components/TiptapEditor.vue';

export default {
  mixins: [utilsMixin, commentForm],
  components: { TiptapEditor },
  i18n: {
    messages: {
      en: {
        your_answer: 'Your answer',
        cancel: 'Cancel',
        edit: 'Edit',
        reply: 'Reply',
      },
      fr: {
        your_answer: 'Votre réponse',
        cancel: 'Annuler',
        edit: 'Modifier',
        reply: 'Répondre',
      },
    },
  },
  computed: {
    cancelButtonLabel() {
      return this.$t('cancel');
    },
    submitButtonLabel() {
      return this.comment ? this.$t('edit') : this.$t('reply');
    },
  },
};
</script>

<template>
  <form
    class="is-relative"
    @submit.prevent="dataIsValid(isCommentPosting) && handle()"
  >
    <b-loading
      v-if="isCommentPosting"
      :is-full-page="false"
      active
    />

    <div class="field">
      <TiptapEditor
        ref="newComment"
        :placeholder="$t('your_answer')"
        :value="newComment.content"
        @input="newComment.content = $event"
      />
    </div>
    <div class="buttons is-right">
      <b-button
        v-if="comment"
        class="tdecoration-underline is-size-9"
        type="is-text"
        :loading="isCommentPosting"
        @click="$emit('cancel')"
      >
        {{ cancelButtonLabel }}
      </b-button>
      <b-button
        native-type="submit"
        type="is-primary is-custom"
        size="is-small"
        :loading="isCommentPosting"
      >
        {{ submitButtonLabel }}
      </b-button>
    </div>
  </form>
</template>
