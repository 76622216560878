<script>
import transform from 'lodash/transform';
import ContentBlockView from '@shared/mixins/ContentBlockView';

export default {
  mixins: [ContentBlockView],
  computed: {
    dynamicTitle() {
      const [trainingItem] = transform(this.trainingItems, (result, item) => {
        if (item.children) {
          const found = item.children.find((child) => child.id === this.data.id);

          if (found) {
            result.push(found);
            return false;
          }
        }

        if (item.id === this.data.id) {
          result.push(item);
          return false;
        }

        return true;
      }, []);

      return trainingItem ? trainingItem.name : this.$t('training_item_url.lesson_not_found');
    },
    viewLessonButtonText() {
      return this.$t('training_item_url.view_lesson');
    },
  },
  i18n: {
    messages: {
      en: {
        training_item_url: {
          lesson_not_found: '(This lesson no longer exists or cannot be found)',
          view_lesson: 'View lesson',
        },
      },
      fr: {
        training_item_url: {
          lesson_not_found: '(Cette leçon n\'existe pas ou plus)',
          view_lesson: 'Voir la leçon',
        },
      },
    },
  },
};
</script>

<template>
  <div class="comptir box is-flex is-justify-content-space-between is-align-items-center">
    <h2>
      {{ data.title || dynamicTitle }}
    </h2>
    <b-button
      class="comptir_btn"
      tag="router-link"
      type="is-primary"
      :to="{
        name: 'mytrainingitem',
        params: {slug: training.slug, id: data.id}
      }"
    >
      {{ viewLessonButtonText }}
    </b-button>
  </div>
</template>

<style lang="scss" scoped>
.comptir {
  &_btn {
    margin-left: pxToRem(12);
  }

  @include mobile {
    & {
      flex-direction: column;

      &_btn {
        margin-left: 0;
        margin-top: pxToRem(12);
      }
    }
  }
}
</style>
