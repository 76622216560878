<script>
import { genericForms } from '@shared/mixins/forms';
import APITrainings from '@app/services/API/Trainings';

export default {
  mixins: [genericForms],
  props: {
    training: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isFetching: false,
      trainingUuid: this.training ? this.training.uuid : '',
      customersFile: null,
    };
  },
  computed: {
    trainings() {
      const { list } = this.$store.state.trainings;
      return list ? list.data : null;
    },
  },
  created() {
    if (this.training) {
      return;
    }

    this.isFetching = true;
    this.$store.dispatch('trainings/fetch')
      .finally(() => (this.isFetching = false));
  },
  methods: {
    action() {
      const data = new FormData();
      data.append('file', this.customersFile);
      return APITrainings.importCustomers(this.trainingUuid, data)
        .then(() => this.$emit('import'));
    },
  },
};
</script>

<template>
  <div>
    <h1 class="title is-4">
      Import CSV <AppFeatureBadge class="ml-3" position="is-bottom" />
    </h1>
    <p class="mb-3">
      Les apprenants ajoutés auront <strong>immédiatement accès</strong> à la formation
      car nous leur envoyons <strong>un email contenant un mot de passe généré</strong>.
      Ils seront invités à modifier ce mot de passe à leur première connexion.
    </p>
    <p class="mb-3">
      Si un compte que vous importez est déjà existant,
      l'import sera transparent pour l'apprenant.
    </p>
    <go-pro-wrapper>
      <p
        v-if="training && training.status === 'DRAFT'"
        class="notification is-warning"
      >
        Vous ne pouvez pas inviter des apprenants
        dans une formation qui est en brouillon.
      </p>
      <template v-else>
        <p class="notification is-info">
          <strong>Votre fichier doit être au format .csv encodé en UTF-8</strong>.
          Les colonnes attendues, séparées par une virgule, sont
          l'<strong>adresse email</strong>, le <strong>prénom</strong> et le <strong>nom</strong>
          (ainsi qu'une liste de tags si vous avez un pack EXPERT).
          Il faut <strong>enlever la ligne des titres des colonnes</strong>.
          Votre fichier peut contenir jusqu'à <strong>500 apprenants</strong>.
        </p>
        <form @submit.prevent="dataIsValid(isLoading) && handle()">
          <b-field v-if="!training" label="Formation">
            <b-select v-model="trainingUuid" :loading="isFetching" required expanded>
              <option value="">
                Choisissez une formation
              </option>
              <option
                v-for="one in trainings"
                :key="one.uuid"
                :value="one.uuid"
                :disabled="one.status === 'DRAFT'"
              >
                <template v-if="one.status === 'DRAFT'">
                  (brouillon)
                </template>

                {{ one.name }}
              </option>
            </b-select>
          </b-field>
          <b-field :addons="false">
            <b-upload :accept="$constants.CSV_FORMATS" v-model="customersFile" expanded required>
              <b-button tag="a" class="button" icon-left="file-excel" expanded outlined>
                Sélectionnez le fichier .csv
              </b-button>
            </b-upload>
            <p v-if="customersFile" class="is-size-8 has-text-centered mt-2">
              {{ customersFile.name }}
            </p>
          </b-field>
          <div class="buttons is-right">
            <b-button type="is-primary" native-type="submit" :loading="isLoading">
              Importer
            </b-button>
          </div>
        </form>
      </template>
    </go-pro-wrapper>
  </div>
</template>
