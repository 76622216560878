<script>
import APIGroup from '@shared/services/API/Community/Group';
import utilsMixin from '@shared/mixins/utils';

export default {
  mixins: [utilsMixin],
  props: {
    group: {
      type: Object,
      default: null,
    },
  },
  i18n: {
    messages: {
      en: {
        training_item: '{name} (0 learner) | {name} ({n} learner) | {name} ({n} learners)',
        import_members: 'Import members into this space',
        add_all_learners: 'Add all learners from selected trainings',
        choose: 'Choose...',
        cancel: 'Cancel',
        import: 'Import',
        import_in_progress: 'The selected learners are being imported...',
        import_warning: 'Warning: the process may take several minutes!',
        import_success_title: 'Import in progress',
      },
      fr: {
        training_item: '{name} (0 apprenant) | {name} ({n} apprenant) | {name} ({n} apprenants)',
        import_members: 'Importer des membres dans cet espace',
        add_all_learners: 'Ajouter tous les apprenants des formations sélectionnées',
        choose: 'Choisissez...',
        cancel: 'Annuler',
        import: 'Importer',
        import_in_progress: 'Les apprenants sélectionnés sont en cours d\'import...',
        import_warning: 'Attention : le traitement peut prendre plusieurs minutes !',
        import_success_title: 'Import en cours',
      },
    },
  },
  data() {
    return {
      newImport: {
        trainings: [],
      },
      isLoading: false,
    };
  },
  computed: {
    form() {
      return {
        trainings: {
          label: this.$t('add_all_learners'),
          selectLabel: this.$t('choose'),
          type: 'multiselect',
          column: 'is-12',
          options: this.trainingsAsOptions,
          inputAttrs: {
            alwaysOpen: true,
          },
        },
      };
    },
    trainingsAsOptions() {
      const trainings = this.$store.state.trainings.list;

      if (!trainings) {
        return null;
      }

      return trainings.data.reduce((prev, curr) => {
        prev[curr.uuid] = this.$tc(
          'training_item',
          curr.counters.nb_customer,
          { name: curr.name },
        );
        return prev;
      }, {});
    },
    importMembersTitle() {
      return this.$t('import_members');
    },
    cancelButtonText() {
      return this.$t('cancel');
    },
    importButtonText() {
      return this.$t('import');
    },
  },
  methods: {
    handle() {
      const data = { ...this.newImport };

      this.isLoading = true;
      APIGroup.importMembers(this.group.uuid, data)
        .then(() => {
          this.$emit('done');
          this.$buefy.dialog.alert({
            title: this.$t('import_success_title'),
            message: `
              ${this.$t('import_in_progress')}
              <strong>${this.$t('import_warning')}</strong>
            `,
          });
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    // only in user context
    const loader = this.$buefy.loading.open({ container: this.$el });
    this.$store.dispatch('trainings/fetch')
      .finally(() => loader.close());
  },
};
</script>

<template>
  <AppCardModal
    tag="form"
    class="is-relative"
    hasCloseBtn
    @close="$emit('cancel')"
    @submit.prevent="dataIsValid(isLoading) && handle()"
  >
    <template #title>
      {{ importMembersTitle }}
    </template>
    <template #body>
      <div class="columns is-multiline">
        <div
          v-for="(field, k) in form"
          :key="k"
          class="column mb-0"
          :class="field.column"
        >
          <GeneratorField
            :fieldName="k"
            :fieldDefinition="field"
            :formData.sync="newImport"
          />
        </div>
      </div>
    </template>
    <template #foot>
      <b-button type="is-text" @click="$emit('cancel')">
        {{ cancelButtonText }}
      </b-button>
      <b-button
        type="is-primary is-custom"
        native-type="submit"
        :loading="isLoading"
      >
        {{ importButtonText }}
      </b-button>
    </template>
  </AppCardModal>
</template>
