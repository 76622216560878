var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shcomp mx-auto"},[_c('div',{staticClass:"columns is-mobile is-multiline is-vcentered",class:{
      'is-flex-direction-row-reverse': (_vm.videoPosition == 'RIGHT'),
      'w-720 mx-auto has-text-centered': (!_vm.videoUrl || _vm.hasVideoPositionVertical)
    }},[(_vm.videoUrl && !_vm.hasVideoPositionVertical)?_c('div',{staticClass:"column is-12-mobile"},[_c('VueSimpleEmbed',{staticClass:"has-text-centered",attrs:{"attrs":{
          class: 'mw-full',
          width: '768',
          height: '432'
        },"url":_vm.videoUrl}},[_c('b-notification',{attrs:{"type":"is-danger","closable":false}},[_vm._v(" "+_vm._s(_vm.$t('loading_problem'))+" ")])],1)],1):_vm._e(),_c('div',{staticClass:"column is-12-mobile"},[_c('h1',{staticClass:"title is-custom is-3 is-size-4-touch"},[_vm._v(" "+_vm._s(_vm.store.name)+" ")]),(_vm.store.description || (_vm.videoUrl && _vm.hasVideoPositionVertical))?_c('div',{class:{
          'is-flex is-flex-direction-column': (_vm.videoPosition == 'TOP'),
          'is-flex is-flex-direction-column-reverse': (_vm.videoPosition == 'BOTTOM'),
        }},[(_vm.videoUrl && _vm.hasVideoPositionVertical)?_c('VueSimpleEmbed',{staticClass:"flex-none has-background-black",class:{
            'mb-5': (_vm.videoPosition == 'TOP'),
            'mt-5': (_vm.videoPosition == 'BOTTOM'),
          },attrs:{"attrs":{
            class: 'w-full',
            width: '792',
            height: '445.50'
          },"url":_vm.videoUrl}},[_c('b-notification',{attrs:{"type":"is-danger","closable":false}},[_vm._v(" "+_vm._s(_vm.$t('loading_problem'))+" ")])],1):_vm._e(),(_vm.store.description)?_c('div',{staticClass:"flex-none is-size-5 is-size-6-touch content",domProps:{"innerHTML":_vm._s(_vm.html)}}):_vm._e()],1):_vm._e(),(_vm.store.social_links.length)?_c('ul',{staticClass:"columns is-mobile mt-6",class:{'is-centered': !_vm.videoUrl || _vm.hasVideoPositionVertical}},_vm._l((_vm.store.social_links),function(link){return _c('li',{key:link.id,staticClass:"column is-narrow"},[_c('b-button',{attrs:{"tag":"a","type":"is-primary is-custom is-small","href":link.url,"target":"_blank","rel":"noopener noreferrer","icon-left":_vm.$options.static.linkTypes[link.name],"icon-pack":_vm.$options.static.linkTypes[link.name] == 'globe' ? 'fa' : 'fab',"title":_vm.$t('social_link_title', { name: link.name })}})],1)}),0):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }