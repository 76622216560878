<script>
import DOMPurify from 'dompurify';
import linkTypes from '@shared/assets/data/social_links.json';
import VueSimpleEmbed from '@shared/components/VueSimpleEmbed.vue';

export default {
  components: { VueSimpleEmbed },
  props: {
    store: {
      type: Object,
      required: true,
    },
  },
  static: { linkTypes },
  computed: {
    html() {
      return DOMPurify.sanitize(this.store.description);
    },
    videoPosition() {
      return this.store.promotional_video_position || 'TOP';
    },
    videoUrl() {
      return this.store.promotional_video_url;
    },
    hasVideoPositionVertical() {
      return (this.videoPosition === 'TOP' || this.videoPosition === 'BOTTOM');
    },
  },
  i18n: {
    messages: {
      en: {
        loading_problem: 'There was a loading problem...',
        social_link_title: '{name}',
      },
      fr: {
        loading_problem: 'Un problème est survenu lors du chargement...',
        social_link_title: '{name}',
      },
    },
  },
};
</script>

<template>
  <div class="shcomp mx-auto">
    <div
      class="columns is-mobile is-multiline is-vcentered"
      :class="{
        'is-flex-direction-row-reverse': (videoPosition == 'RIGHT'),
        'w-720 mx-auto has-text-centered': (!videoUrl || hasVideoPositionVertical)
      }"
    >
      <div
        v-if="videoUrl && !hasVideoPositionVertical"
        class="column is-12-mobile"
      >
        <VueSimpleEmbed
          class="has-text-centered"
          :attrs="{
            class: 'mw-full',
            width: '768',
            height: '432'
          }"
          :url="videoUrl"
        >
          <b-notification type="is-danger" :closable="false">
            {{ $t('loading_problem') }}
          </b-notification>
        </VueSimpleEmbed>
      </div>
      <div class="column is-12-mobile">
        <h1 class="title is-custom is-3 is-size-4-touch">
          {{ store.name }}
        </h1>
        <div
          v-if="store.description || (videoUrl && hasVideoPositionVertical)"
          :class="{
            'is-flex is-flex-direction-column': (videoPosition == 'TOP'),
            'is-flex is-flex-direction-column-reverse': (videoPosition == 'BOTTOM'),
          }">
          <VueSimpleEmbed
            v-if="videoUrl && hasVideoPositionVertical"
            class="flex-none has-background-black"
            :class="{
              'mb-5': (videoPosition == 'TOP'),
              'mt-5': (videoPosition == 'BOTTOM'),
            }"
            :attrs="{
              class: 'w-full',
              width: '792',
              height: '445.50'
            }"
            :url="videoUrl"
          >
            <b-notification type="is-danger" :closable="false">
              {{ $t('loading_problem') }}
            </b-notification>
          </VueSimpleEmbed>
          <div
            v-if="store.description"
            class="flex-none is-size-5 is-size-6-touch content"
            v-html="html"
          />
        </div>
        <ul
          v-if="store.social_links.length"
          class="columns is-mobile mt-6"
          :class="{'is-centered': !videoUrl || hasVideoPositionVertical}"
        >
          <li v-for="link in store.social_links" :key="link.id" class="column is-narrow">
            <b-button
              tag="a"
              type="is-primary is-custom is-small"
              :href="link.url"
              target="_blank"
              rel="noopener noreferrer"
              :icon-left="$options.static.linkTypes[link.name]"
              :icon-pack="$options.static.linkTypes[link.name] == 'globe' ? 'fa' : 'fab'"
              :title="$t('social_link_title', { name: link.name })"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.shcomp {
  max-width: 1440px;
  width: 100%;
}
</style>
