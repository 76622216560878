<script>
import PDFViewer from '@shared/components/PDFViewer.vue';
import ContentBlockView from '@shared/mixins/ContentBlockView';

export default {
  mixins: [ContentBlockView],
  components: {
    PDFViewer,
  },
  data: () => ({ isFullscreen: false }),
  computed: {
    dynamicData() {
      const path = this.data.path.startsWith('http')
        ? this.data.path
        : `${this.$env.apiResourcesURL}/${this.data.path}`;

      return { path };
    },
    fullscreenTooltipLabel() {
      return this.$t(this.isFullscreen ? 'exit_fullscreen' : 'enter_fullscreen');
    },
    fullscreenIcon() {
      return this.isFullscreen ? 'compress' : 'expand';
    },
  },
  i18n: {
    messages: {
      en: {
        exit_fullscreen: 'Exit fullscreen',
        enter_fullscreen: 'Enter fullscreen',
      },
      fr: {
        exit_fullscreen: 'Sortir du plein écran',
        enter_fullscreen: 'Mettre en plein écran',
      },
    },
  },
};
</script>

<template>
  <fullscreen ref="fullscreen" @change="isFullscreen = $event">
    <figure class="card" :class="{'h-screen is-flex is-flex-direction-column': isFullscreen}">
      <div class="card-image" :class="{'h-full': isFullscreen}">
        <div class="image has-text-centered overflow-visible" :class="{'h-full': isFullscreen}">
          <PDFViewer :class="{'h-full': isFullscreen}" :src="dynamicData.path" />
        </div>
      </div>
      <footer class="card-footer flex-none">
        <div class="card-footer-item level">
          <div class="level-left">
            <figcaption v-if="data.caption" class="has-text-centered is-size-8">
              {{ data.caption }}
            </figcaption>
          </div>
          <div class="level-right">
            <b-tooltip
              :label="fullscreenTooltipLabel"
              position="is-left"
            >
              <b-button
                :icon-left="fullscreenIcon"
                @click="$refs.fullscreen.toggle()"
              />
            </b-tooltip>
          </div>
        </div>
      </footer>
    </figure>
  </fullscreen>
</template>
