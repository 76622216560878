<script>
import utilsMixin from '@shared/mixins/utils';

export default {
  mixins: [utilsMixin],
  props: {
    isEmbed: {
      type: Boolean,
      default: false,
    },
    hasPrice: {
      type: Boolean,
      default: true,
    },
    // trainingName: {
    //   type: String,
    //   default: null,
    // },
    bundle: {
      type: Object,
      required: true,
    },
  },
  i18n: {
    messages: {
      fr: {
        includes_module: 'inclut {count} contenu',
        includes_modules: 'inclut {count} contenus',
        free: 'Gratuit',
        view_bundle: 'Voir le pack',
      },
      en: {
        includes_module: 'includes {count} content',
        includes_modules: 'includes {count} contents',
        free: 'Free',
        view_bundle: 'Discover the bundle',
      },
    },
  },
  computed: {
    href() {
      return this.$router
        .resolve({ name: 'bundle', params: { slug: this.bundle.slug } })
        .href;
    },
    priceStyle() {
      return {
        'font-weight': this.bundle.discount_price != null && 400,
        'text-decoration': this.bundle.discount_price != null && 'line-through',
        opacity: this.bundle.discount_price != null && 0.7,
      };
    },
    picture() {
      if (this.bundle.picture && !this.bundle.picture.startsWith('http')) {
        return `${this.$env.apiResourcesURL}/${this.bundle.picture}`;
      }

      if (!this.bundle.picture) {
        this.$emit('img-load');
      }

      return this.bundle.picture;
    },
  },
  methods: {
    onClick() {
      if (this.isEmbed) {
        window.parent.postMessage({ name: 'checkout' }, '*');
        return;
      }

      this.$router.push({ name: 'bundle', params: { slug: this.bundle.slug } });
    },
  },
};
</script>

<template>
  <article class="comp box">
    <div class="columns is-vcentered">
      <div class="column is-narrow">
        <figure class="comp_img image bradius-8 cursor-pointer" @click.prevent="onClick">
          <img v-if="picture" :src="picture" :alt="bundle.name" @load="$emit('img-load')">
          <span v-else class="is-block p-10 has-background-light is-custom has-text-centered">
            <b-icon icon="chalkboard-teacher" size="is-large" />
          </span>
          <div class="comp_img_info">
            <b-tag v-if="bundle.items.length <= 1" type="is-primary">
              {{ $t('includes_module', { count: bundle.items.length }) }}
            </b-tag>
            <b-tag v-else type="is-primary">
              {{ $t('includes_modules', { count: bundle.items.length }) }}
            </b-tag>
          </div>
        </figure>
      </div>
      <div class="column">
        <h2 class="title is-custom is-4 is-size-6-touch">
          <a class="color-inherit" :href="href" @click.prevent="onClick">
            {{ bundle.name }}
          </a>
        </h2>
        <p v-if="bundle.summary" class="is-size-6 is-size-8-touch">
          {{ bundle.summary }}
        </p>
        <p class="mt-4 is-size-6 is-size-8-touch">
          <template v-if="hasPrice">
            <strong v-if="bundle.discount_price != null" class="mr-3 valign-middle">
              <template v-if="bundle.discount_price > 0">
                {{ bundle.discount_price | formatPrice }}
              </template>
              <template v-else>
                {{ $t('free') }}
              </template>
            </strong>
            <strong class="mr-3 valign-middle" :style="priceStyle">
              <template v-if="bundle.price > 0">
                {{ bundle.price | formatPrice }}
              </template>
              <template v-else>
                {{ $t('free') }}
              </template>
            </strong>
          </template>
          <b-button type="is-primary is-custom valign-middle" tag="a" :href="href" @click.prevent="onClick">
            {{ $t('view_bundle') }}
          </b-button>
        </p>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
.comp {
  &_img {
    width: 320px;
    max-height: 320px;
    position: relative;

    img {
      object-fit: cover;
      object-position: center top;
    }

    .is-small & {
      width: 200px;
    }

    &_info {
      position: absolute;
      bottom: 8px;
      right: 8px;
    }

    @include mobile {

      &,
      .is-small & {
        width: 100%;
      }
    }
  }

  .button {
    white-space: initial;

    @include mobile {
      width: 100%;
      margin-top: 20px;
    }
  }
}
</style>
