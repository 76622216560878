<script>
import utilsMixin from '@shared/mixins/utils';
import communityMixin from '@shared/mixins/community';

export default {
  mixins: [utilsMixin, communityMixin()],
  provide() {
    return {
      onMenuItemClick: this.onMenuItemClick,
    };
  },
  data: () => ({
    hasMenuOpen: false,
  }),
  methods: {
    onMenuItemClick() {
      this.hasMenuOpen = false;
    },
  },
  i18n: {
    messages: {
      fr: {
        menu: 'Menu',
        close: 'Fermer',
      },
      en: {
        menu: 'Menu',
        close: 'Close',
      },
    },
  },
};
</script>

<template>
  <nav class="csncomp">
    <b-button
      class="is-hidden-desktop"
      icon-left="bars"
      @click="hasMenuOpen = !hasMenuOpen"
    >
      {{ $t('menu') }}
    </b-button>

    <div
      ref="menu"
      class="csncomp_menu is-size-8"
      :class="{'is-active': hasMenuOpen}"
    >
      <div class="csncomp_menu_wrap">
        <p class="is-hidden-desktop buttons is-right">
          <b-button
            icon-left="times"
            @click="hasMenuOpen = false"
            :aria-label="$t('close')"
          />
        </p>

        <slot />
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
  .csncomp {
    @include touch {
      &_menu {
        display: none;
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $theme_color_black_30;

        &.is-active {
          display: block;
        }

        &_wrap {
          overflow: auto;
          width: 360px;
          height: 100%;
          padding: 20px;
          background: $white;
        }
      }
    }
  }
</style>
