<script>
import utilsMixin from '@shared/mixins/utils';
import { commentForm } from '@shared/mixins/community';
import TextareaAutoGrowVue from '@shared/components/TextareaAutoGrow.vue';

export default {
  mixins: [utilsMixin, commentForm],
  components: { TextareaAutoGrowVue },
  computed: {
    placeholderText() {
      return this.$t('card_comment_form.your_response');
    },
    cancelText() {
      return this.$t('card_comment_form.cancel');
    },
  },
  i18n: {
    messages: {
      en: {
        card_comment_form: {
          your_response: 'Your response',
          cancel: 'Cancel',
        },
      },
      fr: {
        card_comment_form: {
          your_response: 'Votre réponse',
          cancel: 'Annuler',
        },
      },
    },
  },
};
</script>

<template>
  <form
    class="is-flex is-justify-content-space-between"
    :disabled="isCommentPosting"
    @submit.prevent="dataIsValid(isCommentPosting) && handle()"
  >
    <b-field class="w-full mb-0" :addons="false">
      <TextareaAutoGrowVue>
        <b-input
          type="textarea"
          rows="1"
          size="is-small"
          :placeholder="placeholderText"
          expanded
          v-model="newComment.content"
        />
      </TextareaAutoGrowVue>
      <p v-if="comment" class="has-text-right">
        <b-button
          class="tdecoration-underline is-size-9"
          type="is-text"
          :loading="isCommentPosting"
          @click="$emit('cancel')"
        >
          {{ cancelText }}
        </b-button>
      </p>
    </b-field>
    <div class="field has-text-right ml-3">
      <b-button
        native-type="submit"
        type="is-primary is-custom"
        size="is-small"
        icon-left="paper-plane"
        :loading="isCommentPosting"
      />
    </div>
  </form>
</template>
