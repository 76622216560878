<script>
export default {
  props: {
    handleSubmit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isConfirmed1: false,
    };
  },
  computed: {
    bothConfirmed() {
      return this.isConfirmed1;
    },
  },
  methods: {
    submit() {
      this.handleSubmit();
      this.$emit('close');
    },
  },
};
</script>

<template>
  <AppCardModal @close="$emit('close')" style="margin: auto;">
    <template #title>
      Confirmation
    </template>
    <template #body>
      <div>
        <p>
          Êtes-vous sûr de vouloir changer la langue de votre espace apprenant ?
          Ce changement concerne uniquement l'interface.
          Il faudra traduire vous-même vos contenus pégagogiques et commerciaux.
        </p>
        <div class="is-flex is-flex-direction-column mt-4" style="gap: 0.5rem;">
          <b-checkbox v-model="isConfirmed1">
            J'ai bien compris que je devrais traduire l'ensemble de mes contenus
            (leçons, quiz, emails marketing, pages de vente, etc)
          </b-checkbox>
        </div>
      </div>
    </template>
    <template #foot>
      <b-button type="is-text" @click="$emit('close')">
        Fermer
      </b-button>
      <b-button
        type="is-primary"
        native-type="button"
        :disabled="!bothConfirmed"
        @click="submit"
      >
        Oui, je veux changer la langue
      </b-button>
    </template>
  </AppCardModal>
</template>
