<script>
import APIAbuse from '@shared/services/API/Community/Abuse';
import utilsMixin from '@shared/mixins/utils';
import { mapMutations, mapState } from 'vuex';

export default {
  mixins: [utilsMixin],
  head() {
    return {
      title: this.$t('pages.abuses.title'),
    };
  },
  data: () => ({
    isLoading: false,
    abuses: {
      data: [],
    },
  }),
  computed: {
    ...mapState('community', [
      'currentGroup',
    ]),
  },
  methods: {
    ...mapMutations('community', [
      'decrementGroupCounter',
    ]),
    loadAbuses(url = null, append = false) {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      APIAbuse.getList(url, { group: this.currentGroup.uuid })
        .then((data) => this.setAbuses(data, append))
        .finally(() => (this.isLoading = false));
    },
    setAbuses(data, append = false) {
      if (append) {
        this.abuses = {
          ...data,
          data: [...this.abuses.data, ...data.data],
        };

        return;
      }

      this.abuses = data;
    },
    deleteAbuse(abuse) {
      const onDelete = ({ done }) => APIAbuse.delete(abuse.uuid)
        .then(() => {
          this.abuses.data = this.abuses
            .data.filter((v) => v.uuid !== abuse.uuid);
          this.decrementGroupCounter('abuses_count');
          done();
        });

      const type = (abuse.source.type === 'GROUP_POST')
        ? this.$t('pages.abuses.delete.type.post')
        : this.$t('pages.abuses.delete.type.comment');
      this.showAsyncConfirm({
        message: this.$t('pages.abuses.delete.message', { type }),
      }, onDelete);
    },
    goToSource(abuse) {
      if (abuse.source.type === 'GROUP_POST') {
        this.$router.push({
          name: 'community_group_post',
          params: { postUUID: abuse.source.uuid },
        });
      } else if (abuse.source.type === 'GROUP_COMMENT') {
        this.$router.push({
          name: 'community_group_post',
          params: { postUUID: abuse.source.post_uuid },
          query: { comment: abuse.source.uuid },
        });
      }
    },
  },
  created() {
    this.loadAbuses();
  },
};
</script>

<template>
  <div class="px-3-mobile">
    <h2 class="title is-5">
      {{ $t('pages.abuses.title') }}
    </h2>
    <div class="is-relative">
      <p
        v-if="!isLoading && abuses.data.length <= 0"
        class="box has-text-centered"
      >
        {{ $t('pages.abuses.empty') }}
      </p>

      <ul v-if="abuses.data.length > 0">
        <li
          v-for="abuse in abuses.data"
          :key="abuse.uuid"
          class="
            box is-flex is-align-items-flex-start
            is-justify-content-space-between
          "
        >
          <div class="content mb-0">
            <i18n
              class="is-size-6"
              tag="h3"
              :path="`community.abuse_reason.${abuse.reason}`"
            >
              <span
                class="has-text-weight-normal"
                v-t="`community.abuse_on.${abuse.source.type}`"
              />
            </i18n>
            <p class="wspace-preline">
              {{ abuse.description }}
            </p>
            <p class="is-size-8">
              {{ $t('pages.abuses.reported_by', { name: `${abuse.reporter.firstname} ${abuse.reporter.lastname}` }) }}
              <b-tooltip position="is-bottom">
                <template #content>
                  Signalé le {{ abuse.created_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
                </template>
                {{ abuse.created_at | momentFromUTC | moment('from') }}
              </b-tooltip>
            </p>
          </div>
          <nav class="buttons ml-5">
            <b-tooltip position="is-left" :label="$t('pages.abuses.delete.view_tooltip')">
              <b-button
                type="is-text"
                icon-left="eye"
                @click="goToSource(abuse)"
              />
            </b-tooltip>
            <b-tooltip position="is-left" :label="$t('pages.abuses.delete.tooltip')">
              <b-button
                type="is-text"
                class="has-text-danger"
                icon-left="trash"
                inverted
                @click="deleteAbuse(abuse)"
              />
            </b-tooltip>
          </nav>
        </li>
      </ul>
      <p
        v-if="abuses.links && abuses.links.next"
        class="mt-5 has-text-centered"
      >
        <b-button @click="loadAbuses(abuses.links.next, true)">
          {{ $t('pages.abuses.load_more') }}
        </b-button>
      </p>
      <b-loading :active="isLoading" :is-full-page="false" />
    </div>
  </div>
</template>
