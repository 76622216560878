<script>
import storeHelpersMixin from '@shared/mixins/storeHelpers';

export default {
  mixins: [storeHelpersMixin],
  inject: ['store', 'authCustomer'],
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const accept_marketing = this.authCustomer ? this.authCustomer.accept_marketing : false;
    const customer = {
      email: this.authCustomer ? this.authCustomer.email : '',
      accept_marketing: this.store.accept_marketing_enabled ? accept_marketing : undefined,
    };

    this.$emit('update', customer);

    return { customer };
  },
  watch: {
    customer: {
      deep: true,
      handler() {
        this.$emit('update', this.customer);
      },
    },
  },
  computed: {
    legalContent() {
      return this.replaceStoreInfos(this.store, this.store.user);
    },
  },
  i18n: {
    messages: {
      en: {
        enter_your_email: 'Enter your email address',
        continue: 'Continue',
        general_conditions: 'Terms and Conditions',
        privacy_policy: 'Privacy Policy',
        by_continuing_i_accept: 'I have read and agree to the',
        and_the: 'and the',
        keep_me_updated: 'Keep me informed about new features and offers',
      },
      fr: {
        enter_your_email: 'Entrez votre adresse e-mail',
        continue: 'Continuer',
        general_conditions: 'Conditions Générales',
        privacy_policy: 'Politique de Confidentialité',
        by_continuing_i_accept: 'En continuant, j\'accepte les',
        and_the: 'et la',
        keep_me_updated: 'Je souhaite être tenu(e) informé(e) des mises à jour et des offres',
      },
    },
  },
};
</script>

<template>
  <div class="columns is-multiline">
    <div v-if="!authCustomer" class="column is-12">
      <b-field>
        <b-input
          v-model="customer.email"
          type="email"
          :placeholder="$t('enter_your_email')"
          pattern="^.{2,}@.{2,}\..{2,}$"
          required
        />
      </b-field>
    </div>
    <div class="column is-12">
      <b-button
        native-type="submit"
        type="is-primary is-custom"
        icon-right="caret-right"
        :loading="isLoading"
        expanded
      >
        {{ $t('continue') }}
      </b-button>
    </div>
    <p class="column is-12 py-0 is-size-8">
      <template v-if="store.terms_text">
        {{ store.terms_text }}
        <BaseShowCmsPage
          class="tdecoration-underline"
          :replaceContent="legalContent"
          page="cgv-du-formateur"
        >
          {{ $t('general_conditions') }}
        </BaseShowCmsPage>
        &amp;
        <BaseShowCmsPage
          class="tdecoration-underline"
          :replaceContent="legalContent"
          page="confidentialite-du-formateur"
        >
          {{ $t('privacy_policy') }}
        </BaseShowCmsPage>
      </template>
      <template v-else>
        {{ $t('by_continuing_i_accept') }}
        <BaseShowCmsPage
          class="tdecoration-underline"
          :replaceContent="legalContent"
          page="cgv-du-formateur"
        >
          {{ $t('general_conditions') }}
        </BaseShowCmsPage>
        {{ $t('and_the') }}
        <BaseShowCmsPage
          class="tdecoration-underline"
          :replaceContent="legalContent"
          page="confidentialite-du-formateur"
        >
          {{ $t('privacy_policy') }}
        </BaseShowCmsPage>
      </template>
    </p>
    <p class="column is-12">
      <b-checkbox
        v-if="store.accept_marketing_enabled"
        type="is-primary is-custom"
        class="is-size-8"
        v-model="customer.accept_marketing"
      >
        <template v-if="store.accept_marketing_text">
          {{ store.accept_marketing_text }}
        </template>
        <template v-else>
          {{ $t('keep_me_updated') }}
        </template>
      </b-checkbox>
    </p>
  </div>
</template>
