<script>
import { postsFeed } from '@shared/mixins/community';
import ListPostItem from '@shared/components/Community/ListPostItem.vue';

export default {
  mixins: [postsFeed],
  components: {
    ListPostItem,
  },
  computed: {
    createTopicButtonLabel() {
      return this.$t('create_topic');
    },
  },
  i18n: {
    messages: {
      en: {
        create_topic: 'Create a topic',
        no_topics_yet: 'There are no topics in this space yet!',
      },
      fr: {
        create_topic: 'Créer un sujet',
        no_topics_yet: 'Il n\'y a pas encore de sujet dans cet espace !',
      },
    },
  },
};
</script>

<template>
  <section>
    <nav v-if="canPost" class="buttons is-right pr-3-mobile">
      <b-button
        type="is-primary"
        icon-left="folder-plus"
        @click="$emit('form-open')"
      >
        {{ createTopicButtonLabel }}
      </b-button>
    </nav>

    <div class="is-relative">
      <ListPostItem
        v-for="post in posts.data"
        :key="post.uuid"
        class="mb-5"
        :post="post"
        @delete="$emit('post-delete', $event)"
      />

      <p
        v-if="!isFetching && posts.data.length <= 0"
        class="box has-text-centered"
      >
        {{ $t('no_topics_yet') }}
      </p>
      <b-skeleton v-if="isFetching" height="400" />
    </div>
  </section>
</template>
