<script>
import { mapMutations } from 'vuex';
import APIGroup from '@shared/services/API/Community/Group';
import { genericForms } from '@shared/mixins/forms';

export default {
  mixins: [genericForms],
  props: {
    context: {
      type: String,
      required: true,
    },
    contextUUID: {
      type: String,
      required: true,
    },
  },
  i18n: {
    messages: {
      en: {
        report: 'Report',
        cancel: 'Cancel',
        send: 'Send',
        reason_label: 'What is the reason for reporting?',
        description_label: 'Add a comment to your report',
        spam_option: 'Spam / Unwanted content',
        discrimination_option: 'Discrimination (racism, religious...)',
        hate_option: 'Hate speech',
        violence_option: 'Violent behavior',
      },
      fr: {
        report: 'Signaler',
        cancel: 'Annuler',
        send: 'Envoyer',
        reason_label: 'Quelle est la raison du signalement ?',
        description_label: 'Ajoutez un commentaire à votre signalement',
        spam_option: 'Spam / Contenu indésirable',
        discrimination_option: 'Discrimination (racisme, religieux...)',
        hate_option: 'Propos haineux',
        violence_option: 'Comportement violent',
      },
    },
  },
  data: () => ({
    newAbuse: {
      reason: '',
      description: '',
    },
  }),
  computed: {
    form() {
      return {
        reason: {
          label: this.$t('reason_label'),
          type: 'select',
          column: 'is-12',
          options: {
            SPAM: this.$t('spam_option'),
            DISCRIMINATION: this.$t('discrimination_option'),
            HATE: this.$t('hate_option'),
            VIOLENCE: this.$t('violence_option'),
          },
          inputAttrs: {
            required: true,
          },
        },
        description: {
          label: this.$t('description_label'),
          type: 'textarea',
          column: 'is-12',
          inputAttrs: {
            maxlength: 500,
            required: true,
          },
        },
      };
    },
    cancelButtonText() {
      return this.$t('cancel');
    },
    sendButtonText() {
      return this.$t('send');
    },
  },
  methods: {
    ...mapMutations('community', [
      'incrementGroupCounter',
    ]),
    action() {
      const data = { ...this.newAbuse };

      return (
        this.context === 'comment'
          ? APIGroup.reportComment(this.contextUUID, data)
          : APIGroup.reportPost(this.contextUUID, data)
      )
        .then(() => {
          this.$emit('done');
          this.incrementGroupCounter('abuses_count');
        });
    },
  },
};
</script>

<template>
  <AppCardModal
    tag="form"
    hasCloseBtn
    @close="$emit('cancel')"
    @submit.prevent="dataIsValid(isLoading) && handle()"
  >
    <template #title>
      {{ $t('report') }}
    </template>
    <template #body>
      <div class="columns is-multiline">
        <div
          v-for="(field, k) in form"
          :key="k"
          class="column mb-0"
          :class="field.column"
        >
          <GeneratorField
            :fieldName="k"
            :fieldDefinition="field"
            :formData.sync="newAbuse"
          />
        </div>
      </div>
    </template>
    <template #foot>
      <b-button type="is-text" @click="$emit('cancel')">
        {{ cancelButtonText }}
      </b-button>
      <b-button
        type="is-primary is-custom"
        native-type="submit"
        :loading="isLoading"
      >
        {{ sendButtonText }}
      </b-button>
    </template>
  </AppCardModal>
</template>
