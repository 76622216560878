<script>
import ContentBlockMixin from '@shared/mixins/ContentBlock';
import ContentBlockURLView from '@shared/components/ContentBlocks/Views/URL.vue';
import APIStore from '@app/services/API/Store';

export default {
  mixins: [ContentBlockMixin],
  components: { ContentBlockURLView },
  i18n: {
    messages: {
      en: {
        link_label: 'Link (https://awebsite.com) *',
        caption_label: 'Caption',
        url_not_exist: 'This URL does not exist.',
      },
      fr: {
        link_label: 'Lien (https://unsite.fr) *',
        caption_label: 'Légende',
        url_not_exist: 'Cette URL n\'existe pas.',
      },
    },
  },
  static: {
    data: {
      url: '',
      caption: '',
      host: '',
      title: '',
      image: '',
      description: '',
      authorName: '',
      authorUrl: {},
    },
  },
  data: () => ({ isForm: true }),
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    linkLabel() {
      return this.$t('link_label');
    },
    captionLabel() {
      return this.$t('caption_label');
    },
  },
  methods: {
    handle() {
      return APIStore.getUrlInfos(this.authStore.uuid, this.newData.url)
        .then((data) => Object.assign(this.newData, data.data));
    },
    handleError(error) {
      if (error.response.status === 400) {
        this.$buefy.notification.open({ type: 'is-danger', message: this.$t('url_not_exist') });
      } else {
        this.$errorHandlers.axios(error);
      }
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <b-field :label="linkLabel">
        <b-input v-model="newData.url" pattern="^https?:\/\/.+" required />
      </b-field>
      <b-field :label="captionLabel">
        <b-input v-model="newData.caption" />
      </b-field>
    </template>
    <template #view>
      <ContentBlockURLView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
