<script>
import { commentsList } from '@shared/mixins/community';
import ListCommentItem from '@shared/components/Community/ListCommentItem.vue';
import ListPostCommentForm from '@shared/components/Community/ListPostCommentForm.vue';

export default {
  mixins: [commentsList],
  components: {
    ListCommentItem,
    ListPostCommentForm,
  },
  i18n: {
    messages: {
      en: {
        responses: 'Replies',
        see_more_comments: 'See more comments',
      },
      fr: {
        responses: 'Réponses',
        see_more_comments: 'Voir plus de commentaires',
      },
    },
  },
  computed: {
    responsesTitle() {
      return `${this.$t('responses')} (${this.post.comments_count})`;
    },
  },
};
</script>

<template>
  <div>
    <div v-if="comments.data.length > 0">
      <h2 class="title is-5 mt-10">
        {{ responsesTitle }}
      </h2>

      <hr class="has-background-black10">

      <ListCommentItem
        v-for="comment in comments.data"
        :key="comment.uuid"
        class="mt-3"
        :post="post"
        :comment="comment"
      >
        <ListPostCommentForm
          v-if="
            !(comment.settings && comment.settings.readonly)
            && !postCommentsReadonly
          "
          :post="post"
          :parentUUID="comment.uuid"
        />

        <template #childrenPagination="{ links }">
          <p v-if="links && links.next">
            <b-button
              class="tdecoration-underline"
              type="is-text"
              size="is-small"
              @click="$emit('load-comments', {
                parent: comment,
                url: links.next,
                append: true
              })"
            >
              {{ $t('see_more_comments') }}
            </b-button>
          </p>
        </template>
      </ListCommentItem>

      <p
        v-if="comments.links && comments.links.next"
        class="has-text-centered mt-3"
      >
        <b-button
          class="tdecoration-underline"
          type="is-text"
          size="is-small"
          @click="$emit('load-comments', {
            parent: null,
            url: comments.links.next,
            append: true
          })"
        >
          {{ $t('see_more_comments') }}
        </b-button>
      </p>
    </div>
    <ListPostCommentForm
      v-if="!postCommentsReadonly"
      class="mt-5"
      :post="post"
    />
  </div>
</template>
