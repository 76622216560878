<script>
import ContentBlockWithUploadMixin from '@shared/mixins/ContentBlockWithUpload';
import ContentBlockResourceView from '@shared/components/ContentBlocks/Views/Resource.vue';
import APITrainings from '@app/services/API/Trainings';

export default {
  mixins: [ContentBlockWithUploadMixin],
  components: { ContentBlockResourceView },
  i18n: {
    messages: {
      en: {
        save_lesson_first: 'Please save your lesson before adding a downloadable file.',
        file_available_in_annexes: 'This file will also be available in your annexes.',
        file_name: 'File name',
        select_file: 'Select a file',
        file_too_large: 'The file exceeds 500 MB (too large). Feel free to compress it or split your file into several parts.',
        notification_warning: 'Warning',
        notification_danger: 'Error',
      },
      fr: {
        save_lesson_first: 'Veuillez sauvegarder votre leçon avant de pouvoir ajouter un fichier à télécharger.',
        file_available_in_annexes: 'Ce fichier sera aussi disponible dans vos annexes.',
        file_name: 'Nom du fichier',
        select_file: 'Sélectionnez un fichier',
        file_too_large: 'Le fichier dépasse 500 Mo (trop volumineux). N\'hésitez pas à le compresser, ou à découper votre fichier en plusieurs parties.',
        notification_warning: 'Attention',
        notification_danger: 'Erreur',
      },
    },
  },
  static: {
    data: {
      id: null,
      name: '',
      path: '',
    },
  },
  data: () => ({
    isForm: true,
    isEditable: false,
  }),
  computed: {
    selectFileLabel() {
      return this.$t('select_file');
    },
    noTrainingItemMessage() {
      return this.$t('save_lesson_first');
    },
  },
  methods: {
    handle() {
      if (!this.trainingItem || !this.trainingItem.id) {
        return Promise.reject(new Error('NO_TRAINING_ITEM'));
      }

      if (this.local.file.size > this.$constants.MAX_RESOURCE_SIZE) {
        return Promise.reject(new Error('MAX_RESOURCE_SIZE'));
      }

      const { uuid } = this.training;
      return this.uploadFile(this.viewProps.store, 'resource', (url) => {
        const data = {
          name: this.newData.name,
          file: url,
          target: 'CUSTOMER',
        };

        return APITrainings.addTrainingResource(uuid, this.trainingItem.id, data)
          .then((data) => Object.assign(this.newData, data.data));
      });
    },
    handleError(error) {
      if (error.message === 'NO_TRAINING_ITEM') {
        this.$buefy.notification.open({
          type: 'is-warning',
          message: this.$t('save_lesson_first'),
        });
      } else if (error.message === 'MAX_RESOURCE_SIZE') {
        this.$buefy.dialog.alert(this.$t('file_too_large'));
      } else {
        this.$errorHandlers.axios(error);
      }
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <div v-if="!trainingItem || !trainingItem.id" class="notification is-warning">
        {{ $t('save_lesson_first') }}
      </div>
      <template v-else>
        <p class="notification">
          {{ $t('file_available_in_annexes') }}
        </p>
        <b-field :label="$t('file_name')">
          <b-input v-model="newData.name" required />
        </b-field>
        <b-field :label="$t('select_file')">
          <b-upload v-model="local.file" expanded required native>
            <b-button tag="a" class="button" icon-left="file" outlined>
              {{ selectFileLabel }}
            </b-button>
          </b-upload>
        </b-field>
        <p v-if="local.file" class="is-size-8 mb-5">
          {{ local.file.name }} - {{ localFileSize }} Mo
        </p>
      </template>
    </template>
    <template #view>
      <ContentBlockResourceView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
