<script>
import DOMPurify from 'dompurify';
import ContentBlockView from '@shared/mixins/ContentBlockView';

export default {
  mixins: [ContentBlockView],
  data: () => ({ isOpen: false }),
  computed: {
    html() {
      return DOMPurify.sanitize(this.data.text);
    },
    tooltipText() {
      return this.$t(this.isOpen ? 'toggle.click_to_hide' : 'toggle.click_to_show');
    },
  },
  i18n: {
    messages: {
      en: {
        toggle: {
          click_to_hide: 'Click to hide',
          click_to_show: 'Click to show',
        },
      },
      fr: {
        toggle: {
          click_to_hide: 'Cliquez pour cacher',
          click_to_show: 'Cliquez pour afficher',
        },
      },
    },
  },
};
</script>

<template>
  <div class="card">
    <header class="card-header">
      <a
        class="
          card-header-title is-flex is-align-items-center
          is-justify-content-space-between has-text-black
          has-text-weight-medium
        "
        @click.prevent="isOpen = !isOpen"
      >
        <b-tooltip position="is-top" :label="tooltipText">
          {{ data.title }}
        </b-tooltip>
        <b-icon
          class="ml-3"
          :icon="isOpen ? 'chevron-down' : 'chevron-right'"
        />
      </a>
    </header>
    <div v-show="isOpen" class="card-content">
      <div class="content break-words" v-html="html" />
    </div>
  </div>
</template>
