<script>
import { component as embedComponentsMixin } from '@shared/mixins/embeds';
import utilsMixin from '@shared/mixins/utils';
import RegisterForm from '@school/components/RegisterForm.vue';

export default {
  mixins: [embedComponentsMixin, utilsMixin],
  components: { RegisterForm },
  computed: {
    store() {
      return this.$store.state.store.data;
    },
  },
  methods: {
    postMessage(data) {
      this.$emit('change');
      console.log('postMessage from embedform', data);
      window.parent.postMessage(data, '*');
    },
  },
  i18n: {
    messages: {
      en: {
        embed_form_title: 'Register',
        enroll: 'enroll',
        complete: 'complete',
      },
      fr: {
        embed_form_title: 'S\'inscrire',
        enroll: 's\'inscrire',
        complete: 'terminer',
      },
    },
  },
};
</script>

<template>
  <div
    class="box p-0 is-relative overflow-hidden"
    :style="{
      width: params['style[width]'],
      height: 'auto',
      minHeight: params['style[height]'] || 'auto',
      maxWidth: '100%',
    }"
  >
    <RegisterForm
      class="h-full"
      :isEmbed="true"
      :canStay="false"
      :buyable="buyable"
      :buyableName="text"
      :hasModal="false"
      :hasCloseBtn="false"
      :hasPaymentPlans="buyable.payment_schedules.length > 0"
      @change="$emit('change')"
      @enroll="postMessage({ name: $t('enroll'), store, training: buyable })"
      @complete="postMessage({ name: $t('complete'), store, training: buyable })"
    />
  </div>
</template>
