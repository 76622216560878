<script>
import utilsMixin from '@shared/mixins/utils';
import PaymentMethodsCard from '@school/components/PaymentMethodsCard.vue';

export default {
  mixins: [utilsMixin],
  components: { PaymentMethodsCard },
  computed: {
    purchases() {
      return this.$store
        .getters['auth/customerPurchases']
        .map((v) => ({ ...v, amount: v.amount || 0 }));
    },
    hasPaymentMethod() {
      const customer = this.$store.getters['auth/customer'];
      return (
        customer.card_exp_month != null
        && customer.card_exp_year != null
        && customer.card_last4 != null
      );
    },
  },
  i18n: {
    messages: {
      en: {
        product: 'Product',
        purchase_date: 'Purchase Date',
        purchase_price: 'Purchase Price',
        at: 'at',
        no_purchase: 'No product bought',
      },
      fr: {
        product: 'Produit',
        purchase_date: 'Date d\'achat',
        purchase_price: 'Prix d\'achat',
        at: 'à',
        no_purchase: 'Aucun achat effectué',
      },
    },
  },
};
</script>

<template>
  <div>
    <PaymentMethodsCard v-if="hasPaymentMethod" />

    <b-table
      v-if="purchases && purchases.length > 0" class="box" :data="purchases">
      <b-table-column v-slot="{ row }" field="name" :label="$t('product')" sortable>
          <b-tooltip :label="row.entity.name">
            <span class="is-block mw-260 has-text-clipped">
              {{ row.entity.name }}
            </span>
          </b-tooltip>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="created_at" :label="$t('purchase_date')" sortable numeric>
        <b-tooltip :label="$moment.utc(row.created_at).local().format(`DD/MM/YY [${$t('at')}] HH:mm`)">
          {{ row.created_at | momentFromUTC | moment('from') }}
        </b-tooltip>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="amount" :label="$t('purchase_price')" sortable numeric>
        {{ row.amount | formatPrice }}
      </b-table-column>
    </b-table>
    <b-table v-else>
      {{ $t('no_purchase') }}.
    </b-table>
  </div>
</template>
