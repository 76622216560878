<script>
import { generateId } from '@shared/services/Utils';
import Draggable from 'vuedraggable';

export default {
  components: { Draggable },
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    v: {
      type: Object,
      required: true,
    },
  },
  data() {
    const timecodes = [...this.value].map((v) => {
      if (v.id) {
        return v;
      }

      return {
        ...v,
        id: generateId(),
      };
    });
    return { timecodes };
  },
  computed: {
    dragHandleTitle() {
      return this.$t('timecodes.drag_handle_title');
    },
    addChapterButtonText() {
      return this.$t('timecodes.add_chapter');
    },
  },
  watch: {
    value(value) {
      this.timecodes = [...value];
    },
  },
  methods: {
    addTimecode() {
      this.timecodes.push({
        id: generateId(),
        title: '',
        time: '',
      });
      this.$emit('input', this.timecodes);
    },
    removeTimecode(timecode) {
      this.timecodes = this.timecodes.filter((v) => v !== timecode);
      this.$emit('input', this.timecodes);
    },
  },
  i18n: {
    messages: {
      en: {
        timecodes: {
          title: 'Add chapters and timestamps to your video',
          notification: 'Timestamping allows you to create links to specific moments in your video. The expected format is "<strong>00:00</strong>", that is: <strong>minutes, colon, seconds</strong>. Example: if you have a chapter that starts at 3m30s, you should put 03:30. And if it starts at 2h13m30s, you should indicate 133:30.',
          chapter_label: 'Chapter label',
          time_placeholder: '00:00',
          drag_handle_title: 'Click and drag to reorder',
          add_chapter: 'Add a chapter',
        },
      },
      fr: {
        timecodes: {
          title: 'Ajouter des chapitres et horodatages à votre vidéo',
          notification: 'L\'horodatage vous permet de créer des liens vers des moments précis de votre vidéo. Le format attendu est "<strong>00:00</strong>", c\'est-à-dire : <strong>minutes, deux points, secondes</strong>. Exemple : si vous avez un chapitre qui commence à 3m30s, il faut mettre 03:30. Et si il commence à 2h13m30s, il faut indiquer 133:30.',
          chapter_label: 'Libellé du chapitre',
          time_placeholder: '00:00',
          drag_handle_title: 'Cliquez et déplacez la souris pour ré-ordonner',
          add_chapter: 'Ajouter un chapitre',
        },
      },
    },
  },
};
</script>

<template>
  <div class="field">
    <h3 class="title is-5">
      {{ $t('timecodes.title') }}
    </h3>
    <p class="notification" v-html="$t('timecodes.notification')" />
    <Draggable v-bind="{ value }" @input="$emit('input', $event)" handle=".handle">
      <b-field v-for="(timecode, k) in timecodes" :key="timecode.id" class="w-60p" grouped>
        <b-button type="is-ghost" class="handle" icon-left="grip-lines"
          :title="dragHandleTitle" />
        <b-field>
          <b-input v-model="timecode.title" :placeholder="$t('timecodes.chapter_label')" required expanded />
        </b-field>
        <b-field v-bind="$getErrorProps(v.$each[k].time, ['timecode'])">
          <b-input v-model="timecode.time" :placeholder="$t('timecodes.time_placeholder')" required expanded />
        </b-field>
        <p class="control">
          <b-button type="is-danger" icon-left="times" outlined @click="removeTimecode(timecode)" />
        </p>
      </b-field>
    </Draggable>
    <div class="field w-60p mt-3 ml-9">
      <b-button expanded @click="addTimecode">
        {{ addChapterButtonText }}
      </b-button>
    </div>
  </div>
</template>

<style scoped>
.handle:hover {
  text-decoration: none;
  cursor: grab;
}
</style>
