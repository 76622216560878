<script>
import { mapActions } from 'vuex';
import CommunityAbuseForm from '@shared/components/CommunityAbuseForm.vue';

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
    parent: {
      type: Object,
      default: null,
    },
    comment: {
      type: Object,
      required: true,
    },
    isChild: {
      type: Boolean,
      required: true,
    },
    hasReadonlyOption: {
      type: Boolean,
      default: true,
    },
    hasNotificationOption: {
      type: Boolean,
      default: true,
    },
    canManageCommunityEntity: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    notificationText() {
      return this.comment.subscribed
        ? this.$t('unsubscribe_notification')
        : this.$t('subscribe_notification');
    },
    readonlyText() {
      return this.comment.settings && this.comment.settings.readonly
        ? this.$t('enable_replies')
        : this.$t('disable_replies');
    },
  },
  methods: {
    ...mapActions('community', [
      'deleteComment',
      'toggleSubscriptionComment',
      'toggleReadonlyComment',
    ]),
    toggleSubscription() {
      const load = this.$buefy.loading.open({ container: this.$refs.list });
      this.toggleSubscriptionComment({
        postUUID: this.post.uuid,
        parentUUID: this.parent?.uuid,
        comment: this.comment,
      })
        .finally(() => (load.close()));
    },
    toggleReadonly() {
      const load = this.$buefy.loading.open({ container: this.$refs.list });
      this.toggleReadonlyComment({
        postUUID: this.post.uuid,
        parentUUID: this.parent?.uuid,
        comment: this.comment,
      })
        .finally(() => (load.close()));
    },
    report() {
      let modal = this.$buefy.modal.open({
        parent: this,
        component: CommunityAbuseForm,
        canCancel: false,
        hasModalCard: true,
        props: {
          context: 'comment',
          contextUUID: this.comment.uuid,
        },
        events: {
          cancel() {
            modal.close();
            modal = null;
          },
          done() {
            modal.close();
            modal = null;
          },
        },
      });
    },
    editComment() {
      this.$emit('edit');
    },
    deleteCommentDialog() {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        title: this.$t('permanent_action'),
        message: this.$t('confirm_delete_reply'),
        focusOn: 'cancel',
        confirmText: this.$t('yes'),
        cancelText: this.$t('cancel'),
        closeOnConfirm: false,
        onConfirm: (_, { close }) => {
          const loader = this.$buefy.loading.open();
          this.deleteComment({
            postUUID: this.post.uuid,
            parentUUID: this.parent?.uuid,
            commentUUID: this.comment.uuid,
          })
            .then(() => {
              close();
              this.$showMessage.success();
            })
            .finally(() => loader.close());
        },
      });
    },
  },
  i18n: {
    messages: {
      en: {
        unsubscribe_notification: 'Stop receiving notifications for this reply',
        subscribe_notification: 'Receive notifications for this reply',
        enable_replies: 'Enable replies',
        disable_replies: 'Disable replies',
        report: 'Report',
        edit: 'Edit',
        delete: 'Delete',
        permanent_action: 'Permanent Action',
        confirm_delete_reply: 'Are you sure you want to delete this reply?',
        yes: 'Yes',
        cancel: 'Cancel',
      },
      fr: {
        unsubscribe_notification: 'Ne plus recevoir de notifications pour cette réponse',
        subscribe_notification: 'Recevoir les notifications pour cette réponse',
        enable_replies: 'Activer les réponses',
        disable_replies: 'Désactiver les réponses',
        report: 'Signaler',
        edit: 'Modifier',
        delete: 'Supprimer',
        permanent_action: 'Action définitive',
        confirm_delete_reply: 'Êtes-vous sûr(e) de vouloir supprimer cette réponse ?',
        yes: 'Oui',
        cancel: 'Annuler',
      },
    },
  },
};
</script>

<template>
  <b-dropdown position="is-bottom-left">
    <template #trigger>
      <b-button
        class="px-0 has-text-black30"
        type="is-text"
        size="is-small"
        icon-left="ellipsis-v"
      />
    </template>
    <b-dropdown-item
      v-if="hasNotificationOption && !isChild"
      @click="toggleSubscription"
    >
      <template v-if="comment.subscribed">
        <b-icon pack="far" icon="bell-slash" />
      </template>
      <template v-else>
        <b-icon pack="far" icon="bell" />
      </template>
      {{ notificationText }}
    </b-dropdown-item>
    <b-dropdown-item
      v-if="
        hasReadonlyOption && (
          !(post.settings && post.settings.readonly)
          && !isChild && canManageCommunityEntity
        )
      "
      @click="toggleReadonly"
    >
      <template v-if="comment.settings && comment.settings.readonly">
        <b-icon icon="comment" />
      </template>
      <template v-else>
        <b-icon icon="comment-slash" />
      </template>
      {{ readonlyText }}
    </b-dropdown-item>
    <b-dropdown-item @click="report">
      <b-icon pack="far" icon="flag" />
      {{ $t('report') }}
    </b-dropdown-item>
    <template v-if="canManageCommunityEntity">
      <b-dropdown-item separator />
      <b-dropdown-item
        @click="editComment"
      >
        <b-icon icon="pen" />
        {{ $t('edit') }}
      </b-dropdown-item>
      <b-dropdown-item
        class="has-text-danger"
        @click="deleteCommentDialog"
      >
        <b-icon icon="trash" />
        {{ $t('delete') }}
      </b-dropdown-item>
    </template>
  </b-dropdown>
</template>
