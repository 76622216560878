<script>
import DOMPurify from 'dompurify';
import { genericForms } from '@shared/mixins/forms';
import TiptapEditor from '@shared/components/TiptapEditor.vue';
import APICustomer from '@school/services/API/Customer';

export default {
  mixins: [genericForms],
  components: { TiptapEditor },
  props: {
    trainingSlug: {
      type: String,
      required: true,
    },
    trainingItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { newNote: '' };
  },
  computed: {
    authCustomer() {
      return this.$store.getters['auth/customer'];
    },
    note() {
      return DOMPurify.sanitize(this.trainingItem.progression.personal_note);
    },
  },
  created() {
    this.newNote = this.authCustomer
      ? this.trainingItem.progression.personal_note
      : '';
  },
  methods: {
    action() {
      const data = { personal_note: this.newNote };
      return APICustomer.updateTrainingItemProgression(
        this.trainingSlug,
        this.trainingItem.id,
        data,
      )
        .then((data) => this.$emit('update', data.data));
    },
  },
  i18n: {
    messages: {
      fr: {
        personal_notes_title: 'Vos notes personnelles',
        personal_notes_description: 'Vous pouvez ajouter des notes ou remarques privées tout au long de la formation, pour vous aider à mieux progresser.',
        update_button: 'Mettre à jour',
        not_logged_in_warning: 'Vous n\'êtes pas connecté en tant qu\'apprenant !',
      },
      en: {
        personal_notes_title: 'Your personal notes',
        personal_notes_description: 'You can add private notes or remarks during the training to support your learning progress.',
        update_button: 'Update',
        not_logged_in_warning: 'You are not logged in as a learner!',
      },
    },
  },
};
</script>

<template>
  <div>
    <h2 class="title is-custom is-5">
      {{ $t('personal_notes_title') }}
    </h2>

    <p class="mb-5">
      {{ $t('personal_notes_description') }}
    </p>

    <form v-if="authCustomer" @submit.prevent="dataIsValid(isLoading) && handle()">
      <div class="columns is-multiline">
        <div class="column">
          <b-field>
            <TiptapEditor :value="newNote" @input="newNote = $event" />
          </b-field>
        </div>
      </div>

      <b-field class="has-text-right">
        <b-button type="is-primary is-custom" native-type="submit" :loading="isLoading">
          {{ $t('update_button') }}
        </b-button>
      </b-field>
    </form>
    <div v-else class="notification is-warning is-flex">
      <b-icon class="flex-none mr-3" icon="info-circle" />
      {{ $t('not_logged_in_warning') }}
    </div>
  </div>
</template>
