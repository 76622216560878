<script>
import merge from 'lodash/merge';
import { StripeOptions } from '@shared/config/stripe';
import stripeElement from '@shared/mixins/stripeElement';

const options = { ...StripeOptions };
delete options.hidePostalCode;

export default {
  mixins: [stripeElement],
  data() {
    return {
      isLoaded: false,
      cardNumber: {
        isReady: false,
        isComplete: false,
        error: null,
      },
      cardExpiry: {
        isReady: false,
        isComplete: false,
        error: null,
      },
      cardCvc: {
        isReady: false,
        isComplete: false,
        error: null,
      },
    };
  },
  methods: {
    init() {
      const { stripeAccount } = this;
      this.stripe = window.Stripe(this.$env.stripePK, { stripeAccount, locale: document.documentElement.lang || 'fr' });
      const elements = this.stripe.elements();

      this.cardNumber.$el = elements.create('cardNumber', options);
      this.cardExpiry.$el = elements.create('cardExpiry', options);
      this.cardCvc.$el = elements.create('cardCvc', options);

      this.cardNumber.$el.mount(this.$refs.cardNumber);
      this.cardExpiry.$el.mount(this.$refs.cardExpiry);
      this.cardCvc.$el.mount(this.$refs.cardCvc);

      const cardElements = [this.cardNumber, this.cardExpiry, this.cardCvc];
      cardElements.forEach((element) => {
        element.$el.on('change', (event) => {
          element.isComplete = event.complete;
          element.error = event.error;

          let errorMessage = cardElements.reduce((prev, curr) => {
            const { error } = curr;

            if (!error) {
              return prev;
            }

            return prev ? `${prev} ${error.message}` : error.message;
          }, '');

          errorMessage = errorMessage ? { message: errorMessage } : errorMessage;

          this.$emit('complete', cardElements.every((v) => v.isComplete));
          this.$emit('error', errorMessage);
        });

        element.$el.on('ready', () => {
          element.isReady = true;

          if (cardElements.every((v) => v.isReady)) {
            this.$emit('ready');
          }
        });
      });

      this.isLoaded = true;
      this.$emit('loaded');
    },
    createPaymentMethod(data = {}) {
      data = merge(data, { type: 'card', card: this.cardNumber.$el });

      return this.stripe.createPaymentMethod(data).then((res) => {
        if (res.error) {
          return Promise.reject(res.error);
        }
        return res;
      });
    },
    confirmCardPayment(clientSecret, data = {}) {
      data = merge(data, { payment_method: { card: this.cardNumber.$el } });

      return this.stripe.confirmCardPayment(clientSecret, data).then((res) => {
        if (res.error) {
          return Promise.reject(res.error);
        }
        return res;
      });
    },
    confirmCardSetup(clientSecret, data = {}) {
      data = merge(data, { payment_method: { card: this.cardNumber.$el } });

      return this.stripe.confirmCardSetup(clientSecret, data).then((res) => {
        if (res.error) {
          return Promise.reject(res.error);
        }
        return res;
      });
    },
    handleCardAction(clientSecret) {
      return this.stripe.handleCardAction(clientSecret).then((res) => {
        if (res.error) {
          return Promise.reject(res.error);
        }
        return res;
      });
    },
    clear() {
      this.cardNumber.$el.clear();
      this.cardExpiry.$el.clear();
      this.cardCvc.$el.clear();
    },
  },
  destroyed() {
    this.cardNumber.$el && this.cardNumber.$el.destroy();
    this.cardExpiry.$el && this.cardExpiry.$el.destroy();
    this.cardCvc.$el && this.cardCvc.$el.destroy();
  },
};
</script>

<template>
  <div class="compses columns is-mobile is-multiline mb-0">
    <div class="column is-12-mobile pb-0">
      <div class="input">
        <div v-show="!cardNumber.isReady" class="loader is-primary is-size-5 mr-5" />
        <div ref="cardNumber" v-show="cardNumber.isReady" class="w-full" />
      </div>
    </div>
    <div class="column is-expiry is-6-mobile">
      <div class="input">
        <div v-show="!cardExpiry.isReady" class="loader is-primary is-size-5 mr-5" />
        <div ref="cardExpiry" v-show="cardExpiry.isReady" class="w-full" />
      </div>
    </div>
    <div class="column is-cvc is-6-mobile">
      <div class="input">
        <div v-show="!cardCvc.isReady" class="loader is-primary is-size-5 mr-5" />
        <div ref="cardCvc" v-show="cardCvc.isReady" class="w-full" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.compses {
  @include tablet {
    .is-expiry {
      flex: 120px 0 0;
      width: 120px;
    }

    .is-cvc {
      flex: 88px 0 0;
      width: 88px;
    }
  }

  @include mobile {
    .is-expiry {
      padding-right: 0;
    }
  }
}
</style>
