<script>
import { postsFeed } from '@shared/mixins/community';
import BaseAvatar from '@shared/components/BaseAvatar.vue';
import CardPostItem from '@shared/components/Community/CardPostItem.vue';

export default {
  mixins: [postsFeed],
  components: {
    BaseAvatar,
    CardPostItem,
  },
  computed: {
    expressYourselfText() {
      return this.$t('express_yourself');
    },
  },
  i18n: {
    messages: {
      en: {
        express_yourself: 'Express yourself',
        nothing_happened_yet: 'Nothing has happened in this space yet!',
      },
      fr: {
        express_yourself: 'Exprimez-vous',
        nothing_happened_yet: "Il ne s'est encore rien passé dans cet espace !",
      },
    },
  },
};
</script>

<template>
  <section class="mw-640 mx-auto">
    <nav v-if="canPost" class="box bradius-0-mobile flex-between-center">
      <BaseAvatar
        class="flex-none"
        :avatar="$store.getters['community/userAvatar']"
        :displayName="$store.getters['community/userDisplayName']"
      />
      <button
        class="input ml-3"
        type="button"
        @click="$emit('form-open')"
      >
        {{ expressYourselfText }}
      </button>
    </nav>

    <div class="is-relative">
      <CardPostItem
        v-for="post in posts.data"
        :key="post.uuid"
        class="mb-5"
        :post="post"
        @delete="$emit('post-delete', $event)"
      />

      <p
        v-if="!isFetching && posts.data.length <= 0"
        class="box has-text-centered bradius-0-mobile"
      >
        {{ $t('nothing_happened_yet') }}
      </p>
      <b-skeleton v-if="isFetching" height="400" />
    </div>
  </section>
</template>
