export default {
  methods: {
    replaceStoreInfos(store, user) {
      return (content) => {
        const email = store.support_email
          || store.reply_to_email
          || user.email;

        const cgv = this.getLegalUrlText(store.terms_url);
        const privacy = this.getLegalUrlText(store.privacy_url);

        content = content.replace(/\[email\]/gi, email);
        content = content.replace(/\[link_cgv\]/gi, cgv);
        content = content.replace(/\[link_privacy\]/gi, privacy);

        return content;
      };
    },
    getLegalUrlText(url) {
      if (!url) {
        return this.$t('legal.no_info');
      }

      return `
        <a href="${url}" target="_blank" rel="noreferrer noopener">
          ${this.$t('legal.click_here')}
        </a>
        ${this.$t('legal.more_info')}
      `;
    },
  },
};
