<script>
export default {
  props: {
    api: {
      type: Object,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    send() {
      this.isLoading = true;
      this.api.sendVerificationEmail(this.email)
        .then(() => this.$buefy.dialog.alert({
          title: this.$t('alert_message_email_validation_title'),
          message: this.$t('alert_message_email_validation_message'),
        }))
        .finally(() => (this.isLoading = false));
    },
  },
  i18n: {
    messages: {
      fr: {
        alert_message_email_validation_text: 'Un lien vous a été envoyé par email pour activer votre compte.',
        alert_message_email_validation_button: 'Renvoyer l\'email d\'activation',
        alert_message_email_validation_title: 'Un nouveau lien vous a été envoyé',
        alert_message_email_validation_message: "Si vous ne trouvez pas l'email dans votre boîte de réception principale, vérifiez l'onglet <strong>Spam</strong> ou <strong>Promotions</strong>.<br><br>Ce nouveau lien invalide tous les précédents.",
      },
      en: {
        alert_message_email_validation_text: 'A link has been sent to your email to activate your account.',
        alert_message_email_validation_button: ' Resend the activation link',
        alert_message_email_validation_title: 'A new link has been sent to you',
        alert_message_email_validation_message: 'If you don’t find the email in your main inbox, check the <strong>Spam</strong> or <strong>Promotions</strong> tabs.<br><br>This new link invalidates all previous ones.',
      },
    },
  },
};
</script>

<template>
  <div class="box has-background-warning mb-6">
    <div class="columns is-multiline is-vcentered">
      <div class="column">
        <b-icon class="valign-middle mr-3" icon="info-circle" />
        {{ $t('alert_message_email_validation_text') }}
      </div>
      <div class="column is-narrow">
        <b-button type="is-white" :loading="isLoading" @click="send">
          {{ $t('alert_message_email_validation_button') }}
        </b-button>
      </div>
    </div>
  </div>
</template>
