<script>
import ContentBlockView from '@shared/mixins/ContentBlockView';

export default {
  mixins: [ContentBlockView],
  computed: {
    dynamicData() {
      const path = this.data.path.startsWith('http')
        ? this.data.path
        : `${this.$env.apiResourcesURL}/${this.data.path}`;

      return { path };
    },
    downloadButtonText() {
      return this.$t('download_button');
    },
    downloadButtonAttributes() {
      return {
        href: this.dynamicData.path,
        target: '_blank',
        rel: 'noreferrer noopener',
        download: this.$t('download_attribute'),
      };
    },
  },
  i18n: {
    messages: {
      en: {
        download_button: 'Download',
        download_attribute: 'download',
      },
      fr: {
        download_button: 'Télécharger',
        download_attribute: 'telecharger',
      },
    },
  },
};
</script>

<template>
  <div class="compr box is-flex is-align-items-center is-justify-content-space-between">
    <p>
      {{ data.name }}
    </p>
    <div class="compr_btn flex-none">
      <a
        class="button is-primary is-custom"
        v-bind="downloadButtonAttributes"
      >
        {{ downloadButtonText }}
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.compr {
  &_btn {
    margin-left: pxToRem(12);
  }

  @include mobile {
    & {
      flex-direction: column;

      &_btn {
        margin-left: 0;
        margin-top: pxToRem(12);
      }
    }
  }
}
</style>
