<script>
import utilsMixin from '@shared/mixins/utils';

export default {
  mixins: [utilsMixin],
  props: {
    customer: {
      type: Object,
      default: null,
    },
    bundle: {
      type: Object,
      required: true,
    },
  },
  i18n: {
    messages: {
      fr: {
        bundle_buy_button_pay_in_installments: 'Payer en plusieurs fois',
        bundle_buy_button_or: 'OU',
        bundle_buy_button_get_for: 'Obtenir pour {price}',
        bundle_buy_button_access_free: 'Accéder gratuitement',
        bundle_buy_button_instead_of: 'Au lieu de {price}',
        bundle_buy_button_discount_percentage: '{reduction}% de réduction',
      },
      en: {
        bundle_buy_button_pay_in_installments: 'Pay in installments',
        bundle_buy_button_or: 'OR',
        bundle_buy_button_get_for: 'Get for {price}',
        bundle_buy_button_access_free: 'Access for free',
        bundle_buy_button_instead_of: 'Instead of {price}',
        bundle_buy_button_discount_percentage: '{reduction}% discount',
      },
    },
  },
  computed: {
    price() {
      return this.bundle.discount_price == null
        ? this.bundle.price
        : this.bundle.discount_price;
    },
    reduction() {
      return 100 - Math.round((this.bundle.discount_price / this.bundle.price) * 100);
    },
  },
  methods: {
    addToCart(isSinglePrice = true) {
      const options = isSinglePrice ? undefined : 1;
      this.$router.push({ query: { acheter: 1, options } });

      if (!isSinglePrice) {
        return;
      }

      this.$gtag.addToCart({
        items: [{
          id: this.bundle.uuid,
          name: this.bundle.name,
          quantity: 1,
          value: this.price,
        }],
      });
      this.$fbq.addToCart({
        content_ids: [this.bundle.uuid],
        content_name: this.bundle.name,
        value: this.price,
      });
    },
  },
};
</script>

<template>
  <div class="comptbb">
    <!-- <template v-if="customer && bundle.customer_type == 'STUDENT'">
      <b-button class="comptbb_cta" type="is-primary is-custom" size="is-large" tag="router-link"
        :to="{ name: 'mytraining', params: { slug: training.slug } }" expanded>
        Accéder
      </b-button>
    </template> -->
    <!-- <template v-else> -->
    <template v-if="price > 0 && bundle.payment_schedules.length">
      <b-button class="comptbb_cta" type="is-primary is-custom" size="is-large" expanded @click="addToCart(false)">
        {{ $t('bundle_buy_button_pay_in_installments') }}
      </b-button>
      <p class="p-2 has-text-weight-bold">
        {{ $t('bundle_buy_button_or') }}
      </p>
    </template>
    <b-button class="comptbb_cta" type="is-primary is-custom" size="is-large" expanded @click="addToCart">
      <template v-if="price > 0">
        {{ $t('bundle_buy_button_get_for', { price: $options.filters.formatPrice(price) }) }}
      </template>
      <template v-else>
        {{ $t('bundle_buy_button_access_free') }}
      </template>
    </b-button>
    <template v-if="bundle.discount_price != null">
      <p class="mt-5 is-size-7 is-size-9-mobile">
        <span class="tdecoration-line-through">
          {{ $t('bundle_buy_button_instead_of', { price: $options.filters.formatPrice(bundle.price) }) }}
        </span>
        <br>
        {{ $t('bundle_buy_button_discount_percentage', { reduction }) }}
      </p>
      <p v-if="bundle.discount_price_reason" class="opacity-70 is-size-8">
        {{ bundle.discount_price_reason }}
      </p>
    </template>
    <!-- </template> -->
  </div>
</template>

<style lang="scss" scoped>
.comptbb {
  @include mobile {
    &_cta {
      font-size: $size-7;
    }
  }
}
</style>
