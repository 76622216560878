<script>
import CustomerTrainingResourcesLink from '@school/components/CustomerTrainingResourcesLink.vue';

export default {
  components: { CustomerTrainingResourcesLink },
  props: {
    count: {
      type: [Number, String],
      required: true,
    },
    training: {
      type: Object,
      default: null,
    },
    trainingItem: {
      type: Object,
      default: null,
    },
  },
  computed: {
    items() {
      return this.training ? this.training.items : [this.trainingItem];
    },
  },
  i18n: {
    messages: {
      fr: {
        no_appendices_yet: "Il n'y a pas d'annexes pour le moment.",
      },
      en: {
        no_appendices_yet: 'There are no appendices at the moment.',
      },
    },
  },
};
</script>

<template>
  <div class="content has-text-left">
    <p v-if="!count" class="box is-primary is-custom">
      {{ $t('no_appendices_yet') }}
    </p>

    <template v-for="one in items">
      <template v-if="one.children">
        <template v-for="child in one.children">
          <template v-if="child.resources.length">
            <h3 :key="`title${child.id}`">
              {{ one.name }} - {{ child.name }}
            </h3>
            <ul :key="`list${child.id}`">
              <li v-for="resource in child.resources" :key="resource.id">
                <CustomerTrainingResourcesLink :item="child" :resource="resource" />
              </li>
            </ul>
          </template>
        </template>
      </template>
      <template v-else>
        <template v-if="one.resources.length">
          <h3 v-if="training" :key="`title${one.id}`">
            {{ one.name }}
          </h3>
          <ul :key="`list${one.id}`">
            <li v-for="resource in one.resources" :key="resource.id">
              <CustomerTrainingResourcesLink :item="one" :resource="resource" />
            </li>
          </ul>
        </template>
      </template>
    </template>
  </div>
</template>
