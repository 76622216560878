<script>
import ContentBlockMixin from '@shared/mixins/ContentBlock';
import ContentBlockHTMLView from '@shared/components/ContentBlocks/Views/HTML.vue';

export default {
  mixins: [ContentBlockMixin],
  components: { ContentBlockHTMLView },
  static: {
    data: { html: '', caption: '' },
  },
  data: () => ({ isForm: true }),
  computed: {
    htmlContentLabel() {
      return this.$t('html_content_label');
    },
    captionLabel() {
      return this.$t('caption_label');
    },
  },
  i18n: {
    messages: {
      en: {
        html_content_label: 'HTML Content (code)',
        caption_label: 'Caption',
      },
      fr: {
        html_content_label: 'Contenu HTML (code)',
        caption_label: 'Légende',
      },
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <b-field :label="htmlContentLabel">
        <div>
          <div
            v-if="notification"
            class="notification"
            :class="notificationClass"
            v-html="notification"
          />
          <b-input v-model="newData.html" type="textarea" required />
        </div>
      </b-field>
      <b-field :label="captionLabel">
        <b-input v-model="newData.caption" />
      </b-field>
    </template>
    <template #view>
      <ContentBlockHTMLView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
