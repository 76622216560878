<script>
import { postSingle } from '@shared/mixins/community';
import CardPostItem from '@shared/components/Community/CardPostItem.vue';
import CardCommentItem from '@shared/components/Community/CardCommentItem.vue';

export default {
  mixins: [postSingle],
  components: {
    CardPostItem,
    CardCommentItem,
  },
  computed: {
    isStandAloneText() {
      return this.$t('card_post_single.is_stand_alone');
    },
  },
  i18n: {
    messages: {
      en: {
        card_post_single: {
          response: 'The response:',
          associated_post: 'The associated post:',
          is_stand_alone: 'true',
        },
      },
      fr: {
        card_post_single: {
          response: 'La réponse :',
          associated_post: 'La publication associée :',
          is_stand_alone: 'true',
        },
      },
    },
  },
};
</script>

<template>
  <section class="mw-640 mx-auto">
    <template v-if="comment">
      <h3 class="title is-6">
        {{ $t('card_post_single.response') }}
      </h3>
      <CardCommentItem
        class="box"
        :post="post"
        :comment="comment"
        :parent="comment.parent"
        :isStandAlone="isStandAloneText"
      />

      <h3 class="title is-6">
        {{ $t('card_post_single.associated_post') }}
      </h3>
    </template>
    <CardPostItem :post="post" />
  </section>
</template>
