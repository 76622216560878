<script>
import { mapState } from 'vuex';
import AffiliateTrainingCard from '@school/components/AffiliateTrainingCard.vue';
import AffiliateBundleCard from '@school/components/AffiliateBundleCard.vue';

export default {
  components: { AffiliateBundleCard, AffiliateTrainingCard },
  i18n: {
    messages: {
      en: {
        affiliate_key: 'Affiliate key:',
        account_status: 'Your affiliate account is {status}',
        status: {
          ACTIVE: 'active',
          SUSPENDED: 'suspended',
        },
        affiliate_link_homepage: 'Affiliate link to the homepage',
        homepage: 'Homepage',
        click_to_copy: 'click to copy',
        copy_link: 'Copy link',
        affiliate_links_by_training: 'Training-specific affiliate links',
        no_trainings: 'No trainings.',
        affiliate_links_by_bundle: 'Bundle-specific affiliate links',
        no_bundles: 'No packs.',
      },
      fr: {
        affiliate_key: 'Clé d\'affiliation :',
        account_status: 'Votre compte d\'affiliation est {status}',
        status: {
          ACTIVE: 'actif',
          SUSPENDED: 'suspendu',
        },
        affiliate_link_homepage: 'Lien d\'affiliation vers la page d\'accueil',
        homepage: 'Page d\'accueil',
        click_to_copy: 'cliquer pour copier',
        copy_link: 'Copier le lien',
        affiliate_links_by_training: 'Liens d\'affiliation par formation',
        no_trainings: 'Aucune formation.',
        affiliate_links_by_bundle: 'Liens d\'affiliation par pack',
        no_bundles: 'Aucun pack.',
      },
    },
  },
  computed: {
    ...mapState('store', {
      store: 'data',
    }),
    ...mapState('auth', {
      affiliate: 'affiliateData',
    }),
    url() {
      const { location } = window;
      const { code } = this.affiliate;
      const url = `${location.protocol}//${location.hostname}`;
      return `${url}?${this.$constants.AFFILIATE_PREFIX}${code}`;
    },
    trainingsByUUID() {
      return this.store
        .trainings
        .reduce((prev, curr) => {
          prev[curr.uuid] = curr;
          return prev;
        }, {});
    },
    bundlesByUUID() {
      return this.store
        .bundles
        .reduce((prev, curr) => {
          prev[curr.uuid] = curr;
          return prev;
        }, {});
    },
    affiliateTrainings() {
      return this.affiliate
        .applications
        .trainings
        .reduce((prev, curr) => {
          prev[curr.uuid] = curr;
          return prev;
        }, {});
    },
    affiliateBundles() {
      return this.affiliate
        .applications
        .bundles
        .reduce((prev, curr) => {
          prev[curr.uuid] = curr;
          return prev;
        }, {});
    },
    allowedTrainings() {
      if (this.affiliate.applications.store) {
        const hasSpecificRate = !!(
          this.affiliate.applications.store_amount
          || this.affiliate.applications.store_percentage
        );

        const amount = hasSpecificRate
          ? this.affiliate.applications.store_amount
          : this.store.affiliation_options.default_commission_amount;

        const percentage = hasSpecificRate
          ? this.affiliate.applications.store_percentage
          : this.store.affiliation_options.default_commission_percentage;

        return this.store
          .trainings
          .map((v) => ({
            uuid: v.uuid,
            slug: v.slug,
            name: v.name,
            status: v.status,
            commissionRate: (amount ? `${amount}€` : `${percentage}%`),
          }));
      }

      return this.affiliate
        .applications
        .trainings
        .filter((v) => !!this.trainingsByUUID[v.uuid])
        .map((v) => ({
          uuid: v.uuid,
          slug: this.trainingsByUUID[v.uuid].slug,
          name: this.trainingsByUUID[v.uuid].name,
          status: this.trainingsByUUID[v.uuid].status,
          commissionRate: (v.amount ? `${v.amount}€` : `${v.percentage}%`),
        }));
    },
    allowedBundles() {
      if (this.affiliate.applications.store) {
        const hasSpecificRate = !!(
          this.affiliate.applications.store_amount
          || this.affiliate.applications.store_percentage
        );

        const amount = hasSpecificRate
          ? this.affiliate.applications.store_amount
          : this.store.affiliation_options.default_commission_amount;

        const percentage = hasSpecificRate
          ? this.affiliate.applications.store_percentage
          : this.store.affiliation_options.default_commission_percentage;

        return this.store
          .bundles
          .map((v) => ({
            uuid: v.uuid,
            slug: v.slug,
            name: v.name,
            status: v.status,
            commissionRate: (amount ? `${amount}€` : `${percentage}%`),
          }));
      }

      return this.affiliate
        .applications
        .bundles
        .filter((v) => !!this.bundlesByUUID[v.uuid])
        .map((v) => ({
          uuid: v.uuid,
          slug: this.bundlesByUUID[v.uuid].slug,
          name: this.bundlesByUUID[v.uuid].name,
          status: this.bundlesByUUID[v.uuid].status,
          commissionRate: (v.amount ? `${v.amount}€` : `${v.percentage}%`),
        }));
    },
  },
};
</script>

<template>
  <section>
    <b-skeleton v-if="!affiliate" height="400" />
    <template v-else>
      <header class="mb-10">
        <p>
          {{ $t('affiliate_key') }}
          <span class="is-outlined">
            {{ affiliate.code }}
          </span>
        </p>
        <p
          class="tag mt-1"
          :class="{
            'is-success': affiliate.status === 'ACTIVE',
            'is-danger': affiliate.status === 'SUSPENDED',
          }"
        >
          {{ $t('account_status', { status: $t(`status.${affiliate.status}`) }) }}
        </p>
      </header>

      <h2 class="title is-4 is-custom">
        {{ $t('affiliate_link_homepage') }}
      </h2>

      <article class="box">
        <div class="columns">
          <div class="column">
            <h2 class="title is-5 mb-3">
              {{ $t('homepage') }}
            </h2>
            <p>
              <a
                class="color-inherit"
                :href="url"
                v-clipboard:copy="url"
                v-clipboard:success="() => $copySuccess()"
                v-clipboard:error="() => $copyError()"
                @click.prevent
              >
                <b-tooltip :label="$t('click_to_copy')">
                  <b-icon icon="clipboard" />
                </b-tooltip>
                {{ url }}
              </a>
            </p>
          </div>
          <div class="column is-narrow">
            <b-button
              type="is-primary is-custom"
              v-clipboard:copy="url"
              v-clipboard:success="() => $copySuccess()"
              v-clipboard:error="() => $copyError()"
            >
              {{ $t('copy_link') }}
            </b-button>
          </div>
        </div>
      </article>

      <h2 class="title is-4 is-custom">
        {{ $t('affiliate_links_by_training') }}
      </h2>

      <AffiliateTrainingCard
        v-for="training in allowedTrainings"
        :key="training.uuid"
        :affiliate="affiliate"
        :training="training"
      />
      <div v-if="allowedTrainings.length === 0">
        {{ $t('no_trainings') }}
      </div>

      <h2 class="title is-4 is-custom">
        {{ $t('affiliate_links_by_bundle') }}
      </h2>

      <AffiliateBundleCard
        v-for="bundle in allowedBundles"
        :key="bundle.uuid"
        :affiliate="affiliate"
        :bundle="bundle"
      />
      <div v-if="allowedBundles.length === 0">
        {{ $t('no_bundles') }}
      </div>
    </template>
  </section>
</template>
