<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    floating: {
      type: Boolean,
      default: false,
    },
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isOpened: false,
  }),
  computed: {
    selectAllText() {
      return this.$t('select_all');
    },
    unselectAllText() {
      return this.$t('unselect_all');
    },
    noDataText() {
      return this.$t('no_data');
    },
  },
  methods: {
    toggle() {
      if (this.loading) {
        return;
      }

      this.isOpened = !this.isOpened;
    },
    close() {
      if (this.loading) {
        return;
      }

      this.isOpened = false;
    },
    select(value) {
      let newValue = this.value.slice();

      if (newValue.includes(value)) {
        newValue = newValue.filter((v) => v !== value);
      } else {
        newValue.push(value);
      }

      this.$emit('input', newValue);
      this.$nextTick(() => this.$refs.input.checkHtml5Validity());
    },
    selectAll() {
      this.$emit('input', this.$slots.default.map((v) => v.componentInstance.value));
    },
    unSelectAll() {
      this.$emit('input', []);
    },
  },
  i18n: {
    messages: {
      en: {
        select_all: 'Select all',
        unselect_all: 'Unselect all',
        no_data: 'No data',
      },
      fr: {
        select_all: 'Tout cocher',
        unselect_all: 'Tout décocher',
        no_data: 'Aucunes données',
      },
    },
  },
};
</script>

<template>
  <div class="compbms" v-click-outside="close">
    <div class="control" :class="{'is-loading': loading}">
      <button
        type="button"
        class="compbms_btn cursor-pointer input has-text-left is-flex is-justify-content-space-between"
        @click="toggle"
      >
      <span>
        <b-input ref="input" class="w-0 h-0 p-0 border-0 is-clipped" :required="required && !value.length" />
        <slot v-if="$slots.label" name="label" />
        <template v-else>{{ label }}</template>
        ({{ value.length }})
      </span>
      <b-icon v-show="!loading" icon="chevron-down" />
    </button>
    </div>
    <ul
      v-show="isOpened || alwaysOpen"
      class="compbms_list box p-2"
      :class="{'is-floating border-grey-2': floating, 'is-primary mt-3': !floating}">
      <li v-if="$slots.default" class="mb-2">
        <a
          v-show="value.length < (($slots.default && $slots.default.length) || 0)"
          href="#"
          class="tdecoration-underline"
          @click.prevent="selectAll">
          {{ selectAllText }}
        </a>
        <a
          v-show="value.length == (($slots.default && $slots.default.length) || 0)"
          href="#"
          class="tdecoration-underline"
          @click.prevent="unSelectAll">
          {{ unselectAllText }}
        </a>
      </li>
      <slot v-if="$slots.default" />
      <li v-else>
        <slot name="empty">
          <p class="has-text-centered p-5">
            {{ noDataText }}
          </p>
        </slot>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.compbms {
  position: relative;

  &_list {
    // @include scrollbars(12px, 12px, $primary, $theme_color_grey_light);
    width: 100%;
    overflow: auto;
    max-height: 304px;

    &.is-floating {
      position: absolute;
      z-index: 100;
      top: $control-height;
      left: 0;
      box-shadow: 0 2px 24px rgba($theme_color_black, 0.2);
    }
  }
}
</style>
