<script>
import DOMPurify from 'dompurify';
import utilsMixin from '@shared/mixins/utils';
import AppLoading from '@shared/components/AppLoading.vue';

export default {
  mixins: [utilsMixin],
  components: {
    CommunityDescriptionForm: () => ({
      loading: AppLoading,
      component: import(
        '@shared/components/CommunityDescriptionForm.vue'
      ),
    }),
  },
  head() {
    return {
      title: this.$t('pages.community_index.title'),
    };
  },
  data: () => ({
    isEditing: false,
  }),
  computed: {
    description() {
      return DOMPurify.sanitize(
        this.$store.getters['community/communityStore']
          .community_options
          .description,
      );
    },
  },
};
</script>

<template>
  <div>
    <h1 class="title">
      {{ $t('pages.community_index.home') }}
    </h1>

    <CommunityDescriptionForm v-if="isEditing" @done="isEditing = false" />
    <template v-else>
      <div v-if="description" class="box content" v-html="description" />
      <p v-else>
        {{ $t('pages.community_index.no_description') }}
      </p>
    </template>

    <p v-if="isStoreAdmin && !isEditing" class="mt-3">
      <b-button type="is-primary" @click="isEditing = true">
        {{ $t('pages.community_index.modify') }}
      </b-button>
    </p>
  </div>
</template>
