<script>
import transform from 'lodash/transform';
import blockTypes from '@shared/assets/data/training_item_content_block_types';
import EditorContentBlockSelector from '@shared/components/EditorContentBlockSelector.vue';
import { editorWithBlocks } from '@app/mixins/trainings';
import EditorLayout from '@app/layouts/EditorLayout.vue';
import TrainingItemResourcesEditor from '@app/components/TrainingItemResourcesEditor.vue';
import TrainingItemEditorStatusField from '@app/components/TrainingItemEditorStatusField.vue';

export default {
  mixins: [editorWithBlocks],
  components: {
    EditorContentBlockSelector,
    TrainingItemResourcesEditor,
    TrainingItemEditorStatusField,
    EditorLayout,
    ...transform(blockTypes, (result, v, k) => (result[`ContentBlock${k}`] = v.component), {}),
  },
  i18n: {
    messages: {
      fr: {
        statusOption: {
          draft: 'Mettre le devoir en brouillon',
          published: 'Publier le devoir',
        },
      },
    },
  },
  form: {
    side: {
      max_notation: {
        label: 'Score maximum (optionnel)',
        column: 'is-12 pt-0',
        inputAttrs: {
          maxlength: 32,
          hasCounter: false,
        },
        tooltip: `
          Utilisez ce champ pour définir un système de notation
          en reseignant le socre maximum que l'on peut obtenir
          sur le devoir.
        `,
        tooltipAttrs: {
          multilined: true,
        },
      },
    },
    main: {
      description: {
        label: 'Rédigez les consignes du devoir à rendre',
        type: 'editor',
        column: 'is-12 pt-0',
        inputAttrs: {
          maxlength: 512,
        },
        tooltip: 'Rédigez les consignes du devoir que doit vous rendre votre apprenant',
        tooltipAttrs: {
          multilined: true,
        },
      },
    },
  },
  static: { blockTypes },
  data() {
    return {
      trainingItem: {
        id: null,
        parent_id: null,
        type: 'ASSIGNMENT',
        name: '',
        draft: false,
        resources: [],
        min_complete_duration: 0,
        specific_content: {
          max_notation: '',
          max_notation_enabled: false,
          multi_assets_enabled: false,
          multi_submissions_enabled: false,
          private_discussion_enabled: false,
          expected_duration: '',
        },
      },
    };
  },
  methods: {
    onOptionChange(key, value) {
      this.trainingItem.specific_content[key] = value;

      if (!this.$store.getters['auth/hasExpertPlan']) {
        this.$nextTick(() => {
          this.trainingItem.specific_content[key] = false;
          this.isDirty = true;
        });

        this.$showMessage.goPro(
          () => this.$router.push({
            name: 'plan',
            query: { onglet: 'vous' },
          }),
          null,
          { plan: 'EXPERT' },
        );
        return;
      }

      this.isDirty = true;
    },
    save(silent = false) {
      this.onSave(silent, (trainingItem) => {
        if (!this.showMinCompleteDuration) {
          trainingItem.min_complete_duration = 0;
        }

        return trainingItem;
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.handleBeforeRouteLeave(to, from, next);
  },
};
</script>

<template>
  <EditorLayout class="compa" ref="layout" :trainingItem="trainingItem">
    <template #sidenav>
      <b-skeleton v-if="initialLoading" height="300" />
      <b-tabs v-else v-model="activeTab" size="is-small" :animated="false" expanded>
        <b-tab-item label="Devoir">
          <b-field label="Renseignez le titre de ce devoir">
            <b-input
              ref="trainingItemName"
              v-model="trainingItem.name"
              :has-counter="false"
              maxlength="128"
              required
              autofocus
              @keyup.native.enter="isDirty && dataIsValid(isLoading) && save()"
            />
          </b-field>
          <TrainingItemEditorStatusField
            class="field"
            v-model="trainingItem.draft"
          >
            <template #draft>
              <span v-t="'statusOption.draft'" />
            </template>
            <template #published>
              <span v-t="'statusOption.published'" />
            </template>
          </TrainingItemEditorStatusField>

          <hr class="has-background-grey">

          <div class="field">
            <b-switch
              class="w-full"
              size="is-small"
              :value="trainingItem.specific_content.max_notation_enabled"
              @input="onOptionChange('max_notation_enabled', $event)"
            >
              Activer la notation
              <b-tooltip class="ml-3" position="is-top" multilined>
                <template #content>
                  <p>
                    Définissez la note maximale pouvant être obtenue par vos apprenants
                  </p>
                </template>
                <b-icon class="pl-5 pr-3" icon="question-circle" pack="far" />
              </b-tooltip>
              <AppFeatureBadge v-if="hasExpertPlan" class="ml-2" />
            </b-switch>
          </div>
          <b-field
            v-if="hasExpertPlan && trainingItem.specific_content.max_notation_enabled"
            label="Note maximale (en chiffre)">
            <b-input
              type="number"
              v-model="trainingItem.specific_content.max_notation"
              required
              @keyup.native.enter="isDirty && dataIsValid(isLoading) && save()"
            />
          </b-field>
          <div class="field">
            <b-switch
              class="w-full"
              size="is-small"
              :value="trainingItem.specific_content.private_discussion_enabled"
              @input="onOptionChange('private_discussion_enabled', $event)"
            >
              Activer la discussion privée
              <b-tooltip class="ml-3" position="is-left" multilined>
                <template #content>
                  <p>
                    Utilisez la fonctionnalité de discussion privée
                    pour échanger avec l'apprenant sur ce devoir à tout moment.
                  </p>
                </template>
                <b-icon class="pl-5 pr-3" icon="question-circle" pack="far" />
              </b-tooltip>
              <AppFeatureBadge v-if="hasExpertPlan" class="ml-2" />
            </b-switch>
          </div>
          <div class="field">
            <b-switch
              class="w-full"
              size="is-small"
              :value="trainingItem.specific_content.multi_assets_enabled"
              @input="onOptionChange('multi_assets_enabled', $event)"
            >
              Accepter plusieurs documents
              <b-tooltip class="ml-3" position="is-left" multilined>
                <template #content>
                  <p>
                    Vous pouvez autoriser les apprenants
                    à soumettre plusieurs liens et/ou fichiers
                    pour ce devoir.
                  </p>
                </template>
                <b-icon class="pl-5 pr-3" icon="question-circle" pack="far" />
              </b-tooltip>
              <AppFeatureBadge v-if="hasExpertPlan" class="ml-2" />
            </b-switch>
          </div>
          <div class="field">
            <b-switch
              class="w-full"
              size="is-small"
              :value="trainingItem.specific_content.multi_submissions_enabled"
              @input="onOptionChange('multi_submissions_enabled', $event)"
            >
              Activer les allers-retours
              <b-tooltip class="ml-3" position="is-left" multilined>
                <template #content>
                  <p>
                    En activant cette option, vous permettez à votre apprenant
                    de resoumettre son devoir si vous jugez que
                    c'est nécessaire lors de sa correction
                  </p>
                </template>
                <b-icon class="pl-5 pr-3" icon="question-circle" pack="far" />
              </b-tooltip>
              <AppFeatureBadge v-if="hasExpertPlan" class="ml-2" />
            </b-switch>
          </div>
          <b-field v-if="isNotPercentPlan && showMinCompleteDuration" label="Renseignez la durée (en minutes)">
            <b-input
              v-model.number="trainingItem.min_complete_duration"
              type="number"
              step="1"
              min="1"
              required
              @keyup.native.enter="isDirty && dataIsValid(isLoading) && save()"
            />
          </b-field>

          <hr class="has-background-grey">

          <draggable v-if="contentBlocks.length" v-model="contentBlocks">
            <b-button
              v-for="contentBlock in contentBlocks"
              :key="contentBlock.id"
              class="mb-2 cursor-grab is-justify-content-flex-start"
              type="is-text border-dashed"
              :icon-left="$options.static.blockTypes[contentBlock.type].icon"
              :icon-pack="$options.static.blockTypes[contentBlock.type].pack"
              :label="$t($options.static.blockTypes[contentBlock.type].text)"
              expanded
              @click.prevent="scrollToBlock(contentBlock)"
            />
          </draggable>

          <b-button type="is-text" icon-left="plus" expanded @click="activeTab = 1">
            Ajouter un bloc
          </b-button>
        </b-tab-item>
        <b-tab-item label="Blocs">
          <h2 class="title is-8 has-text-centered has-text-weight-normal">
            Cliquez sur un des blocs ci-dessous pour ajouter les consignes de votre devoir.
          </h2>

          <EditorContentBlockSelector :autofocus="activeTab == 1" @add="addBlock" />
        </b-tab-item>
        <b-tab-item label="Annexes">
          <TrainingItemResourcesEditor
            :training="currentTraining"
            :training-item="trainingItem"
            @change="onResourceChange"
            @delete="onResourceDelete"
          />
        </b-tab-item>
      </b-tabs>
    </template>
    <template #header_right>
      <b-button
        v-if="pageURL"
        type="is-text"
        size="is-small"
        icon-left="eye"
        @click="onViewDraft">
        Voir la page
      </b-button>
      <b-tooltip label="Déjà sauvegardé !" position="is-bottom" :active="!!(!isDirty && trainingItem.id)">
        <b-button
          type="is-primary"
          size="is-small"
          native-type="submit"
          :loading="isLoading"
          :disabled="!isDirty"
          @click="dataIsValid(isLoading) && save()"
        >
          Sauvegarder
        </b-button>
      </b-tooltip>
    </template>
    <template #footer>
      <b-button
        type="is-primary"
        native-type="submit"
        :loading="isLoading"
        :disabled="!isDirty"
        @click="dataIsValid(isLoading) && save()"
      >
        Sauvegarder les modifications
      </b-button>
    </template>
    <template #main>
      <b-skeleton v-if="initialLoading" height="500" />
      <div v-else>
        <div class="container mb-6">
          <h1 class="title is-2 has-text-centered">
            {{ trainingItem.name }}
          </h1>
        </div>
        <draggable
          v-if="contentBlocks.length"
          v-model="contentBlocks"
          handle=".handle"
        >
          <div
            v-for="(contentBlock, k) in contentBlocks"
            :key="contentBlock.id"
            :class="{
              container: contentBlock.isEditing || !contentBlock.data.__isWide
            }"
          >
            <Component
              :is="`ContentBlock${contentBlock.type}`"
              :ref="`block${contentBlock.id}`"
              :contentBlock="contentBlock"
              :meta="{position: k, total: contentBlocks.length}"
              :label="$t($options.static.blockTypes[contentBlock.type].text)"
              :viewProps="{
                type: contentBlock.type,
                store: authStore,
                training: currentTraining,
                trainingItem: trainingItem,
                trainingItems: currentTraining.items,
              }"
              :training="currentTraining"
              :trainingItem="trainingItem"
              :notification="$options.static.blockTypes[contentBlock.type].notification"
              :notificationClass="$options.static.blockTypes[contentBlock.type].notificationClass"
              @cancel="cancelBlock(contentBlock)"
              @remove="removeBlock(contentBlock)"
              @save="saveBlock(contentBlock, $event)"
              @edit="editBlock(contentBlock)"
              @moveup="moveBlockUp(contentBlock, k)"
              @movedown="moveBlockDown(contentBlock, k)"
            />
          </div>
        </draggable>
        <div class="border-dashed bradius-4 py-20 has-text-centered">
          <p>
            Ajouter des blocs de contenus <br>
            pour donner les consignes de ce devoir à vos apprenants.
          </p>
          <p class="mt-5">
            <button
              type="button"
              class="compa_btn bradius-4 cursor-pointer"
              @click="addBlock('video')"
            >
              <b-icon :icon="$options.static.blockTypes.video.icon" size="is-large" />
              <br />
              <span class="is-size-7">
                {{ $t($options.static.blockTypes.video.text) }}
              </span>
            </button>
            <button
              type="button"
              class="compa_btn ml-3 bradius-4 cursor-pointer"
              @click="addBlock('richtext')"
            >
              <b-icon :icon="$options.static.blockTypes.richtext.icon" size="is-large" />
              <br />
              <span class="is-size-7">
                {{ $t($options.static.blockTypes.richtext.text) }}
              </span>
            </button>
            <button
              type="button"
              class="compa_btn ml-3 bradius-4 cursor-pointer"
              @click="addBlock('image')"
            >
              <b-icon :icon="$options.static.blockTypes.image.icon" size="is-large" />
              <br />
              <span class="is-size-7">
                {{ $t($options.static.blockTypes.image.text) }}
              </span>
            </button>
            <button
              type="button"
              class="compa_btn ml-3 bradius-4 cursor-pointer"
              @click="addBlock('url')"
            >
              <b-icon :icon="$options.static.blockTypes.url.icon" size="is-large" />
              <br />
              <span class="is-size-7">
                {{ $t($options.static.blockTypes.url.text) }}
              </span>
            </button>
            <button
              type="button"
              class="compa_btn ml-3 bradius-4 cursor-pointer"
              @click="activeTab = 1"
            >
              <b-icon icon="plus" size="is-large" />
              <br />
              <span class="is-size-7">
                Plus
              </span>
            </button>
          </p>
        </div>
      </div>
    </template>
  </EditorLayout>
</template>

<style lang="scss" scoped>
.compa {
  .container {
    max-width: 728px;
  }

  &_btn {
    height: 100px;
    width: 112px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: $white;

    &:hover {
      color: $white;
      background: $primary;
    }
  }
}
</style>
