<script>
// import DOMPurify from 'dompurify';
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
import FileResolver from '@shared/services/FileResolver';
import utilsMixin from '@shared/mixins/utils';
import responsiveMixin from '@shared/mixins/responsive';
import communityMixin from '@shared/mixins/community';
import CommunityGroupBanner from '@shared/components/CommunityGroupBanner.vue';
import GenericUploadForm from '@shared/components/GenericUploadForm.vue';
import CommunityPostForm from '@shared/components/CommunityPostForm.vue';
import CommunityMemberForm from '@shared/components/CommunityMemberForm.vue';

export default {
  mixins: [
    utilsMixin,
    communityMixin(),
    responsiveMixin({ isMobile: 767 }),
  ],
  components: {
    CommunityGroupBanner,
    CommunityPostForm,
    CommunityMemberForm,
    GenericUploadForm,
  },
  analytics: { skip: true },
  head() {
    let title = this.$t('pages.group.loading');

    if (this.currentGroup) {
      title = this.currentGroup.name;
    }

    return {
      titleTemplate: (chunk) => (chunk ? `${chunk} - ${title}` : title),
      afterNavigation: () => this.$gtag.pageView(),
    };
  },
  data: () => ({
    isLogoModalOpen: false,
    isBannerModalOpen: false,
  }),
  computed: {
    ...mapState('community', [
      'currentGroup',
      'groupIsLoading',
      'currentGroupIsFetching',
      'postFormIsOpen',
      'postFormEditedData',
      'memberFormIsOpen',
      'memberIsLoading',
    ]),
    ...mapGetters('community', [
      'communityStore',
    ]),
  },
  methods: {
    ...mapMutations('community', [
      'showGroupForm',
      'closePostForm',
      'showMemberForm',
      'closeMemberForm',
      'incrementGroupCounter',
    ]),
    ...mapActions('community', [
      'getGroup',
      'updateGroup',
      'deleteGroup',
      'updateMember',
    ]),
    showDeleteDialog() {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        title: this.$t('pages.group.delete.title'),
        message: this.$t('pages.group.delete.message', { name: this.currentGroup.name }),
        focusOn: 'cancel',
        confirmText: this.$t('pages.group.delete.confirm'),
        closeOnConfirm: false,
        onConfirm: (_, { close }) => {
          const loader = this.$buefy.loading.open();
          this.deleteGroup({ uuid: this.currentGroup.uuid })
            .then(() => {
              close();
              if (this.$route.params.uuid === this.currentGroup.uuid) {
                this.$router.push({ name: 'community' });
              }
            })
            .finally(() => loader.close());
        },
      });
    },
    onBannerUpload(banner) {
      this.updateGroup({
        uuid: this.currentGroup.uuid,
        data: { banner },
      })
        .then(() => {
          this.isBannerModalOpen = false;
          this.$showMessage.success();
        });
    },
    onLogoUpload(logo) {
      this.updateGroup({
        uuid: this.currentGroup.uuid,
        data: { logo },
      })
        .then(() => {
          this.isLogoModalOpen = false;
          this.$showMessage.success();
        });
    },
    removeImage(type) {
      this.updateGroup({
        uuid: this.currentGroup.uuid,
        data: { [type]: {} },
      })
        .then(() => this.$showMessage.success());
    },
    updateMemberNotif(member) {
      if (
        this.currentGroup.member.notification_settings
        === member.notification_settings
      ) {
        return;
      }

      this.updateMember(member);
    },
  },
  beforeRouteEnter(to, from, next) {
    FileResolver.getStore().then(({ default: store }) => {
      store.dispatch('community/getGroup', { uuid: to.params.uuid })
        .then(() => next())
        .catch((error) => next((vm) => vm.showErrorPage(error)));
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (
      to.matched.some((v) => v.name === 'community_group')
      && to.params.uuid === from.params.uuid
    ) {
      next();
      return;
    }

    this.getGroup({ uuid: to.params.uuid })
      .then(() => next())
      .catch((error) => next((vm) => vm.showErrorPage(error)));
  },
};
</script>

<template>
  <div class="compg mw-768 mx-auto is-relative">
    <b-skeleton v-if="currentGroupIsFetching || !currentGroup" height="700" />
    <template v-else>
      <div>
        <CommunityGroupBanner
          tag="header"
          :store="communityStore"
          :group="currentGroup"
        >
          <template #bannerActions>
            <b-button
              v-if="canManageCommunityEntity"
              type="is-white"
              size="is-small"
              icon-left="pen"
              @click="isBannerModalOpen = true"
            />
            <b-button
              v-if="canManageCommunityEntity && currentGroup.banner.url"
              type="is-danger"
              size="is-small"
              icon-left="trash"
              inverted
              @click="removeImage('banner')"
            />
          </template>
          <template #logoActions>
            <b-button
              v-if="canManageCommunityEntity"
              type="is-white"
              size="is-small"
              icon-left="pen"
              @click="isLogoModalOpen = true"
            />
            <b-button
              v-if="canManageCommunityEntity && currentGroup.logo.url"
              type="is-danger"
              size="is-small"
              icon-left="trash"
              inverted
              @click="removeImage('logo')"
            />
          </template>
          <template #default>
            <template v-if="canManageCommunityEntity">
              <b-tooltip
                class="flex-none"
                position="is-left"
                :label="$t('pages.group.invite.tooltip')"
              >
                <b-button
                  type="is-black"
                  icon-left="user-plus"
                  @click="showMemberForm()"
                >
                  {{ $t('pages.group.invite.button') }}
                </b-button>
              </b-tooltip>
              <b-tooltip
                class="flex-none ml-3"
                position="is-left"
                :label="$t('pages.group.modify.tooltip')"
              >
                <b-button
                  icon-left="edit"
                  @click="showGroupForm({ group: currentGroup })"
                />
              </b-tooltip>
              <b-tooltip
                class="flex-none ml-3"
                position="is-left"
                :label="$t('pages.group.delete.tooltip')"
              >
                <b-button
                  icon-left="trash"
                  type="is-danger"
                  outlined
                  @click="showDeleteDialog()"
                />
              </b-tooltip>
            </template>

            <b-tooltip
              v-if="currentGroup.member && currentGroup.member.role === 'MEMBER'"
              class="flex-none ml-3"
              position="is-left"
              :label="$t('pages.group.notifications.tooltip')"
            >
              <b-dropdown
                position="is-bottom-left"
                :value="currentGroup.member.notification_settings"
                append-to-body
              >
                <template #trigger>
                  <b-button
                    icon-left="bell"
                    :loading="memberIsLoading"
                  />
                </template>

                <b-dropdown-item
                  class="wspace-normal"
                  value="always"
                  @click="updateMemberNotif({
                      notification_settings:'always',
                  })"
                >
                  <h4 class="title is-5">
                    {{ $t('pages.group.notifications.all_members.title') }}
                  </h4>

                  <p class="subtitle is-7">
                    {{ $t('pages.group.notifications.all_members.description') }}
                  </p>
                </b-dropdown-item>
                <b-dropdown-item
                  value="admin_or_owner"
                  @click="updateMemberNotif({
                    notification_settings: 'admin_or_owner',
                  })"
                >
                  <h4 class="title is-5">
                    {{ $t('pages.group.notifications.admins.title') }}
                  </h4>

                  <p class="subtitle is-7">
                    {{ $t('pages.group.notifications.admins.description') }}
                  </p>
                </b-dropdown-item>
                <b-dropdown-item
                  value="none"
                  @click="updateMemberNotif({
                    notification_settings: 'none',
                  })"
                >
                  <h4 class="title is-5">
                    {{ $t('pages.group.notifications.none.title') }}
                  </h4>

                  <p class="subtitle is-7">
                    {{ $t('pages.group.notifications.none.description') }}
                  </p>
                </b-dropdown-item>
              </b-dropdown>
            </b-tooltip>
          </template>
        </CommunityGroupBanner>
        <div class="tabs mb-10 is-size-8-mobile">
          <ul>
            <router-link
              tag="li"
              :to="{name: 'community_group_posts'}"
              active-class="is-active"
            >
              <a>{{ $t('pages.group.tabs.discussions') }} ({{ currentGroup.posts_count }})</a>
            </router-link>
            <router-link
              v-if="currentGroup.settings.show_members || canManageCommunityEntity"
              tag="li"
              :to="{name: 'community_group_members'}"
              active-class="is-active"
            >
              <a>{{ $t('pages.group.tabs.members') }} ({{ currentGroup.members_count }})</a>
            </router-link>
            <router-link
              tag="li"
              :to="{name: 'community_group_information'}"
              active-class="is-active"
            >
              <a>{{ $t('pages.group.tabs.about') }}</a>
            </router-link>
            <router-link
              v-if="canManageCommunityEntity"
              tag="li"
              :to="{name: 'community_group_abuses'}"
              active-class="is-active"
            >
              <a>{{ $t('pages.group.tabs.reports') }} ({{ currentGroup.abuses_count }})</a>
            </router-link>
          </ul>
        </div>
        <div>
          <router-view />
        </div>
      </div>

      <b-modal
        :active="postFormIsOpen"
        :can-cancel="false"
        :fullScreen="isMobile"
        hasModalCard
      >
        <CommunityPostForm
          :group="currentGroup"
          :post="postFormEditedData"
          @cancel="closePostForm"
        />
      </b-modal>
      <b-modal
        :active="memberFormIsOpen"
        :canCancel="false"
        hasModalCard
        @close="closeMemberForm()"
      >
        <CommunityMemberForm
          class="mw-640"
          :group="currentGroup"
          @cancel="closeMemberForm()"
        />
      </b-modal>
      <b-modal
        :active="isLogoModalOpen"
        :canCancel="false"
        hasModalCard
      >
        <GenericUploadForm
          type="image"
          :storeUUID="communityStore.uuid"
          :contextId="currentGroup.uuid"
          contextType="GROUP"
          @cancel="isLogoModalOpen = false"
          @done="onLogoUpload"
        />
      </b-modal>
      <b-modal
        :active="isBannerModalOpen"
        :canCancel="false"
        hasModalCard
      >
        <GenericUploadForm
          type="image"
          :storeUUID="communityStore.uuid"
          :contextId="currentGroup.uuid"
          contextType="GROUP"
          @cancel="isBannerModalOpen = false"
          @done="onBannerUpload"
        >
          <p class="notification">
          {{ $t('pages.group.banner.dimensions') }}
          </p>
        </GenericUploadForm>
      </b-modal>

      <b-loading v-if="groupIsLoading" active />
    </template>
  </div>
</template>
