<script>
import { mapState } from 'vuex';
import utilsMixin from '@shared/mixins/utils';
import AffiliateConditionList from '@school/components/AffiliateConditionList.vue';

export default {
  mixins: [utilsMixin],
  components: { AffiliateConditionList },
  i18n: {
    messages: {
      en: {
        ACCEPTED: 'accepted',
        REJECTED: 'rejected',
      },
      fr: {
        ACCEPTED: 'confirmée',
        REJECTED: 'rejetée',
      },
    },
  },
  data: () => ({
    hasPayoutLoading: false,
    newPayout: {
      payout_request_amount: null,
      payout_info: '',
      payout_invoice_url: null,
    },
  }),
  computed: {
    ...mapState('store', {
      store: 'data',
    }),
    ...mapState('auth', {
      affiliate: 'affiliateData',
    }),
    form() {
      return {
        payout_request_amount: {
          type: 'number',
          column: 'is-5',
          label: this.$t('affiliate_payout_request_amount_label'),
          inputAttrs: {
            required: true,
            min: 1,
            step: 0.01,
          },
        },
        payout_invoice_url: {
          type: 'text',
          column: 'is-7',
          label: this.$t('affiliate_payout_invoice_url_label'),
          inputAttrs: {
            maxlength: 512,
            pattern: 'https?://.+',
          },
          tooltip: this.$t('affiliate_payout_invoice_url_tooltip'),
          tooltipAttrs: {
            position: 'is-left',
            multilined: true,
          },
        },
        payout_info: {
          type: 'textarea',
          column: 'is-12',
          label: this.$t('affiliate_payout_info_label'),
          inputAttrs: {
            required: true,
            maxlength: 512,
          },
        },
      };
    },
  },
  methods: {
    askPayout() {
      if (this.hasPayoutLoading) {
        return;
      }

      this.hasPayoutLoading = true;
      const newPayout = { ...this.newPayout };

      if (newPayout.payout_invoice_url === '') {
        newPayout.payout_invoice_url = null;
      }

      this.$store.dispatch('auth/askAffiliatePayout', newPayout)
        .finally(() => (this.hasPayoutLoading = false));
    },
    showComment(payout) {
      this.$buefy.dialog.alert({
        message: payout.comment,
      });
    },
  },
};
</script>

<template>
  <div>
    <b-skeleton v-if="!affiliate" height="400" />
    <template v-else>
      <section>
        <h2 class="title is-4 is-custom">
          {{ $t('affiliate_conditions_title') }}
        </h2>

        <AffiliateConditionList class="mb-5" />

        <h2 class="title is-4 is-custom">
          {{ $t('affiliate_new_payout_request_title') }}
        </h2>

        <div v-if="affiliate.payout_request_at" class="box">
          <p>
            <strong>{{ $t('affiliate_requested_payout') }}</strong> :
            {{ affiliate.payout_request_amount | formatPrice }}
          </p>
          <p v-if="affiliate.payout_invoice_url" class="break-words">
            <strong>{{ $t('affiliate_invoice_link') }}</strong> :
            <a
              class="has-text-black is-custom tdecoration-underline"
              :href="affiliate.payout_invoice_url"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ affiliate.payout_invoice_url }}
            </a>
          </p>
          <p>
            <strong>{{ $t('affiliate_comment') }}</strong> :
            <br>
            <span class="wspance-preline">
              {{ affiliate.payout_info }}
            </span>
          </p>
          <hr>
          <p>
            {{ $t('affiliate_request_made') }}
            <b-tooltip>
              <template #content>
                {{ affiliate.payout_request_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
              </template>
              {{ affiliate.payout_request_at | momentFromUTC | moment('from') }}
            </b-tooltip>
          </p>
          <p>
            {{ $t('affiliate_waiting_for_processing') }}
          </p>
        </div>
        <form v-else class="box" @submit.prevent="askPayout">
          <div class="columns is-multiline">
            <div
              v-for="(field, k) in form"
              :key="k"
              class="column mb-0"
              :class="field.column"
            >
              <GeneratorField
                :fieldName="k"
                :fieldDefinition="field"
                :formData.sync="newPayout"
              />
            </div>
            <div class="column is-12 has-text-right">
              <b-button
                native-type="submit"
                type="is-primary is-custom"
                :loading="hasPayoutLoading"
              >
                {{ $t('affiliate_request_payout') }}
              </b-button>
            </div>
          </div>
        </form>
      </section>

      <section v-if="affiliate.payout_history.length > 0" class="mt-10">
        <h2 class="title is-4 is-custom">
          {{ $t('affiliate_payout_history') }}
        </h2>

        <b-table class="box" :data="affiliate.payout_history">
          <b-table-column
            field="amount"
            :label="$t('affiliate_request_made_on')"
            v-slot="{ row }"
          >
            {{ $t('affiliate_on') }} {{ row.request_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
          </b-table-column>
          <b-table-column
            field="amount"
            :label="$t('affiliate_decision')"
            v-slot="{ row }"
          >
            <p
              class="has-text-weight-bold"
              :class="{
                'has-text-success': row.decision === 'ACCEPTED',
                'has-text-danger': row.decision === 'REJECTED',
              }"
            >
              <span v-t="row.decision" />
              {{ $t('affiliate_on') }} {{ row.decision_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
            </p>
          </b-table-column>
          <b-table-column
            field="amount"
            :label="$t('affiliate_amount')"
            numeric
            v-slot="{ row }"
          >
            {{ row.amount | formatPrice }}
          </b-table-column>
          <b-table-column :label="$t('affiliate_information')" v-slot="{ row }">
            <div>
              <p v-if="row.invoice_url">
                <a
                  class="has-text-black"
                  :href="row.invoice_url"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b-icon icon="receipt" />
                  {{ $t('affiliate_view_invoice') }}
                </a>
              </p>
              <p v-else>
                <b-icon icon="receipt" />
                {{ $t('affiliate_no_invoice') }}
              </p>
              <p class="mt-1">
                <a class="has-text-black" @click.prevent="showComment(row)">
                  <b-icon icon="comment" />
                  {{ $t('affiliate_view_comment') }}
                </a>
              </p>
            </div>
          </b-table-column>
        </b-table>
      </section>
    </template>
  </div>
</template>
