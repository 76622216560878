<script>
export default {
  i18n: {
    messages: {
      fr: {
        delete_account: 'Suppression du compte',
        delete_account_warning: 'La suppression de votre compte est définitive.',
        delete_button: 'Supprimer mon compte',
        confirm_deletion: 'Confirmez la suppression de votre compte en renseignant votre mot de passe.',
        ok: 'Ok !',
        cancel: 'Annuler',
      },
      en: {
        delete_account: 'Delete Account',
        delete_account_warning: 'Caution : this action is permanent.',
        delete_button: 'Delete my account',
        confirm_deletion: 'Enter your password to confirm the deletion of your account.',
        ok: 'Ok!',
        cancel: 'Cancel',
      },
    },
  },
  methods: {
    deleteAccount() {
      this.$buefy.dialog.prompt({
        message: this.$t('confirm_deletion'),
        type: 'is-danger',
        inputAttrs: { type: 'password' },
        confirmText: this.$t('ok'),
        cancelText: this.$t('cancel'),
        closeOnConfirm: false,
        onConfirm: ((password, { close }) => {
          const loader = this.$buefy.loading.open();
          this.$store.dispatch('auth/delete', { password })
            .then(() => {
              this.$showMessage.success();
              close();
            })
            .catch((error) => this.$errorHandlers.axios(error))
            .finally(() => loader.close());
        }),
      });
    },
  },
};
</script>

<template>
  <div class="box is-flex is-justify-content-space-between is-align-items-center">
    <div class="content">
      <h2 class="is-size-6">
        {{ $t('delete_account') }}
      </h2>
      <p>
        {{ $t('delete_account_warning') }}
      </p>
    </div>
    <b-button type="is-danger" outlined @click="deleteAccount">
      {{ $t('delete_button') }}
    </b-button>
  </div>
</template>
