<script>
import { mapGetters, mapState } from 'vuex';
import utilsMixin from '@shared/mixins/utils';
import StatisticCard from '@shared/components/StatisticCard.vue';
import AffiliateConditionList from '@school/components/AffiliateConditionList.vue';

export default {
  mixins: [utilsMixin],
  components: { StatisticCard, AffiliateConditionList },
  i18n: {
    messages: {
      en: {
        affiliate_program_closed: 'This affiliate program is currently closed. Visits and sales from affiliate links are not counted and no new commissions can be generated. However, you can still request payment for your already generated commissions.',
        visits: 'Visits',
        total_visits_description: 'Total number of visits generated through your affiliate links',
        sales: 'Sales',
        total_sales_description: 'Total sales generated via your affiliate links',
        conversion_rate: 'Conversion Rate',
        total_conversion_description: 'Percentage of visits that generated sales from your affiliate links',
        total_commissions: 'Total Commissions',
        total_amount_description: 'Total commissions earned through your affiliate links',
        available_amount: 'Available Amount',
        total_available_description: 'The eligible amount of your earned commissions',
        pending_amount: 'Pending Amount',
        total_pending_description: 'The amount of your commissions pending eligibility',
        dashboard: 'Dashboard',
        profile_and_links: 'Profile and Links',
        payout_requests: 'Payout Requests',
        affiliate_program: 'Affiliate Program',
        affiliate_program_welcome: 'Welcome to the affiliate program of <strong>{storeName}</strong>. You can join this program with one click to start earning commissions from your recommendations.',
        what_is_affiliation: 'What is affiliation?',
        affiliation_explanation_1: 'Affiliation is a system that rewards recommendations and word-of-mouth. By joining the affiliate program, we provide you with tracking links that you can use to recommend our store\'s offers.',
        affiliation_explanation_2: 'When one or more sales are generated from your affiliate links, you earn a commission on these sales!',
        affiliation_explanation_3: 'There\'s no commitment on your part: you have no obligation to produce results and can stop participating in the program at any time.',
        program_conditions: 'Program Conditions',
        conditions_may_change: 'The terms and conditions of this program are subject to change.',
        join_affiliate_program: 'Join the affiliate program',
      },
      fr: {
        affiliate_program_closed: 'Ce programme d\'affiliation est actuellement fermé. Les visites et ventes provenant des liens d\'affiliation ne sont pas comptabilisées et aucune nouvelle commission ne peut être générée. Vous pouvez cependant toujours demander le paiement de vos commissions déjà générées.',
        visits: 'Visites',
        total_visits_description: 'Nombre de visites générées à partir de vos liens d\'affiliation',
        sales: 'Ventes',
        total_sales_description: 'Nombre de ventes générées à partir de vos liens d\'affiliation',
        conversion_rate: 'Taux de conversion',
        total_conversion_description: 'Pourcentage de visites ayant généré des ventes à partir de vos liens d\'affiliation',
        total_commissions: 'Total des commissions',
        total_amount_description: 'Montant total des commissions générées à partir de vos liens d\'affiliation',
        available_amount: 'Montant disponible',
        total_available_description: 'Montant éligible de vos commissions',
        pending_amount: 'Montant en attente',
        total_pending_description: 'Montant en attente d\'éligibilité de vos commissions',
        dashboard: 'Tableau de bord',
        profile_and_links: 'Profil et liens',
        payout_requests: 'Demandes de paiement',
        affiliate_program: 'Programme d\'affiliation',
        affiliate_program_welcome: 'Bienvenue dans le programme d\'affiliation de <strong>{storeName}</strong>. Vous pouvez rejoindre ce programme en un clic pour commencer à gagner des commissions sur vos recommandations.',
        what_is_affiliation: 'Qu\'est-ce que l\'affiliation ?',
        affiliation_explanation_1: 'L\'affiliation est un système qui récompense les recommandations et le bouche-à-oreille. En rejoignant le programme d\'affiliation, nous vous fournissons des liens de suivi que vous pouvez utiliser pour recommander les offres de notre boutique.',
        affiliation_explanation_2: 'Lorsqu\'une ou plusieurs ventes sont générées à partir de vos liens d\'affiliation, vous gagnez une commission sur ces ventes !',
        affiliation_explanation_3: 'Il n\'y a aucun engagement de votre part : vous n\'avez aucune obligation de produire des résultats et pouvez arrêter de participer au programme à tout moment.',
        program_conditions: 'Conditions du programme',
        conditions_may_change: 'Ces conditions peuvent changer à tout moment.',
        join_affiliate_program: 'Rejoindre le programme d\'affiliation',
      },
    },
  },
  data: () => ({
    isFetching: false,
  }),
  computed: {
    ...mapState('store', {
      store: 'data',
    }),
    ...mapState('auth', {
      affiliate: 'affiliateData',
    }),
    ...mapGetters('auth', {
      authCustomer: 'customer',
    }),
    hasAffiliationDisabled() {
      return (
        this.$store.getters['store/hasPercentPlan']
        || this.$store.getters['store/isAffiliationClosed']
      );
    },
  },
  methods: {
    fetchData() {
      if (this.authCustomer.affiliate_data) {
        this.isFetching = true;
        this.$store.dispatch('auth/getAffiliateData')
          .finally(() => {
            this.isFetching = false;
          });
      }
    },
    join() {
      const loader = this.$buefy.loading.open();
      this.$store.dispatch('auth/joinAffiliates')
        .finally(() => {
          loader.close();
          window.scroll(0, 0);
        });
    },
    redirectIfNotAffiliate() {
      const cannotSeeAffiliate = (
        !this.$store.getters['store/isAffiliationPublic']
        && !this.authCustomer?.affiliate_data
      );

      if (cannotSeeAffiliate) {
        this.$router.push({
          name: this.authCustomer ? 'dashboard' : 'login',
        });
      }
    },
  },
  created() {
    this.redirectIfNotAffiliate();
    this.fetchData();
  },
};
</script>

<template>
  <div class="section">
    <aside
      v-if="hasAffiliationDisabled"
      class="notification is-warning"
    >
      <b-icon icon="exclamation-triangle" />
      {{ $t('affiliate_program_closed') }}
    </aside>

    <template v-if="authCustomer.affiliate_data">
      <section class="columns is-mobile is-multiline">
        <div class="column is-6-touch is-4-desktop">
          <b-skeleton v-if="isFetching" height="150" />
          <StatisticCard v-else>
            <template #title>
              {{ $t('visits') }}
            </template>
            <template #default>
              {{ affiliate.total_visits }}
            </template>
            <template #tooltipContent>
              <span>{{ $t('total_visits_description') }}</span>
            </template>
          </StatisticCard>
        </div>
        <div class="column is-6-touch is-4-desktop">
          <b-skeleton v-if="isFetching" height="150" />
          <StatisticCard v-else>
            <template #title>
              {{ $t('sales') }}
            </template>
            <template #default>
              {{ affiliate.total_sales }}
            </template>
            <template #tooltipContent>
              <span>{{ $t('total_sales_description') }}</span>
            </template>
          </StatisticCard>
        </div>
        <div class="column is-6-touch is-4-desktop">
          <b-skeleton v-if="isFetching" height="150" />
          <StatisticCard v-else>
            <template #title>
              {{ $t('conversion_rate') }}
            </template>
            <template #default>
              {{
                [affiliate.total_sales, affiliate.total_visits] | toPercentage
              }}%
            </template>
            <template #tooltipContent>
              <span>{{ $t('total_conversion_description') }}</span>
            </template>
          </StatisticCard>
        </div>
        <div class="column is-6-touch is-4-desktop">
          <b-skeleton v-if="isFetching" height="150" />
          <StatisticCard v-else>
            <template #title>
              {{ $t('total_commissions') }}
            </template>
            <template #default>
              {{ affiliate.total_amount | formatPrice }}
            </template>
            <template #tooltipContent>
              <span>{{ $t('total_amount_description') }}</span>
            </template>
          </StatisticCard>
        </div>
        <div class="column is-6-touch is-4-desktop">
          <b-skeleton v-if="isFetching" height="150" />
          <StatisticCard v-else>
            <template #title>
              {{ $t('available_amount') }}
            </template>
            <template #default>
              {{ affiliate.total_available | formatPrice }}
            </template>
            <template #tooltipContent>
              <span>{{ $t('total_available_description') }}</span>
            </template>
          </StatisticCard>
        </div>
        <div class="column is-6-touch is-4-desktop">
          <b-skeleton v-if="isFetching" height="150" />
          <StatisticCard v-else>
            <template #title>
              {{ $t('pending_amount') }}
            </template>
            <template #default>
              {{ affiliate.total_pending | formatPrice }}
            </template>
            <template #tooltipContent>
              <span>{{ $t('total_pending_description') }}</span>
            </template>
          </StatisticCard>
        </div>
      </section>

      <nav>
        <div class="tabs mb-10">
          <ul>
            <router-link
              tag="li"
              :to="{name: 'affiliate', params: $route.params}"
              exact-active-class="is-active"
            >
              <a>{{ $t('dashboard') }}</a>
            </router-link>
            <router-link
              tag="li"
              :to="{name: 'affiliate_profile', params: $route.params}"
              exact-active-class="is-active"
            >
              <a>{{ $t('profile_and_links') }}</a>
            </router-link>
            <router-link
              tag="li"
              :to="{name: 'affiliate_payouts', params: $route.params}"
              exact-active-class="is-active"
            >
              <a>{{ $t('payout_requests') }}</a>
            </router-link>
          </ul>
        </div>
      </nav>

      <router-view />
    </template>
    <section v-else class="box mw-640 mx-auto content">
      <h1>{{ $t('affiliate_program') }}</h1>

      <p v-html="$t('affiliate_program_welcome', { storeName: store.name })" />

      <h2>{{ $t('what_is_affiliation') }}</h2>

      <p>{{ $t('affiliation_explanation_1') }}</p>
      <p>{{ $t('affiliation_explanation_2') }}</p>
      <p>{{ $t('affiliation_explanation_3') }}</p>

      <h2>{{ $t('program_conditions') }}</h2>

      <AffiliateConditionList />

      <div
        v-if="store.affiliation_options.description"
        class="mb-4"
        v-html="store.affiliation_options.description"
      />

      <p class="has-text-weight-bold">
        {{ $t('conditions_may_change') }}
      </p>

      <p>
        <b-button type="is-primary is-custom" @click="join">
          {{ $t('join_affiliate_program') }}
        </b-button>
      </p>
    </section>
  </div>
</template>
