<script> import { mapState } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import utilsMixin from '@shared/mixins/utils';
import authMixin from '@shared/mixins/auth';
import APICustomer from '@school/services/API/Customer';

export default {
  mixins: [utilsMixin, authMixin],
  data() {
    return {
      email: '',
      password: '',
      isLoading: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  computed: {
    ...mapState('store', {
      store: 'data',
    }),
  },
  methods: {
    handle() {
      const { email, password } = this;
      this.isLoading = true;
      this.$store.dispatch('auth/login', { email, password })
        .catch((error) => this.handleAuthError(
          error, APICustomer, email,
          () => {
            if (this.$route.name === 'login') {
              return;
            }

            this.$router.push({ query: { connexion: 1 } });
          },
        ))
        .finally(() => (this.isLoading = false));
    },
    getSamlUrl() {
      const w = 520;
      const h = window.innerHeight * 0.7;
      const x = (window.screen.width - w) / 2;
      const y = 100;

      if (this.popup) {
        window.removeEventListener('message', this.popup._onMessageSuccess);
        this.popup.close();
        this.popup = null;
      }

      this.popup = window.open(
        `${window.location.protocol}//${window.location.hostname}/saml2/${this.store.saml_tenant}/login`,
        '_blank',
        `popup=1,toolbar=no,menubar=no,width=${w},height=${h},left=${x},top=${y}`,
      );

      if (this.popup == null) {
        this.showPopupBlockedDialog('de connexion');
        return;
      }

      this.popup._onMessageSuccess = (event) => {
        if (
          (
            event.origin !== (window.origin || window.location.origin)
            && event.source !== window
          ) || event.data?.name !== 'teachizy-saml2-login-popup'
          || event.data?.auth == null
        ) {
          return;
        }

        if (!this.popup.closed) {
          this.$store.dispatch('auth/manualLogin', event.data.auth);
          window.removeEventListener('message', this.popup._onMessageSuccess);
          this.popup.close();
        }
      };

      window.addEventListener('message', this.popup._onMessageSuccess);
    },
    openClerkPopup() {
      const w = 520;
      const h = window.innerHeight * 0.7;
      const x = (window.screen.width - w) / 2;
      const y = 100;

      if (this.clerkPopup) {
        window.removeEventListener('message', this.clerkPopup._onMessageSuccess);
        this.clerkPopup.close();
        this.clerkPopup = null;
      }

      this.clerkPopup = window.open(
        '/auth/clerk',
        '_blank',
        `popup=1,toolbar=no,menubar=no,width=${w},height=${h},left=${x},top=${y}`,
      );

      if (this.clerkPopup == null) {
        this.showPopupBlockedDialog('de connexion');
        return;
      }

      this.clerkPopup._onMessageSuccess = (event) => {
        if (
          (
            event.origin !== (window.origin || window.location.origin)
            && event.source !== window
          ) || event.data?.name !== 'teachizy-oauth-login-popup'
          || event.data?.auth == null
        ) {
          return;
        }

        if (!this.clerkPopup.closed) {
          this.$store.dispatch('auth/manualLogin', event.data.auth);
          window.removeEventListener('message', this.clerkPopup._onMessageSuccess);
          this.clerkPopup.close();
        }
      };

      window.addEventListener('message', this.clerkPopup._onMessageSuccess);
    },
  },
  i18n: {
    messages: {
      en: {
        login_title: 'Login',
        email_address: 'Email address',
        password: 'Password',
        login_button: 'Log in',
        forgot_password: 'Forgot your password',
        saml_login: 'Login with SSO',
      },
      fr: {
        login_title: 'Connexion',
        email_address: 'Adresse e-mail',
        password: 'Mot de passe',
        login_button: 'Se connecter',
        forgot_password: 'Mot de passe oublié',
        saml_login: 'Connexion avec SSO',
      },
    },
  },
};
</script>

<template>
  <section class="box form">
    <form class="p-5" novalidate @submit.prevent="dataIsValid(isLoading) && handle()">
      <h1 class="title is-custom is-4">
        {{ $t('login_title') }}
      </h1>

      <div  v-if="store.sso_options.clerk.enabled" class="field">
        <b-button type="is-primary is-custom" :loading="isLoading" expanded outlined @click="openClerkPopup">
          {{ store.sso_options.clerk.btn_text }}
        </b-button>

        <hr>
      </div>

      <div v-if="store.sso_options.saml.enabled" class="field">
        <b-button type="is-primary is-custom" :loading="isLoading" expanded outlined @click="getSamlUrl">
          {{ store.sso_options.saml.btn_text }}
        </b-button>

        <hr>
      </div>

      <b-field :label="$t('email_address')" v-bind="$getErrorProps($v.email, ['required', 'email'])">
        <b-input v-model="email" type="email" />
      </b-field>

      <b-field :label="$t('password')" v-bind="$getErrorProps($v.password, ['required'])">
        <b-input v-model="password" type="password" password-reveal />
      </b-field>

      <div class="field">
        <b-button type="is-primary is-custom" native-type="submit" :loading="isLoading" expanded>
          {{ $t('login_button') }}
        </b-button>
      </div>

      <div class="field has-text-centered">
        <b-button type="is-text is-custom" @click="$emit('switch')">
          {{ $t('forgot_password') }}
        </b-button>
      </div>
    </form>
  </section>
</template>
