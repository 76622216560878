import axios from 'axios';
import { env } from '@shared/env';

const lang = document.documentElement.lang || 'fr';
axios.defaults.headers.common['Accept-Language'] = lang;

axios.$get = axios.Axios.prototype.$get = function(...args) {
  return this.get(...args).then((res) => res.data);
};
axios.$post = axios.Axios.prototype.$post = function(...args) {
  return this.post(...args).then((res) => res.data);
};
axios.$put = axios.Axios.prototype.$put = function(...args) {
  return this.put(...args).then((res) => res.data);
};
axios.$patch = axios.Axios.prototype.$patch = function(...args) {
  return this.patch(...args).then((res) => res.data);
};
axios.$delete = axios.Axios.prototype.$delete = function(...args) {
  return this.delete(...args).then((res) => res.data);
};

export default axios;

export const api = axios.create({
  baseURL: env.apiURL,
});
api.defaults.headers.common['Accept-Language'] = lang;

export const cms = axios.create({
  baseURL: env.cmsURL,
});

export const schoolPrefix = `/school/stores/${window.initialState?.store?.subdomain}`;

export const storeApi = {
  $get: (path, ...args) => api.$get(path.startsWith('http') ? path : (schoolPrefix + path), ...args),
  $post: (path, ...args) => api.$post(path.startsWith('http') ? path : (schoolPrefix + path), ...args),
  $put: (path, ...args) => api.$put(path.startsWith('http') ? path : (schoolPrefix + path), ...args),
  $patch: (path, ...args) => api.$patch(path.startsWith('http') ? path : (schoolPrefix + path), ...args),
  $delete: (path, ...args) => api.$delete(path.startsWith('http') ? path : (schoolPrefix + path), ...args),
  get: (path, ...args) => api.get(path.startsWith('http') ? path : (schoolPrefix + path), ...args),
  post: (path, ...args) => api.post(path.startsWith('http') ? path : (schoolPrefix + path), ...args),
  put: (path, ...args) => api.put(path.startsWith('http') ? path : (schoolPrefix + path), ...args),
  patch: (path, ...args) => api.patch(path.startsWith('http') ? path : (schoolPrefix + path), ...args),
  delete: (path, ...args) => api.delete(path.startsWith('http') ? path : (schoolPrefix + path), ...args),
};
