<script>
import {
  mapState, mapGetters, mapActions,
} from 'vuex';
import Draggable from 'vuedraggable';
import utilsMixin from '@shared/mixins/utils';
import communityMixin from '@shared/mixins/community';
import responsiveMixin from '@shared/mixins/responsive';

export default {
  mixins: [utilsMixin, communityMixin(), responsiveMixin({ isTouch: 1023 })],
  components: {
    Draggable,
  },
  inject: ['onMenuItemClick'],
  computed: {
    ...mapState('community', [
      'collections',
      'groups',
    ]),
    ...mapGetters('community', [
      'standaloneGroups',
      'collectionsWithGroups',
    ]),
  },
  methods: {
    showCommunityForm() {
      this.onMenuItemClick();
      this.$store.commit('community/showCommunityForm');
    },
    showCollectionForm() {
      this.onMenuItemClick();
      this.$store.commit('community/showCollectionForm');
    },
    showGroupForm() {
      this.onMenuItemClick();
      this.$store.commit('community/showGroupForm');
    },
    ...mapActions('community', [
      'deleteCollectionDialog',
    ]),
    onNavigate(event, group, navigate) {
      if (group.is_accessible) {
        navigate(event);
        this.onMenuItemClick();
        return;
      }

      event.preventDefault();
      this.onMenuItemClick();
      this.$buefy.dialog.alert({
        type: 'is-primary is-custom',
        title: this.$t('information'),
        message: this.$t('no_access_message'),
        confirmText: this.$t('ok'),
        cancelText: this.$t('cancel'),
      });
    },
    onCollectionNavigate(event, navigate) {
      navigate(event);
      this.onMenuItemClick();
    },
    reorderGroups(groups) {
      this.$store.dispatch('community/reorderGroups', groups)
        .then(() => this.$showMessage.success());
    },
    reorderCollections(collections) {
      this.$store.dispatch('community/reorderCollections', collections)
        .then(() => this.$showMessage.success());
    },
  },
  i18n: {
    messages: {
      fr: {
        launch_my_community: 'Lancer ma communauté',
        community_home: 'Accueil de la communauté',
        create_space: 'Créer un espace',
        create_collection: 'Créer une collection',
        collections_description: 'Les collections servent à regrouper des espaces dans le menu',
        community_settings: 'Paramètres communauté',
        this_space_is_hidden: 'Cet espace est caché',
        this_space_is_draft: 'Cet espace est en brouillon',
        no_access_to_space: 'Vous n\'avez pas accès à cet espace',
        create_space_in_collection: 'Créer un espace dans la collection',
        edit_collection: 'Modifier la collection',
        delete_collection: 'Supprimer la collection',
        information: 'Information',
        no_access_message: 'Vous n\'avez pas accès à cet espace, veuillez contacter votre formateur.trice pour en savoir plus.',
        ok: 'Ok !',
      },
      en: {
        launch_my_community: 'Launch my community',
        community_home: 'Community home',
        create_space: 'Create a space',
        create_collection: 'Create a collection',
        collections_description: 'Collections are used to group spaces in the menu',
        community_settings: 'Community settings',
        this_space_is_hidden: 'This space is hidden',
        this_space_is_draft: 'This space is a draft',
        no_access_to_space: 'You don\'t have access to this space',
        create_space_in_collection: 'Create a space in the collection',
        edit_collection: 'Edit collection',
        delete_collection: 'Delete collection',
        information: 'Information',
        no_access_message: 'You don\'t have access to this space, please contact your trainer for more information.',
        ok: 'Ok!',
      },
    },
  },
};
</script>

<template>
  <div class="csnicomp">
    <ul class="csnicomp_list">
      <router-link
        v-if="isStoreAdmin"
        class="csnicomp_item"
        :to="{name: 'community_onboarding'}"
        exact-active-class="is-active"
        tag="li"
      >
        <a class="csnicomp_link" @click="onMenuItemClick">
          <b-icon pack="far" icon="question-circle" />
          {{ $t('launch_my_community') }}
        </a>
      </router-link>
      <router-link
        class="csnicomp_item"
        :to="{name: 'community'}"
        exact-active-class="is-active"
        tag="li"
      >
        <a class="csnicomp_link" @click="onMenuItemClick">
          <b-icon icon="users" />
          {{ $t('community_home') }}
        </a>
        <b-dropdown v-if="isStoreAdmin" class="csnicomp_more">
          <template #trigger>
            <b-button
              type="is-text"
              class="h-auto py-0 has-text-black is-shadowless"
              icon-left="ellipsis-h"
            />
          </template>
          <b-dropdown-item @click="showGroupForm()">
            <b-icon class="is-size-9" size="is-small" icon="hashtag" />
            {{ $t('create_space') }}
          </b-dropdown-item>
          <b-dropdown-item @click="showCollectionForm()">
            <b-icon icon="folder" />
            {{ $t('create_collection') }}
            <b-tooltip class="ml-5" multilined>
              <template #content>
                {{ $t('collections_description') }}
              </template>
              <b-icon icon="info-circle" />
            </b-tooltip>
          </b-dropdown-item>
          <b-dropdown-item @click="showCommunityForm()">
            <b-icon icon="sliders-h" />
            {{ $t('community_settings') }}
          </b-dropdown-item>
        </b-dropdown>
      </router-link>
    </ul>
    <Draggable
      class="csnicomp_list"
      tag="ul"
      :value="standaloneGroups"
      :disabled="isTouch || !canManageCommunityEntity"
      @input="reorderGroups"
    >
      <router-link
        v-for="group in standaloneGroups"
        :key="group.uuid"
        :to="{name: 'community_group', params: {uuid: group.uuid}}"
        custom
        v-slot="{ href, isActive, navigate }"
      >
        <li class="csnicomp_item" :class="{'is-active': isActive}">
          <a
            class="csnicomp_link"
            :href="href"
            @click="onNavigate($event, group, navigate)"
          >
            <b-icon class="is-size-9" size="is-small" icon="hashtag" />
            <b-tooltip
              v-if="group.state === 'PRIVATE' && canManageCommunityEntity"
              position="is-right"
            >
              <template #content>
                {{ $t('this_space_is_hidden') }}
              </template>
              <b-icon icon="user-lock" />
            </b-tooltip>
            <b-tooltip
              v-if="group.state === 'DRAFT'"
              position="is-right"
            >
              <template #content>
                {{ $t('this_space_is_draft') }}
              </template>
              <b-icon
                class="is-size-9"
                type="is-warning"
                size="is-small"
                icon="circle"
              />
            </b-tooltip>
            <b-tooltip
              v-if="!group.is_accessible"
              position="is-right"
            >
              <template #content>
                {{ $t('no_access_to_space') }}
              </template>
              <b-icon icon="lock" />
            </b-tooltip>
            {{ group.name }}
          </a>
        </li>
      </router-link>
    </Draggable>
    <Draggable
      :value="collections"
      :disabled="isTouch || !canManageCommunityEntity"
      @input="reorderCollections"
    >
      <RenderlessToggle
        v-for="collection in collectionsWithGroups"
        :key="collection.uuid"
        open
      >
        <template #default="{ isOpen, toggle }">
          <div class="csnicomp_group">
            <p class="csnicomp_title">
              <a href="#" @click.prevent="toggle">
                <b-icon :icon="isOpen ? 'chevron-down' : 'chevron-right'" />
              </a>
              <router-link
                class="
                  csnicomp_link has-text-weight-bold has-text-clipped
                "
                :to="{
                  name: 'community_collection',
                  params: {uuid: collection.uuid}
                }"
                custom
                v-slot="{ href, navigate }"
              >
                <a
                  :href="href"
                  :title="`${collection.name} (${collection.groups.length})`"
                  @click="onCollectionNavigate($event, navigate)"
                >
                  {{ collection.name }} ({{ collection.groups.length }})
                </a>
              </router-link>
              <b-dropdown v-if="isStoreAdmin" class="csnicomp_more">
                <template #trigger>
                  <b-button
                    type="is-text"
                    class="h-auto py-0 has-text-black is-shadowless"
                    icon-left="ellipsis-h"
                  />
                </template>
                <b-dropdown-item @click="showGroupForm({ collection })">
                  <b-icon class="is-size-9" size="is-small" icon="hashtag" />
                  {{ $t('create_space_in_collection') }}
                </b-dropdown-item>
                <b-dropdown-item @click="showCollectionForm({ collection })">
                  <b-icon icon="edit" />
                  {{ $t('edit_collection') }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="canManageCommunityEntity"
                  class="has-text-danger"
                  @click="deleteCollectionDialog({ vm: _self, collection })"
                >
                  <b-icon icon="trash" />
                  {{ $t('delete_collection') }}
                </b-dropdown-item>
              </b-dropdown>
            </p>
            <Draggable
              v-show="isOpen"
              class="csnicomp_list"
              tag="ul"
              :value="collection.groups"
              :disabled="isTouch || !canManageCommunityEntity"
              @input="reorderGroups"
            >
              <router-link
                v-for="group in collection.groups"
                :key="group.uuid"
                :to="{name: 'community_group', params: {uuid: group.uuid}}"
                custom
                v-slot="{ href, isActive, navigate }"
              >
                <li class="csnicomp_item" :class="{'is-active': isActive}">
                  <a
                    class="csnicomp_link"
                    :href="href"
                    @click="onNavigate($event, group, navigate)"
                  >
                    <b-icon class="is-size-9" size="is-small" icon="hashtag" />
                    <b-tooltip
                      v-if="group.state === 'PRIVATE' && canManageCommunityEntity"
                      position="is-right"
                    >
                      <template #content>
                        {{ $t('this_space_is_hidden') }}
                      </template>
                      <b-icon icon="user-lock" />
                    </b-tooltip>
                    <b-tooltip
                      v-if="group.state === 'DRAFT'"
                      position="is-right"
                    >
                      <template #content>
                        {{ $t('this_space_is_draft') }}
                      </template>
                      <b-icon
                        class="is-size-9"
                        type="is-warning"
                        size="is-small"
                        icon="circle"
                      />
                    </b-tooltip>
                    <b-tooltip
                      v-if="!group.is_accessible"
                      position="is-right"
                    >
                      <template #content>
                        {{ $t('no_access_to_space') }}
                      </template>
                      <b-icon icon="lock" />
                    </b-tooltip>
                    {{ group.name }}
                  </a>
                </li>
              </router-link>
            </Draggable>
          </div>
        </template>
      </RenderlessToggle>
    </Draggable>
  </div>
</template>

<style lang="scss" scoped>
  .csnicomp {
    a {
      color: inherit;
    }

    &_title,
    &_item {
      display: flex;
      align-items: center;
    }

    &_title {
      padding: pxToRem(8px) pxToRem(8px) pxToRem(8px) 0;
    }

    &_item {
      margin-bottom: pxToRem(4px);
      border-radius: $radius;

      &:hover,
      &.is-active {
        color: var(--btn-text-color, $theme_color_black);
        background: $theme_color_grey_light;
        background: var(--btn-color, $theme_color_grey_light);
      }
    }

    &_link {
      display: block;
      width: 100%;
      padding: pxToRem(8px);
    }
  }
</style>
