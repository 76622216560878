<template functional>
  <RenderlessToggle #default="{ isOpen, toggle }" :open="false">
    <div>
      <p class="mb-3">
        <b-button
          type="is-primary"
          icon-left="info"
          @click="toggle"
          :aria-label="parent.$t('video_tips_toggle.button_aria_label')"
        >
          {{ parent.$t('video_tips_toggle.button_text') }}
        </b-button>
      </p>
      <div v-show="isOpen" class="box is-primary content">
        <p v-html="parent.$t('video_tips_toggle.recommendation')"></p>
        <p>{{ parent.$t('video_tips_toggle.free_offer') }}</p>
        <p>{{ parent.$t('video_tips_toggle.sharing_convenience') }}</p>
        <p>
          {{ parent.$t('video_tips_toggle.tutorial_intro') }}
          <a
            :href="parent.$t('video_tips_toggle.tutorial_link')"
            target="_blank"
            rel="noreferrer noopener"
          >{{ parent.$t('video_tips_toggle.tutorial_text') }}</a>
        </p>
        <p v-html="parent.$t('video_tips_toggle.teachizy_hosting')"></p>
      </div>
    </div>
  </RenderlessToggle>
</template>

<script>
export default {
  name: 'VideoTipsToggle',
};
</script>
