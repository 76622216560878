<script>
import { sameAs } from 'vuelidate/lib/validators';
import BasePasswordInput from '@shared/components/BasePasswordInput.vue';
import APICustomer from '@school/services/API/Customer';

export default {
  components: { BasePasswordInput },
  data() {
    return {
      isLoading: false,
      customer: {
        old_password: '',
        new_password: '',
        confirm_new_password: '',
      },
    };
  },
  validations: {
    customer: {
      confirm_new_password: {
        sameAs: sameAs('new_password'),
      },
    },
  },
  methods: {
    handle() {
      const customer = { ...this.customer };

      this.isLoading = true;
      APICustomer.updatePassword(customer)
        .then(() => {
          this.$showMessage.success();
          Object.assign(this.customer, this.$options.data.bind(this)().customer);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  i18n: {
    messages: {
      en: {
        old_password: 'Old password',
        new_password: 'New password',
        confirm_new_password: 'Confirm your new password',
        password_placeholder: 'Minimum 8 characters',
        update_button: 'Update',
        success_message: 'Password updated successfully',
      },
      fr: {
        old_password: 'Ancien mot de passe',
        new_password: 'Nouveau mot de passe',
        confirm_new_password: 'Confirmez votre nouveau mot de passe',
        password_placeholder: '8 caractères minimum',
        update_button: 'Mettre à jour',
        success_message: 'Mot de passe mis à jour avec succès',
      },
    },
  },
};
</script>

<template>
  <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
    <div class="columns is-multiline">
      <div class="column is-12">
        <b-field :label="$t('old_password')">
          <b-input
            v-model="customer.old_password"
            type="password"
            required
            password-reveal
          />
        </b-field>
      </div>
      <div class="column is-12">
        <b-field :label="$t('new_password')">
          <BasePasswordInput
            v-model="customer.new_password"
          />
        </b-field>
      </div>
      <div class="column is-12">
        <b-field
          :label="$t('confirm_new_password')"
          v-bind="$getErrorProps($v.customer.confirm_new_password, ['sameAs'])"
        >
          <b-input
            v-model="customer.confirm_new_password"
            type="password"
            minlength="8"
            maxlength="32"
            required
            :placeholder="$t('password_placeholder')"
            password-reveal
            :use-html5-validation="false"
          />
        </b-field>
      </div>
    </div>

    <b-field class="has-text-right">
      <b-button type="is-primary is-custom" native-type="submit" :loading="isLoading">
        {{ $t('update_button') }}
      </b-button>
    </b-field>
  </form>
</template>
