<script>
import ContentBlockMixin from '@shared/mixins/ContentBlock';
import ContentBlockEmbedoView from '@shared/components/ContentBlocks/Views/Embedo.vue';

export default {
  mixins: [ContentBlockMixin],
  components: { ContentBlockEmbedoView },
  static: {
    data: { url: '', caption: '' },
  },
  data: () => ({ isForm: true }),
  computed: {
    type() {
      return this.contentBlock.type.toUpperCase();
    },
    linkLabel() {
      return this.$t('content_block_embedo.link_label', { type: this.type });
    },
  },
  i18n: {
    messages: {
      en: {
        content_block_embedo: {
          link_label: '{type} Link *',
          caption: 'Caption',
        },
      },
      fr: {
        content_block_embedo: {
          link_label: 'Lien {type} *',
          caption: 'Légende',
        },
      },
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <b-field :label="linkLabel">
        <b-input v-model="newData.url" required />
      </b-field>
      <b-field :label="$t('content_block_embedo.caption')">
        <b-input v-model="newData.caption" />
      </b-field>
    </template>
    <template #view>
      <ContentBlockEmbedoView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
