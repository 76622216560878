<script>
import { mapState, mapMutations } from 'vuex';
import utilsMixin from '@shared/mixins/utils';
import communityMixin from '@shared/mixins/community';
import CommunityCollectionForm from '@shared/components/CommunityCollectionForm.vue';
import CommunityGroupForm from '@shared/components/CommunityGroupForm.vue';
import CommunitySideNav from '@shared/components/CommunitySideNav.vue';
import CommunitySideNavItems from '@shared/components/CommunitySideNavItems.vue';

export default {
  mixins: [utilsMixin, communityMixin()],
  components: {
    CommunityCollectionForm,
    CommunityGroupForm,
    CommunitySideNav,
    CommunitySideNavItems,
    StoreCommunityForm: () => import('@app/components/StoreCommunityForm.vue'),
  },
  computed: {
    ...mapState('community', [
      'isBooting',
      'communityFormIsOpen',
      'collections',
      'collectionFormIsOpen',
      'collectionFormEditedData',
      'groupFormIsOpen',
      'groupFormEditedData',
      'groupFormCollection',
    ]),
  },
  methods: {
    ...mapMutations('community', [
      'closeCommunityForm',
      'closeCollectionForm',
      'closeGroupForm',
    ]),
    showCommunityForm() {
      this.$refs.sidenav.onMenuItemClick();
      this.$store.commit('community/showCommunityForm');
    },
    showCollectionForm() {
      this.$refs.sidenav.onMenuItemClick();
      this.$store.commit('community/showCollectionForm');
    },
    showGroupForm() {
      this.$refs.sidenav.onMenuItemClick();
      this.$store.commit('community/showGroupForm');
    },
    onCommunityFormDone() {
      if (this.$env.contextRoot === '@app') {
        if (
          this.$store.getters['auth/store']
            .community_options
            .enabled
        ) {
          this.$router.push({ name: 'community' });
        } else {
          this.$router.push({ name: 'home' });
        }

        this.closeCommunityForm();
      }
    },
  },
  created() {
    this.$store.dispatch('community/init');
  },
  i18n: {
    messages: {
      en: {
        create_space: 'Create a space',
        create_collection: 'Create a collection',
        collections_description: 'Collections are used to group spaces in the menu',
        settings: 'Settings',
      },
      fr: {
        create_space: 'Créer un espace',
        create_collection: 'Créer une collection',
        collections_description: 'Les collections servent à regrouper des espaces dans le menu',
        settings: 'Paramètres',
      },
    },
  },
};
</script>

<template>
  <div class="cwcomp container">
    <div class="columns is-desktop mx-0-mobile">
      <div class="column is-narrow w-320">
        <b-skeleton v-if="isBooting" height="400" />
        <CommunitySideNav ref="sidenav" v-else>
          <CommunitySideNavItems />

          <div v-if="isStoreAdmin" class="content mt-5">
            <p>
              <b-button
                type="is-primary is-custom"
                expanded
                @click="showGroupForm()"
              >
                {{ $t('create_space') }}
              </b-button>
            </p>
            <p>
              <b-button
                class="has-background-none"
                expanded
                @click="showCollectionForm()"
              >
                {{ $t('create_collection') }}
                <b-tooltip class="ml-5" multilined>
                  <template #content>
                    {{ $t('collections_description') }}
                  </template>
                  <b-icon icon="info-circle" />
                </b-tooltip>
              </b-button>
            </p>
            <p>
              <b-button
                class="has-background-none"
                expanded
                icon-left="sliders-h"
                @click="showCommunityForm()"
              >
                {{ $t('settings') }}
              </b-button>
            </p>
          </div>
        </CommunitySideNav>
      </div>
      <div class="column p-0-mobile">
        <b-skeleton v-if="isBooting" height="400" />
        <slot v-else />
      </div>
    </div>

    <b-modal
      class="is-sidescreen"
      :active="collectionFormIsOpen"
      :can-cancel="false"
      full-screen
      has-modal-card
      @close="closeCollectionForm()"
    >
      <CommunityCollectionForm
        v-if="collectionFormIsOpen"
        class="mw-640"
        :collection="collectionFormEditedData"
        @cancel="closeCollectionForm()"
        @done="closeCollectionForm()"
      />
    </b-modal>

    <b-modal
      class="is-sidescreen"
      :active="groupFormIsOpen"
      :can-cancel="false"
      full-screen
      has-modal-card
      @close="closeGroupForm()"
    >
      <CommunityGroupForm
        class="mw-640"
        :collections="collections"
        :collection="groupFormCollection"
        :group="groupFormEditedData"
        @done="closeGroupForm()"
        @cancel="closeGroupForm()"
      />
    </b-modal>

    <b-modal
      v-if="communityFormIsOpen"
      :active="communityFormIsOpen"
      :can-cancel="false"
      has-modal-card
      @close="closeCommunityForm()"
    >
      <StoreCommunityForm
        @done="onCommunityFormDone"
        @cancel="closeCommunityForm()"
      />
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
</style>
