<script>
import { mapGetters, mapState } from 'vuex';

export default {
  head() {
    return {
      title: this.$t('pages.information.title'),
    };
  },
  computed: {
    ...mapState('community', [
      'currentGroup',
    ]),
    ...mapGetters('community', [
      'currentGroupDescription',
    ]),
  },
};
</script>

<template>
  <div class="px-3-mobile">
    <h2 class="title is-5">
      {{ $t('pages.information.description_title') }}
    </h2>
    <div
      v-if="currentGroupDescription"
      class="box content mt-5 break-words"
      v-html="currentGroupDescription"
    />
    <p v-else class="box has-text-centered">
      {{ $t('pages.information.no_description') }}
    </p>
  </div>
</template>
