<script>
import countries from '@shared/assets/data/countries.json';
import countries_by_code from '@shared/assets/data/countries_by_code.json';
import utilsMixin from '@shared/mixins/utils';
import StripeElementsSplitted from '@shared/components/StripeElementsSplitted.vue';
import BasePasswordInput from '@shared/components/BasePasswordInput.vue';
import APIStore from '@school/services/API/Store';
import APICustomer from '@school/services/API/Customer';

export default {
  mixins: [utilsMixin],
  inject: ['buyable', 'store', 'price'],
  components: { StripeElementsSplitted, BasePasswordInput },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    tempCustomer: {
      type: Object,
      default: () => (false),
    },
    paymentPlan: {
      type: Object,
      default: null,
    },
  },
  static: {
    countries,
  },
  data() {
    const price = this.paymentPlan ? this.paymentPlan.amount : this.price;

    return {
      stripeError: null,
      stripeIsCompleted: false,
      discountIsLoading: false,
      discountIsApplied: false,
      hasChosenPaypal: false,
      hasAddressOpen: false,
      isFree: price <= 0,
      customer: {
        discount_code: '',
        firstname: '',
        lastname: '',
        password: '',
        name: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        postal_code: '',
        state: '',
        country_code: 'FR',
      },
    };
  },
  computed: {
    submitIsDisabled() {
      return (
        (!this.isFree && !this.stripeIsCompleted)
        || (this.customer.discount_code !== '' && !this.discountIsApplied)
      );
    },
    canUseCoupon() {
      return !this.isFree && (
        this.buyable.discount_price == null
        || this.paymentPlan
      );
    },
    canUsePayPal() {
      return (
        !this.isFree
        && !this.paymentPlan
        && this.store.can_accept_paypal
        && !this.store.feature_options.paypal.disabled
      );
    },
    countryList() {
      return this.store.feature_options.language.language_option !== 'fr'
        ? Object.entries(countries_by_code)
          .sort(([, value1], [, value2]) => value1.name.localeCompare(value2.name))
          .reduce((acc, [key, value]) => {
            acc[key] = value.name;
            return acc;
          }, {})
        : countries;
    },
  },
  watch: {
    customer: {
      deep: true,
      handler() {
        this.$emit('update', this.customer);
      },
    },
    paymentPlan(value) {
      if (value) {
        this.$nextTick(() => (this.hasChosenPaypal = false));
      }
    },
  },
  methods: {
    applyCoupon() {
      const payment_schedule_id = this.paymentPlan ? this.paymentPlan.id : undefined;
      const price = this.paymentPlan ? this.paymentPlan.amount : this.price;

      this.discountIsLoading = true;
      this.discountIsApplied = false;
      this.isFree = price < 0;
      this.$emit('new-price');

      APIStore.getPricingData({
        payment_schedule_id,
        ...(this.buyable.buyable_type === 'TRAINING'
          ? { training_uuid: this.buyable.uuid }
          : {}),
        ...(this.buyable.buyable_type === 'BUNDLE'
          ? { bundle_uuid: this.buyable.uuid }
          : {}),
        discount_code: this.customer.discount_code,
      })
        .then((data) => {
          data = data.data;
          this.discountIsApplied = !!data.additional_data.applied_discount_code;

          if (this.discountIsApplied) {
            this.$emit('new-price', data.applicable_price);
            this.isFree = data.applicable_price <= 0;
          } else {
            this.customer.discount_code = '';
            this.$buefy.toast.open({
              type: 'is-danger',
              message: this.$t('promo_code_not_usable'),
            });
          }
        })
        .finally(() => (this.discountIsLoading = false));
    },
    payWithPayPal() {
      if (!this.theForm?.checkValidity() || !this.tempCustomer?.uuid) {
        this.$buefy.dialog.alert(this.$t('missing_information_for_payment'));
        return;
      }

      if (this.customer.discount_code !== '' && !this.discountIsApplied) {
        this.$buefy.dialog.alert({
          message: this.$t('promo_code_not_applied'),
          hasIcon: true,
          icon: 'exclamation-triangle',
          iconPack: 'fas',
        });
        return;
      }

      const w = 520;
      const h = window.innerHeight * 0.7;
      const x = (window.screen.width - w) / 2;
      const y = 100;
      const loader = this.$buefy.loading.open();
      let orderId;

      if (this.popup) {
        this.popup.close();
        this.popup = null;
      }

      this.popup = window.open(
        `${window.location.protocol}//${window.location.hostname}/paypal_init.html`,
        '_blank',
        `popup=1,toolbar=no,menubar=no,width=${w},height=${h},left=${x},top=${y}`,
      );

      if (this.popup == null) {
        this.showPopupBlockedDialog(this.$t('paypal'), this.$t('use_credit_card_if_not_working'));
        loader.close();
        return;
      }

      APICustomer.getPayPalOrder({
        customer_uuid: this.tempCustomer.uuid,
        ...(this.buyable.buyable_type === 'TRAINING'
          ? { training_uuid: this.buyable.uuid }
          : {}),
        ...(this.buyable.buyable_type === 'BUNDLE'
          ? { bundle_uuid: this.buyable.uuid }
          : {}),
        discount_code: this.customer.discount_code || undefined,
      })
        .then(({ data: { id, links = [] } = {} }) => {
          orderId = id;
          const url = links.find((v) => v.rel === 'approve')?.href;
          const { popup } = this;

          if (!popup || popup.closed) {
            return;
          }

          popup.location.href = url;
          popup.focus();

          const onPayPalMessage = (event) => {
            if (
              (
                event.origin !== (window.origin || window.location.origin)
                && event.source !== window
              ) || event.data?.name !== 'teachizy-paypal-popup'
            ) {
              return;
            }

            if (!popup.closed) {
              this.$emit('paypal-order', orderId);
              popup.close();
            }

            window.removeEventListener('message', onPayPalMessage);
          };

          window.addEventListener('message', onPayPalMessage);
        })
        .finally(() => loader.close());
    },
  },
  mounted() {
    console.log(this.$el);
    this.theForm = this.$el.closest('form');
  },
  i18n: {
    messages: {
      en: {
        firstname: 'First name',
        lastname: 'Last name',
        indicate_billing_address: 'Enter your billing address',
        company_name_optional: 'Company / Name (optional)',
        address_line_1: 'Address (number + street)',
        address_line_2: 'Additional address information',
        city: 'City',
        postal_code: 'Postal code',
        state_optional: 'State/Region (optional)',
        set_your_password: 'Set your password',
        promo_code: 'Promo code',
        apply: 'Apply',
        promo_code_applied: 'The promo code has been successfully applied!',
        credit_card: 'Credit card',
        paypal: 'Paypal',
        enter_credit_card: 'Enter your credit card details',
        access_with_paypal: 'Access with <strong>PayPal</strong>',
        access_training: 'Access the training',
        access_pack: 'Access the bundle',
        secure_registration: 'SSL secure registration',
        promo_code_not_usable: 'This promo code cannot be used.',
        missing_information_for_payment: 'There is missing information in the form to proceed with payment.',
        promo_code_not_applied: 'You have entered a promo code, but you haven\'t applied it. Please click on "apply".',
        use_credit_card_if_not_working: 'You can use credit card payment if this doesn\'t work.',
      },
      fr: {
        firstname: 'Prénom',
        lastname: 'Nom',
        indicate_billing_address: 'Entrez votre adresse de facturation',
        company_name_optional: 'Société / Nom (facultatif)',
        address_line_1: 'Adresse (numéro + rue)',
        address_line_2: 'Complément d\'adresse',
        city: 'Ville',
        postal_code: 'Code postal',
        state_optional: 'État/Région (facultatif)',
        set_your_password: 'Définissez votre mot de passe',
        promo_code: 'Code promo',
        apply: 'Appliquer',
        promo_code_applied: 'Le code promo a été appliqué !',
        credit_card: 'Carte de crédit',
        paypal: 'Paypal',
        enter_credit_card: 'Entrez les détails de votre carte de crédit',
        access_with_paypal: 'Accéder avec <strong>PayPal</strong>',
        access_training: 'Accéder à la formation',
        access_pack: 'Accéder au pack',
        secure_registration: 'Inscription sécurisée avec SSL',
        promo_code_not_usable: 'Ce code promo n\'est pas utilisable',
        missing_information_for_payment: 'Il manque des informations dans le formulaire pour procéder au paiement.',
        promo_code_not_applied: 'Vous avez saisi un code promo, mais vous ne l\'avez pas appliqué. Veuillez cliquer sur "appliquer".',
        use_credit_card_if_not_working: 'Vous pouvez utiliser le paiement par carte de crédit si cela ne fonctionne pas.',
      },
    },
  },
};
</script>

<template>
  <div class="columns is-multiline">
    <div class="column is-12 pb-0">
      <b-field grouped>
        <b-field v-if="!tempCustomer || !tempCustomer.firstname" class="w-full is-flex-shrink-1">
          <b-input v-model="customer.firstname" :placeholder="$t('firstname')" expanded required />
        </b-field>
        <b-field v-if="!tempCustomer || !tempCustomer.firstname" class="w-full is-flex-shrink-1">
          <b-input v-model="customer.lastname" :placeholder="$t('lastname')" expanded />
        </b-field>
      </b-field>
    </div>
    <div v-if="!isFree && buyable.feature_options.billing_address.enabled" class="column is-12 pb-0">
      <h3 class="mb-2">
        {{ $t('indicate_billing_address') }}
      </h3>
      <b-field>
        <b-input v-model.trim="customer.name" :placeholder="$t('company_name_optional')" />
      </b-field>
      <b-field>
        <b-input v-model.trim="customer.address_line_1" :placeholder="$t('address_line_1')" required />
      </b-field>
      <b-field>
        <b-input v-model.trim="customer.address_line_2" :placeholder="$t('address_line_2')" />
      </b-field>
      <b-field grouped>
        <b-field class="w-full is-flex-shrink-1">
          <b-input v-model.trim="customer.city" :placeholder="$t('city')" required />
        </b-field>
        <b-field class="w-full is-flex-shrink-1">
          <b-input v-model.trim="customer.postal_code" :placeholder="$t('postal_code')" required />
        </b-field>
      </b-field>
      <b-field>
        <b-select v-model="customer.country_code" expanded required>
          <option v-for="(country, code) in countryList" :key="code" :value="code">
            {{ country }}
          </option>
        </b-select>
      </b-field>
      <b-field v-show="customer.country_code != 'FR'">
        <b-input v-model.trim="customer.state" :placeholder="$t('state_optional')" />
      </b-field>
    </div>
    <div v-if="!tempCustomer || !tempCustomer.firstname" class="column is-12 pb-0">
      <b-field :label="$t('set_your_password')">
        <BasePasswordInput v-model="customer.password" />
      </b-field>
    </div>
    <div v-if="canUseCoupon" class="column is-12 pb-0">
      <b-field>
        <b-input v-model.trim="customer.discount_code" :placeholder="$t('promo_code')" expanded
          @keyup.native.enter.prevent="customer.discount_code != '' && applyCoupon()" />
        <p class="control">
          <b-button type="is-primary is-custom" :loading="discountIsLoading" @click="applyCoupon">
            {{ $t('apply') }}
          </b-button>
        </p>
      </b-field>
      <p v-if="discountIsApplied" class="help is-primary is-custom">
        {{ $t('promo_code_applied') }}
      </p>
    </div>
    <div v-if="canUsePayPal" class="column is-12 pb-0">
      <b-field>
        <b-radio-button class="w-50p" type="is-primary is-custom" v-model="hasChosenPaypal" :native-value="false">
          <b-icon class="mr-3" icon="credit-card" />
          {{ $t('credit_card') }}
        </b-radio-button>
        <b-radio-button class="w-50p" type="is-primary is-custom" v-model="hasChosenPaypal" :native-value="true">
          <b-icon class="mr-3" pack="fab" icon="paypal" />
          {{ $t('paypal') }}
        </b-radio-button>
      </b-field>
    </div>
    <div v-if="!isFree && !hasChosenPaypal" class="column is-12 pb-0">
      <b-field :label="$t('enter_credit_card')" :type="{ 'is-danger': stripeError && stripeError.message }"
        :message="stripeError && stripeError.message">
        <StripeElementsSplitted ref="card" :stripe-account="store.stripe_token" @error="stripeError = $event"
          @complete="stripeIsCompleted = $event" />
      </b-field>
    </div>
    <div class="column is-12">
      <b-button v-if="!isFree && hasChosenPaypal" ref="paypal" type="is-black" class="mt-3 py-6"
        :style="{ backgroundColor: '#0070ba' }" icon-left="paypal" icon-pack="fab" :loading="isLoading" expanded
        @click="payWithPayPal">
        <span v-html="$t('access_with_paypal')"></span>
      </b-button>
      <b-button v-else rel="general" native-type="submit" type="is-primary is-custom" class="py-6"
        :disabled="submitIsDisabled" :loading="isLoading" expanded>
        {{ $t(buyable.buyable_type === 'TRAINING' ? 'access_training' : 'access_pack') }}
      </b-button>

      <p class="mt-5 is-size-8 has-text-centered has-text-primary">
        <b-icon icon="shield-alt" />
        {{ $t('secure_registration') }}
      </p>
    </div>
  </div>
</template>
