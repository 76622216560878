<script>
import { postSingle } from '@shared/mixins/community';
import ListPostDetailedItem from '@shared/components/Community/ListPostDetailedItem.vue';
import CardCommentItem from '@shared/components/Community/CardCommentItem.vue';

export default {
  mixins: [postSingle],
  components: {
    ListPostDetailedItem,
    CardCommentItem,
  },
  computed: {
    responseTitle() {
      return this.$t('response_title');
    },
    associatedPostTitle() {
      return this.$t('associated_post_title');
    },
  },
  i18n: {
    messages: {
      en: {
        response_title: 'The response:',
        associated_post_title: 'The associated post:',
      },
      fr: {
        response_title: 'La réponse :',
        associated_post_title: 'La publication associée :',
      },
    },
  },
};
</script>

<template>
  <section>
    <template v-if="comment">
      <h3 class="title is-6">
        {{ responseTitle }}
      </h3>
      <CardCommentItem
        class="box"
        :post="post"
        :comment="comment"
        :parent="comment.parent"
        isStandAlone
      />

      <h3 class="title is-6">
        {{ associatedPostTitle }}
      </h3>
    </template>
    <ListPostDetailedItem :post="post" />
  </section>
</template>
