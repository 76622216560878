<script>
import utilsMixin from '@shared/mixins/utils';
import APICustomer from '@school/services/API/Customer';

export default {
  mixins: [utilsMixin],
  data: () => ({
    isLoading: false,
    isFetching: false,
    sales: {
      data: [],
    },
  }),
  methods: {
    getSales(page = 1, firstFetch = false) {
      if (firstFetch) {
        this.isFetching = true;
      } else {
        this.isLoading = true;
      }

      APICustomer.getAffiliateSales({ params: { page } })
        .then((data) => (this.sales = data))
        .finally(() => {
          if (firstFetch) {
            this.isFetching = false;
          } else {
            this.isLoading = false;
          }
        });
    },
  },
  created() {
    this.getSales(1, true);
  },
  i18n: {
    messages: {
      fr: {
        affiliate_product: 'Produit',
        affiliate_purchased: 'Acheté',
        affiliate_paid_multiple_times: 'Payé en plusieurs fois',
        affiliate_price: 'Prix',
        affiliate_commission: 'Commission',
        affiliate_no_sales: "Vous n'avez pas encore généré de ventes avec vos liens.",
      },
      en: {
        affiliate_product: 'Product',
        affiliate_purchased: 'Purchased',
        affiliate_paid_multiple_times: 'Paid in multiple installments',
        affiliate_price: 'Price',
        affiliate_commission: 'Commission',
        affiliate_no_sales: "You haven't generated any sales with your links yet.",
      },
    },
  },
};
</script>

<template>
  <section>
    <b-skeleton v-if="isFetching" height="400" />
    <b-table
      v-else
      class="box"
      :loading="isLoading"
      :data="sales.data"
      :per-page="sales.meta.per_page"
      :total="sales.meta.total"
      backend-pagination
      paginated
      @page-change="getSales($event)"
    >
      <b-table-column
        field="training.name"
        :label="$t('affiliate_product')"
        v-slot="{ row }"
      >
        {{ row.training.name }}
      </b-table-column>
      <b-table-column
        field="created_at"
        :label="$t('affiliate_purchased')"
        width="200"
        v-slot="{ row }"
      >
        <div>
          <p>
            <b-tooltip>
              <template #content>
                {{ row.created_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
              </template>
              {{ row.created_at | momentFromUTC | moment('from') }}
            </b-tooltip>
          </p>
          <p
            v-if="row.transaction_type === 'MULTIPLE'"
            class="mt-2 has-text-weight-bold"
          >
            {{ $t('affiliate_paid_multiple_times') }}
          </p>
        </div>
      </b-table-column>
      <b-table-column
        field="transaction_amount"
        :label="$t('affiliate_price')"
        numeric
        v-slot="{ row }"
      >
        {{ row.transaction_amount | formatPrice }}
      </b-table-column>
      <b-table-column
        field="affiliation_amount"
        :label="$t('affiliate_commission')"
        numeric
        v-slot="{ row }"
      >
        <strong class="has-text-success">
          {{ row.affiliation_amount | formatPrice }}
        </strong>
      </b-table-column>

      <template #empty>
        {{ $t('affiliate_no_sales') }}
      </template>
    </b-table>
  </section>
</template>
