import { render, staticRenderFns } from "./StoreHero.vue?vue&type=template&id=6685a4ba&scoped=true&"
import script from "./StoreHero.vue?vue&type=script&lang=js&"
export * from "./StoreHero.vue?vue&type=script&lang=js&"
import style0 from "./StoreHero.vue?vue&type=style&index=0&id=6685a4ba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6685a4ba",
  null
  
)

export default component.exports