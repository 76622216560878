<script>
import { commentsList } from '@shared/mixins/community';
import CardCommentItem from '@shared/components/Community/CardCommentItem.vue';
import CardCommentForm from '@shared/components/Community/CardCommentForm.vue';

export default {
  mixins: [commentsList],
  i18n: {
    messages: {
      en: {
        view_previous_comments: 'View previous comments',
        view_more_comments: 'View more comments',
      },
      fr: {
        view_previous_comments: 'Voir les commentaires précédents',
        view_more_comments: 'Voir plus de commentaires',
      },
    },
  },
  components: {
    CardCommentItem,
    CardCommentForm,
  },
  computed: {
    viewPreviousCommentsLabel() {
      return this.$t('view_previous_comments');
    },
    viewMoreCommentsLabel() {
      return this.$t('view_more_comments');
    },
  },
};
</script>

<template>
  <div class="btop-grey">
    <div v-if="comments.data.length > 0" class="px-3 py-4">
      <p v-if="comments.links && comments.links.next" class="mb-3">
        <b-button
          class="tdecoration-underline"
          type="is-text"
          size="is-small"
          @click="$emit('load-comments', {
            parent: null,
            url: comments.links.next,
            append: true
          })"
        >
          {{ viewPreviousCommentsLabel }}
        </b-button>
      </p>
      <CardCommentItem
        v-for="comment in comments.data"
        :key="comment.uuid"
        :post="post"
        :comment="comment"
      >
        <CardCommentForm
          v-if="
            !(comment.settings && comment.settings.readonly)
            && !postCommentsReadonly
          "
          :post="post"
          :parentUUID="comment.uuid"
        />

        <template #childrenPagination="{ links }">
          <p v-if="links && links.next">
            <b-button
              class="tdecoration-underline"
              type="is-text"
              size="is-small"
              @click="$emit('load-comments', {
                parent: comment,
                url: links.next,
                append: true
              })"
            >
              {{ viewMoreCommentsLabel }}
            </b-button>
          </p>
        </template>
      </CardCommentItem>
    </div>
    <div v-if="!postCommentsReadonly" class="media p-3">
      <div class="media-left">
        <figure class="image is-32x32">
          <img
            class="is-rounded w-full h-full ofit-cover"
            :src="$store.getters['community/userAvatar']"
            :alt="$store.getters['community/userDisplayName']"
          >
        </figure>
      </div>
      <div class="media-content">
        <CardCommentForm :post="post" />
      </div>
    </div>
  </div>
</template>
