<script>
import utilsMixin from '@shared/mixins/utils';
import communityMixin, { postItem } from '@shared/mixins/community';
import PostItemContextMenu from '@shared/components/Community/PostItemContextMenu.vue';

export default {
  mixins: [utilsMixin, communityMixin('post'), postItem],
  components: {
    PostItemContextMenu,
  },
  computed: {
    postLinkParams() {
      return {
        name: 'community_group_post',
        params: { postUUID: this.post.uuid },
      };
    },
  },
  i18n: {
    messages: {
      en: {
        list_post_item: {
          published_by: 'By <strong>{name}</strong>',
          published_at: 'Published on',
          scheduled: 'Scheduled',
          pinned: 'Pinned',
          comments: 'Comments',
          last_comment: 'Last reply',
          last_comment_tooltip: 'Last reply on',
          at: 'at',
        },
      },
      fr: {
        list_post_item: {
          published_by: 'Par <strong>{name}</strong>',
          published_at: 'Publiée le',
          scheduled: 'Programmée',
          pinned: 'Épinglée',
          comments: 'Commentaires',
          last_comment: 'Dernière réponse',
          last_comment_tooltip: 'Dernière réponse le',
          at: 'à',
        },
      },
    },
  },
};
</script>

<template>
  <article class="card bradius-0-mobile">
    <header class="card-content p-3 bbottom-grey">
      <div class="media">
        <div class="media-content">
          <h3 class="title is-5 is-size-6-mobile mb-2 has-over is-custom">
            <router-link
              class="has-text-dark"
              :to="postLinkParams"
            >
              {{ post.title }}
            </router-link>
          </h3>

          <p class="is-size-8 is-size-9-mobile">
            <router-link
              class="has-text-black70"
              :to="postLinkParams"
            >
              <b-tooltip position="is-top">
                <template #content>
                  {{ $t('list_post_item.published_at') }} <br>
                  {{ post.published_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
                </template>
                <span v-html="$t('list_post_item.published_by', { name: displayName })"></span>
                {{ post.published_at | momentFromUTC | moment('from') }}
              </b-tooltip>
            </router-link>
          </p>
        </div>
        <nav class="media-right">
          <PostItemContextMenu
            :can-manage-community-entity="canManageCommunityEntity"
            :post="post"
          />
        </nav>
      </div>
    </header>
    <div class="card-content p-3 bbottom-grey">
      <p class="subtitle is-7">
        {{ excerpt }}
      </p>
      <p v-if="isScheduled || post.pinned" class="tags mb-0">
        <span
          v-if="isScheduled"
          class="tag is-black is-size-9-mobile"
        >
          {{ $t('list_post_item.scheduled') }}
          <b-icon class="ml-2" icon="clock" />
        </span>
        <span
          v-if="post.pinned"
          class="tag is-black is-size-9-mobile"
        >
          {{ $t('list_post_item.pinned') }}
          <b-icon class="ml-2" icon="thumbtack" />
        </span>
      </p>
    </div>
    <footer class="card-content p-2 p-1-mobile is-size-9">
      <router-link
        class="has-text-dark"
        :to="postLinkParams"
      >
        <b-icon pack="far" icon="comment" /> ({{ post.comments_count }})
        {{ $t('list_post_item.comments') }}
      </router-link>
      <template v-if="lastCommentAt">
        <span class="px-2">
          •
        </span>
        <router-link
          :to="postLinkParams"
        >
          <b-tooltip position="is-top">
            <template #content>
              {{ $t('list_post_item.last_comment_tooltip') }} {{ lastCommentAt | momentFromUTC | moment('DD/MM/YY [' + $t('list_post_item.at') +  '] HH:mm') }}
            </template>
            {{ $t('list_post_item.last_comment') }}
            {{ lastCommentAt | momentFromUTC | moment('from') }}
          </b-tooltip>
        </router-link>
      </template>
    </footer>
  </article>
</template>

<style lang="scss" scoped>
.has-over {
  &:hover {
    text-decoration: underline;
  }
}
</style>
