<script>
export default {
  props: {
    type: {
      type: String,
      default: 'is-primary',
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    messageHTML: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: '',
    },
    showHideOption: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({ hideNextTime: false }),
  i18n: {
    messages: {
      en: {
        do_not_show_confirmation: 'Do not display this confirmation again',
        cancel: 'Cancel',
        confirm: 'Confirm',
      },
      fr: {
        do_not_show_confirmation: 'Ne plus afficher cette confirmation',
        cancel: 'Annuler',
        confirm: 'Confirmer',
      },
    },
  },
};
</script>

<template>
  <AppCardModal>
    <template v-if="title" #title>
      {{ title }}
    </template>
    <template #body>
      <template v-if="message">
        {{ message }}
      </template>
      <div v-if="messageHTML" v-html="messageHTML" />
      <div v-if="showHideOption" class="mt-5">
        <b-checkbox v-model="hideNextTime">
          {{ $t('do_not_show_confirmation') }}
        </b-checkbox>
      </div>
    </template>
    <template #foot>
      <b-button @click="$emit('cancel')">
        {{ cancelText || $t('cancel') }}
      </b-button>
      <b-button
        :type="type"
        @click="$emit('confirm', hideNextTime)"
      >
        {{ confirmText || $t('confirm') }}
      </b-button>
    </template>
  </AppCardModal>
</template>
