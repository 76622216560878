<script>
import { resolveUrl } from '@shared/services/IframeEmbedUtils';

export default {
  props: {
    parser: {
      type: String,
      default: null,
    },
    attrs: {
      type: [Object, String],
      default: null,
    },
    url: {
      type: String,
      required: true,
    },
    extraData: {
      type: [Object, Array],
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      iattrs: this.attrs,
      src: false,
      chatSrc: false,
    };
  },
  watch: {
    url: {
      immediate: true,
      handler: 'getSRC',
    },
  },
  computed: {
    host() {
      return window.location.hostname;
    },
    isVideoPlayer() {
      return ['vimeo', 'youtube', 'youtube_live', 'plyr']
        .indexOf(this.parser) > -1;
    },
    hasTimecode() {
      return (
        this.extraData
        && !this.extraData.nativePlayer
        && this.extraData.timecodes
        && this.extraData.timecodes.length
      );
    },
    vimeoPrivateHash() {
      const { src } = this;
      const hashMatch = src && src.match(/\?h=([A-z0-9]+)/);

      return hashMatch && hashMatch[1];
    },
    hasChatEnabled() {
      return !!(this.extraData && this.extraData.hasChatEnabled);
    },
  },
  methods: {
    getSRC(url) {
      this.isLoading = true;

      const promises = [
        resolveUrl(url, this.attrs, this.parser),
      ];

      if (this.hasChatEnabled) {
        promises.push(resolveUrl(url, {}, 'youtube_live_chat'));
      }

      Promise.all(promises)
        .then(([parserResult, chatResult]) => {
          if (parserResult) {
            this.iattrs = parserResult.iattrs;
            this.src = parserResult.src;

            if (chatResult) {
              this.chatSrc = chatResult.src;
            }
          } else {
            this.src = false;
            this.chatSrc = false;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    goToTimecode(timecode) {
      const { player } = this.$refs.player;
      const timeSplitted = timecode.time.split(':');
      const timeInSeconds = Math.min(
        player.duration - 1,
        parseInt(timeSplitted[0] * 60, 10) + parseInt(timeSplitted[1], 10),
      );

      if (!Number.isNaN(timeInSeconds)) {
        player.play();
        player.currentTime = timeInSeconds;
      }
    },
  },
  i18n: {
    messages: {
      en: {
        video_chapters: 'Video chapters',
        loading_error: 'There was a loading problem...',
      },
      fr: {
        video_chapters: 'Chapitrage de la vidéo',
        loading_error: 'Il y a eu un problème de chargement...',
      },
    },
  },
};
</script>

<template>
  <div>
    <b-skeleton v-if="isLoading" height="360" />
    <template v-else-if="src !== false">
      <div class="vsecomp_content">
        <div class="vsecomp_embed">
          <template v-if="!isVideoPlayer">
            <iframe
              class="has-background-black"
              :src="src"
              v-bind="iattrs"
            />
            <slot name="caption" />
          </template>
          <template v-else>
            <div class="vsecomp_embed_wrap" :class="{ hasChatEnabled }">
              <div class="vsecomp_embed_video">
                <vue-plyr
                  v-if="!extraData || !extraData.nativePlayer"
                  ref="player"
                  :options="{
                    vimeo: { h: vimeoPrivateHash },
                  }"
                >
                  <div class="plyr__video-embed">
                    <iframe :src="src" v-bind="iattrs" />
                  </div>
                </vue-plyr>
                <iframe
                  v-else
                  class="has-background-black"
                  :src="src"
                  v-bind="iattrs"
                />
              </div>
              <div v-if="hasChatEnabled" class="vsecomp_embed_sidebar bradius-4">
                <iframe class="w-full h-full" :src="chatSrc" />
              </div>
            </div>
            <slot name="caption" />
            <div v-if="hasTimecode" class="box content has-text-justified mt-3">
              <h3 class="is-size-5-touch">
                {{ $t('video_chapters') }}
              </h3>
              <ul>
                <li v-for="(timecode, k) in extraData.timecodes" :key="k">
                  {{ timecode.title }} :
                  <a href="#" @click.prevent="goToTimecode(timecode)">
                    {{ timecode.time }}
                  </a>
                </li>
              </ul>
            </div>
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <slot v-if="$slots.default" />
      <p v-else class="notification is-danger">
        {{ $t('loading_error') }}
      </p>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.vsecomp {
  &_embed {
    &_wrap {
      display: flex;

      &.hasChatEnabled {
        min-height: 496px;
      }
    }

    &_video {
      width: 100%;
    }

    &_sidebar {
      flex: none;
      width: 35%;
      min-width: 320px;
      margin-left: pxToRem(12);
      border: 2px solid $theme_color_black_10;
    }
  }

  @include mobile {
    &_embed {
      &_wrap {
        display: block;

        &.hasChatEnabled {
          min-height: auto;
        }
      }

      &_sidebar {
        width: 100%;
        height: 496px;
        margin-left: 0;
      }
    }
  }
}
</style>
