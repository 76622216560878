<template>
  <section class="section">
    <h1 class="title is-custom is-2 is-size-4-touch">
      {{ $t('account_settings') }}
    </h1>
    <div class="tabs has-scroll-shadow mb-10">
      <ul>
        <router-link tag="li" :to="{name: 'account'}" exact-active-class="is-active">
          <a>{{ $t('profile') }}</a>
        </router-link>
        <router-link tag="li" :to="{name: 'account_security'}" exact-active-class="is-active">
          <a>{{ $t('password') }}</a>
        </router-link>
        <router-link tag="li" :to="{name: 'account_notifications'}" exact-active-class="is-active">
          <a>{{ $t('notifications') }}</a>
        </router-link>
        <router-link tag="li" :to="{name: 'account_billing'}" exact-active-class="is-active">
          <a>{{ $t('my_purchases') }}</a>
        </router-link>
      </ul>
    </div>
    <div>
      <router-view />
    </div>
  </section>
</template>

<script>
export default {
  name: 'Account',
  i18n: {
    messages: {
      fr: {
        account_settings: 'Paramètres de votre compte',
        profile: 'Profil',
        password: 'Mot de passe',
        notifications: 'Notifications',
        my_purchases: 'Mes achats',
      },
      en: {
        account_settings: 'Your Account Settings',
        profile: 'Profile',
        password: 'Password',
        notifications: 'Notifications',
        my_purchases: 'My Purchases',
      },
    },
  },
};
</script>
