<script>
import { required } from 'vuelidate/lib/validators';
import ContentBlockWithUploadMixin from '@shared/mixins/ContentBlockWithUpload';
import ContentBlockPDFViewerView from '@shared/components/ContentBlocks/Views/PDFViewer.vue';

export default {
  mixins: [ContentBlockWithUploadMixin],
  components: { ContentBlockPDFViewerView },
  static: {
    data: { path: '', caption: '' },
  },
  data: () => ({
    isForm: true,
  }),
  validations: {
    newData: {
      path: { required },
    },
  },
  computed: {
    selectPdfButtonLabel() {
      return this.$t('select_pdf_file');
    },
    pdfFormatHelp() {
      return this.$t('pdf_format_help');
    },
    noPdfText() {
      return this.$t('no_pdf');
    },
    deleteButtonLabel() {
      return this.$t('delete');
    },
  },
  methods: {
    handleFile() {
      if (this.local.file.size > this.$constants.MAX_PDF_SIZE) {
        this.$buefy.dialog.alert(this.$t('file_too_large'));
        return;
      }

      this.uploadFile(this.viewProps.store, 'pdf');
    },
  },
  i18n: {
    messages: {
      en: {
        pdf_file: 'PDF File',
        select_pdf_file: 'Select a PDF file',
        pdf_format_help: 'Format .pdf, max 200 MB',
        caption: 'Caption',
        processing_media: 'Processing media...',
        no_pdf: 'No PDF',
        delete: 'Delete',
        file_too_large: 'The file exceeds 200 MB (too large). <br> Feel free to split your PDF file into several parts.',
      },
      fr: {
        pdf_file: 'Fichier PDF',
        select_pdf_file: 'Sélectionnez un fichier PDF',
        pdf_format_help: 'Format .pdf, max 200 Mo',
        caption: 'Légende',
        processing_media: 'Traitement du média...',
        no_pdf: 'Pas de PDF',
        delete: 'Supprimer',
        file_too_large: 'Le fichier dépasse 200 Mo (trop volumineux). <br> N\'hésitez pas à découper votre fichier PDF en plusieurs parties.',
      },
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <div class="columns is-multiline">
        <div class="column is-narrow">
          <b-field :label="$t('pdf_file')" v-bind="$getErrorProps($v.newData.path, ['required'])">
            <b-upload accept="application/pdf" v-model="local.file" expanded @input="handleFile">
              <b-button tag="a" class="button" icon-left="file-pdf" outlined>
                {{ selectPdfButtonLabel }}
              </b-button>
            </b-upload>
          </b-field>
          <p class="help is-success">
            {{ pdfFormatHelp }}
          </p>
        </div>
        <div class="column">
          <b-field :label="$t('caption')">
            <b-input v-model="newData.caption" />
          </b-field>
        </div>
        <div class="column is-12 has-text-centered">
          <template v-if="local.file">
            <b-progress
              v-if="!local.progressValue || local.progressValue < local.progressTotal"
              class="mb-2"
              type="is-success"
              :value="local.progressValue"
              :max="local.progressTotal"
              format="percent"
              show-value
            />
            <p v-else-if="saveIsDisabled">
              {{ $t('processing_media') }}
            </p>
            <p class="is-size-8 mb-5">
              {{ local.file.name }} - {{ localFileSize }} Mo
            </p>
          </template>
          <figure class="p-3 has-background-grey bradius-8">
            <div v-if="newData.path">
              <ContentBlockPDFViewerView :data="newData" />
            </div>
            <p v-else style="line-height:140px">
              {{ noPdfText }}
            </p>
          </figure>
          <p v-show="newData.path">
            <b-button type="is-text has-text-danger" @click="newData.path = ''">
              {{ deleteButtonLabel }}
            </b-button>
          </p>
        </div>
      </div>
    </template>
    <template #view>
      <ContentBlockPDFViewerView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
