<script>
import CTypes from '@shared/assets/data/training_item_content_block_view_types';

export default {
  props: {
    trainingItem: {
      type: Object,
      required: true,
    },
  },
  static: {
    CTypes,
  },
  computed: {
    store() {
      return this.$store.state.store.data;
    },
    currentTraining() {
      return this.$store.state.trainings.current;
    },
  },
  i18n: {
    messages: {
      fr: {
        no_content_yet: 'Il n\'y a pas encore de contenu dans cette leçon.',
      },
      en: {
        no_content_yet: 'There is no content in this lesson yet.',
      },
    },
  },
};
</script>

<template>
  <div>
    <template v-if="trainingItem.content.length">
      <div
        v-for="contentBlock in trainingItem.content"
        :class="{'container is-728': !contentBlock.data.__isWide}"
        :key="contentBlock.id"
      >
        <component
          v-if="$options.static.CTypes[contentBlock.type]"
          :is="$options.static.CTypes[contentBlock.type]"
          class="mb-5"
          :type="contentBlock.type"
          :data="contentBlock.data"
          :store="store"
          :training="currentTraining"
          :trainingItem="trainingItem"
          :trainingItems="currentTraining.items"
        />
      </div>
    </template>
    <p v-else class="has-text-centered">
      {{ $t('no_content_yet') }}
    </p>
  </div>
</template>
