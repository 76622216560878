<script>
import BasePasswordInput from '@shared/components/BasePasswordInput.vue';
import AffiliateConditionList from '@school/components/AffiliateConditionList.vue';
import { mapState } from 'vuex';

export default {
  components: { BasePasswordInput, AffiliateConditionList },
  data: () => ({
    isLoading: false,
    newAffiliate: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
    },
  }),
  computed: {
    ...mapState('store', {
      store: 'data',
    }),
    form() {
      return {
        firstname: {
          type: 'text',
          column: 'is-6',
          label: this.$t('register_affiliate_firstname'),
          inputAttrs: {
            required: true,
            maxlength: 128,
            hasCounter: false,
          },
        },
        lastname: {
          type: 'text',
          column: 'is-6',
          label: this.$t('register_affiliate_lastname'),
          inputAttrs: {
            required: true,
            maxlength: 128,
            hasCounter: false,
          },
        },
        email: {
          type: 'email',
          column: 'is-12',
          label: this.$t('register_affiliate_email'),
          inputAttrs: {
            required: true,
          },
        },
      };
    },
  },
  methods: {
    handle() {
      const newAffiliate = { ...this.newAffiliate };
      this.isLoading = true;
      this.$store.dispatch('auth/registerAffiliate', newAffiliate)
        .then(() => {
          this.$router.push({ name: 'dashboard' });
          this.$buefy.toast.open({
            type: 'is-success',
            message: this.$t('welcome_message'),
          });
        })
        .finally(() => (this.isLoading = false));
    },
  },
  i18n: {
    messages: {
      fr: {
        register_affiliate_program: 'Programme d\'affiliation',
        register_affiliate_firstname: 'Prénom',
        register_affiliate_lastname: 'Nom',
        register_affiliate_email: 'Email',
        password: 'Mot de passe',
        register_affiliate_submit: 'S\'inscrire',
        program_conditions: 'Conditions du programme',
        conditions_may_change: 'Les conditions peuvent changer',
        welcome_message: 'Bienvenue !',
      },
      en: {
        register_affiliate_program: 'Affiliate Program',
        register_affiliate_firstname: 'First name',
        register_affiliate_lastname: 'Last name',
        register_affiliate_email: 'Email',
        password: 'Password',
        register_affiliate_submit: 'Register',
        program_conditions: 'Program conditions',
        conditions_may_change: 'The terms and conditions of this program are subject to change',
        welcome_message: 'Welcome!',
      },
    },
  },
};
</script>

<template>
  <section class="rapage section has-background-light is-custom">
    <div class="container is-520">
      <form
        class="box"
        @submit.prevent="dataIsValid(isLoading) && handle()"
      >
        <h1 class="title is-4 is-size-5-touch is-custom">
          {{ $t('register_affiliate_program') }}
        </h1>

        <div class="columns is-multiline">
          <div
            v-for="(field, k) in form"
            :key="k"
            class="column mb-0"
            :class="field.column"
          >
            <GeneratorField
              :fieldName="k"
              :fieldDefinition="field"
              :formData.sync="newAffiliate"
            />
          </div>
          <div class="column is-12">
            <b-field :label="$t('password')">
              <BasePasswordInput
                v-model="newAffiliate.password"
              />
            </b-field>
          </div>
          <div class="column is-12 has-text-right">
            <b-button
              type="is-primary is-custom"
              native-type="submit"
              :loading="isLoading"
              expanded
            >
              {{ $t('register_affiliate_submit') }}
            </b-button>
          </div>
        </div>
      </form>

      <article class="box">
        <h2 class="title is-5 is-size-6-touch is-custom">
          {{ $t('program_conditions') }}
        </h2>
        <AffiliateConditionList class="is-size-9-mobile" />
        <div
          v-if="store.affiliation_options.description"
          class="content mt-4"
          v-html="store.affiliation_options.description"
        />
        <p class="has-text-weight-bold mt-4">
          {{ $t('conditions_may_change') }}
        </p>
      </article>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.rapage {
  min-height: $school-main-min-height;
}
</style>
