<script>
import linkTypes from '@shared/assets/data/social_links.json';

export default {
  props: {
    buyable: {
      type: Object,
      required: false,
    },
  },
  static: { linkTypes },
  computed: {
    store() {
      return this.$store.state.store.data;
    },
    author() {
      if (this.buyable) {
        return this.buyable.author;
      }

      return this.store && this.store.user;
    },
  },
  i18n: {
    messages: {
      en: {
        teacher_section_title: 'Content by',
      },
      fr: {
        teacher_section_title: 'Contenu proposé par',
      },
    },
  },
};
</script>

<template>
  <section v-if="author && author.bio" class="comp section has-background-light is-medium is-custom">
    <div class="container">
      <h3 class="title is-custom is-4 is-size-5-touch">
        {{ $t('teacher_section_title') }}
      </h3>
      <article class="media is-block-mobile">
        <figure v-if="author.picture" class="comp_img media-left bradius-4 overflow-hidden">
          <img v-if="author.picture" class="is-block w-full" :src="`${$env.apiResourcesURL}/${author.picture}`"
            :alt="author.firstname" />
        </figure>
        <div class="media-content has-text-justified">
          <h2 class="title is-custom is-4 is-size-6">
            {{ author.firstname }}
          </h2>
          <div class="content" v-html="author.bio" />
          <ul v-if="!buyable && store.social_links.length" class="columns is-mobile mt-6">
            <li v-for="link in store.social_links" :key="link.id" class="column is-narrow">
              <b-button tag="a" type="is-primary is-custom is-small" :href="link.url" target="_blank"
                rel="noopener noreferrer" :icon-left="$options.static.linkTypes[link.name]"
                :icon-pack="$options.static.linkTypes[link.name] == 'globe' ? 'fa' : 'fab'" :title="link.name" />
            </li>
          </ul>
        </div>
      </article>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.comp {
  &_img {
    width: 160px;
    margin-right: 20px;
  }

  .media-content {
    overflow: initial;
  }

  @include mobile {
    .title {
      text-align: center;
    }

    &_img {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
