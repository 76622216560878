var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.comments.data.length > 0)?_c('div',[_c('h2',{staticClass:"title is-5 mt-10"},[_vm._v(" "+_vm._s(_vm.responsesTitle)+" ")]),_c('hr',{staticClass:"has-background-black10"}),_vm._l((_vm.comments.data),function(comment){return _c('ListCommentItem',{key:comment.uuid,staticClass:"mt-3",attrs:{"post":_vm.post,"comment":comment},scopedSlots:_vm._u([{key:"childrenPagination",fn:function(ref){
var links = ref.links;
return [(links && links.next)?_c('p',[_c('b-button',{staticClass:"tdecoration-underline",attrs:{"type":"is-text","size":"is-small"},on:{"click":function($event){return _vm.$emit('load-comments', {
              parent: comment,
              url: links.next,
              append: true
            })}}},[_vm._v(" "+_vm._s(_vm.$t('see_more_comments'))+" ")])],1):_vm._e()]}}],null,true)},[(
          !(comment.settings && comment.settings.readonly)
          && !_vm.postCommentsReadonly
        )?_c('ListPostCommentForm',{attrs:{"post":_vm.post,"parentUUID":comment.uuid}}):_vm._e()],1)}),(_vm.comments.links && _vm.comments.links.next)?_c('p',{staticClass:"has-text-centered mt-3"},[_c('b-button',{staticClass:"tdecoration-underline",attrs:{"type":"is-text","size":"is-small"},on:{"click":function($event){return _vm.$emit('load-comments', {
          parent: null,
          url: _vm.comments.links.next,
          append: true
        })}}},[_vm._v(" "+_vm._s(_vm.$t('see_more_comments'))+" ")])],1):_vm._e()],2):_vm._e(),(!_vm.postCommentsReadonly)?_c('ListPostCommentForm',{staticClass:"mt-5",attrs:{"post":_vm.post}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }