import { render, staticRenderFns } from "./TrainingBuyButton.vue?vue&type=template&id=03858395&scoped=true&"
import script from "./TrainingBuyButton.vue?vue&type=script&lang=js&"
export * from "./TrainingBuyButton.vue?vue&type=script&lang=js&"
import style0 from "./TrainingBuyButton.vue?vue&type=style&index=0&id=03858395&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03858395",
  null
  
)

export default component.exports