<script>
import { mapState } from 'vuex';
import { maxLength } from 'vuelidate/lib/validators';

export default {
  props: {
    collection: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      newCollection: this.collection ? { ...this.collection } : {
        name: '',
        description: '',
      },
    };
  },
  computed: {
    ...mapState('community', ['collectionIsLoading']),
    form() {
      return {
        name: {
          label: this.$t('name_label'),
          type: 'text',
          column: 'is-12',
          inputAttrs: {
            required: true,
            maxlength: 100,
            hasCounter: false,
          },
        },
        description: {
          label: this.$t('description_label'),
          type: 'editor',
          column: 'is-12',
          inputAttrs: {
            placeholder: this.$t('description_placeholder'),
          },
          errors: [
            { name: 'maxLength', args: [5000] },
          ],
        },
      };
    },
  },
  validations: {
    newCollection: {
      description: {
        maxLength: maxLength(5000),
      },
    },
  },
  methods: {
    handle() {
      let promise;

      if (this.collection) {
        promise = this.$store.dispatch('community/updateCollection', {
          uuid: this.collection.uuid,
          data: { ...this.newCollection },
        });
      } else {
        promise = this.$store.dispatch('community/addCollection', {
          ...this.newCollection,
        });
      }

      promise.then(() => {
        this.$emit('done');
        this.$showMessage.success();
      });
    },
  },
  i18n: {
    messages: {
      en: {
        modify_collection: 'Modify collection',
        create_collection: 'Create a collection',
        name_label: 'Collection name',
        description_label: 'Collection description',
        description_placeholder: 'Describe here the purpose and characteristics of this collection',
        cancel: 'Cancel',
        modify: 'Modify',
        add: 'Add',
      },
      fr: {
        modify_collection: 'Modifier la collection',
        create_collection: 'Créer une collection',
        name_label: 'Nom de la collection',
        description_label: 'Description de la collection',
        description_placeholder: 'Décrivez ici l\'objectif et les caractéristiques de cette collection',
        cancel: 'Annuler',
        modify: 'Modifier',
        add: 'Ajouter',
      },
    },
  },
};
</script>

<template>
  <AppCardModal
    tag="form"
    hasCloseBtn
    @close="$emit('cancel')"
    @submit.prevent="dataIsValid(collectionIsLoading) && handle()"
  >
    <template #title>
      <template v-if="collection">
        {{ $t('modify_collection') }}
      </template>
      <template v-else>
        {{ $t('create_collection') }}
      </template>
    </template>
    <template #body>
      <div class="columns is-multiline">
        <div
          v-for="(field, k) in form"
          :key="k"
          class="column mb-0"
          :class="field.column"
        >
          <GeneratorField
            :fieldName="k"
            :fieldDefinition="field"
            :formData.sync="newCollection"
            :formValidation="$v.newCollection"
          />
        </div>
      </div>
      <div class="buttons is-right">
        <b-button type="is-text" @click="$emit('cancel')">
          {{ $t('cancel') }}
        </b-button>
        <b-button
          type="is-primary is-custom"
          native-type="submit"
          :loading="collectionIsLoading"
        >
          <template v-if="collection">
            {{ $t('modify') }}
          </template>
          <template v-else>
            {{ $t('add') }}
          </template>
        </b-button>
      </div>
    </template>
  </AppCardModal>
</template>
