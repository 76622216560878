<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import utilsMixin from '@shared/mixins/utils';
import CardPostSingle from '@shared/components/Community/CardPostSingle.vue';
import ListPostSingle from '@shared/components/Community/ListPostSingle.vue';

export default {
  mixins: [utilsMixin],
  components: {
    CardPostSingle,
    ListPostSingle,
  },
  head() {
    return {
      title: this.$t('pages.post.title'),
    };
  },
  data: () => ({
    isFetching: false,
  }),
  computed: {
    ...mapState('community', {
      currentGroup: 'currentGroup',
      post: 'currentGroupPost',
      comment: 'currentGroupComment',
    }),
    wrapper() {
      if (this.currentGroup.type === 'LIST') {
        return 'ListPostSingle';
      }

      return 'CardPostSingle';
    },
  },
  methods: {
    ...mapMutations('community', {
      setCommunityState: 'setState',
      decrementGroupCounter: 'decrementGroupCounter',
    }),
    ...mapActions('community', [
      'getPost',
      'getComment',
    ]),
    loadPost() {
      this.isFetching = true;

      this.setCommunityState({
        currentGroupComment: null,
      });

      const promises = [
        this.getPost({ uuid: this.$route.params.postUUID }),
      ];

      if (this.$route.query.comment) {
        promises.push(
          this.getComment({ uuid: this.$route.query.comment }),
        );
      }

      Promise.all(promises)
        .catch((error) => this.showErrorPage(error))
        .finally(() => (this.isFetching = false));
    },
  },
  watch: {
    $route() {
      this.loadPost();
    },
  },
  created() {
    this.loadPost();
  },
  destroyed() {
    this.setCommunityState({
      currentGroupPost: null,
      currentGroupComment: null,
    });
  },
};
</script>

<template>
  <div class="px-3-mobile">
    <nav class="mb-5">
      <b-button
        class="is-size-8-mobile pl-0"
        type="is-text"
        tag="router-link"
        :to="{name: 'community_group_posts'}"
        icon-left="chevron-left"
      >
        {{ $t('pages.post.back') }}
      </b-button>
    </nav>

    <b-skeleton v-if="isFetching" height="400" />
    <component
      v-else
      :is="wrapper"
      :post="post"
      :comment="comment"
    />
  </div>
</template>
