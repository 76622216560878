<template>
  <article class="section mx-auto w-640 content is-large has-text-centered">
    <h1>{{ $t('page_not_found_title') }}</h1>
    <p v-if="$store.state.errors.message" v-html="$store.state.errors.message" />
    <p v-else>
      {{ $t('page_not_found_message') }}
    </p>
    <p>
      <template v-if="$routeReferrer">
        <b-button
          class="is-custom"
          type="is-primary"
          tag="router-link"
          :to="$routeReferrer"
        >
          {{ $t('back_to_previous_page') }}
        </b-button>
        <br>
      </template>
      <b-button
        type="is-text"
        tag="router-link"
        :to="{name: 'dashboard'}"
      >
        {{ $t('back_to_home') }}
      </b-button>
    </p>
  </article>
</template>

<script>
export default {
  name: '404',
  i18n: {
    messages: {
      en: {
        page_not_found_title: 'It seems you are lost',
        page_not_found_message: 'This page does not exist!',
        back_to_previous_page: 'Back to previous page',
        back_to_home: 'Back to home',
      },
      fr: {
        page_not_found_title: 'Il semblerait que vous vous soyez perdu',
        page_not_found_message: 'Cette page n\'existe pas !',
        back_to_previous_page: 'Retour à la page précédente',
        back_to_home: 'Retour à l\'accueil',
      },
    },
  },
};
</script>
