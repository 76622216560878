import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { merge } from 'lodash';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const shared = require.context('../../shared/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};

  // First load shared translations
  shared.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = shared(key);
    }
  });

  // Then deep merge local translations
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      if (locale in messages) {
        messages[locale] = merge({}, messages[locale], locales(key));
      } else {
        messages[locale] = locales(key);
      }
    }
  });

  return messages;
}

export const messages = loadLocaleMessages();

export default new VueI18n({
  locale: document.documentElement.lang || process.env.VUE_APP_I18N_LOCALE || 'fr',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'fr',
  messages,
});
