<script>
import ResetPasswordForm from '@shared/components/ResetPasswordForm.vue';

export default {
  head() {
    return {
      title: this.$t('reset_password_title'),
    };
  },
  components: { ResetPasswordForm },
  i18n: {
    messages: {
      en: {
        reset_password_title: 'Password Reset',
      },
      fr: {
        reset_password_title: 'Réinitialisation du mot de passe',
      },
    },
  },
};
</script>

<template>
  <section class="section">
    <div class="container w-640">
      <ResetPasswordForm :to="{name: 'login'}" />
    </div>
  </section>
</template>
