<script>
import { mapState, mapActions } from 'vuex';
import CardCommentsList from '@shared/components/Community/CardCommentsList.vue';
import ListCommentsList from '@shared/components/Community/ListCommentsList.vue';

export default {
  components: {
    CardCommentsList,
    ListCommentsList,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('community', {
      currentGroup: 'currentGroup',
    }),
    wrapper() {
      if (this.currentGroup.type === 'LIST') {
        return 'ListCommentsList';
      }

      return 'CardCommentsList';
    },
    comments() {
      return this.post.comments;
    },
    postCommentsReadonly() {
      return this.post.settings?.readonly;
    },
    loadingText() {
      return this.$t('loading_text');
    },
  },
  methods: {
    ...mapActions('community', [
      'loadComments',
    ]),
    localLoadComments({ parent = null, url = null, append = false }) {
      const loader = this.$buefy.loading.open({
        container: this.$el,
        message: this.loadingText,
      });

      this.loadComments({
        postUUID: this.post.uuid,
        parentUUID: parent?.uuid,
        url,
        append,
      })
        .finally(() => loader.close());
    },
  },
  i18n: {
    messages: {
      en: {
        loading_text: 'Loading...',
        comments_section: 'Comments Section',
      },
      fr: {
        loading_text: 'Chargement...',
        comments_section: 'Section des commentaires',
      },
    },
  },
};
</script>

<template>
  <div
    v-if="!postCommentsReadonly || comments.data.length > 0"
    class="is-relative"
  >
    <component
      :is="wrapper"
      :post="post"
      @load-comments="localLoadComments($event)"
      :aria-label="$t('comments_section')"
    />
  </div>
</template>
