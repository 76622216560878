<script>
import { mapActions, mapMutations } from 'vuex';
import utilsMixin from '@shared/mixins/utils';
import CommunityAbuseForm from '@shared/components/CommunityAbuseForm.vue';

export default {
  mixins: [utilsMixin],
  i18n: {
    messages: {
      en: {
        unpin: 'Unpin',
        pin_to_top: 'Pin to top of discussions',
        unsubscribe: 'Stop receiving notifications for this post',
        subscribe: 'Receive notifications for this post',
        enable_replies: 'Enable replies',
        disable_replies: 'Disable replies',
        report: 'Report',
        edit: 'Edit',
        delete: 'Delete',
        cancel: 'Cancel',
        delete_confirmation: 'Are you sure you want to delete this post? <strong>All associated replies and media will be permanently deleted</strong>.',
      },
      fr: {
        unpin: 'Enlever l\'épingle',
        pin_to_top: 'Épingler en tête des discussions',
        unsubscribe: 'Ne plus recevoir de notifications pour cette publication',
        subscribe: 'Recevoir les notifications pour cette publication',
        enable_replies: 'Activer les réponses',
        disable_replies: 'Désactiver les réponses',
        report: 'Signaler',
        edit: 'Modifier',
        delete: 'Supprimer',
        cancel: 'Annuler',
        delete_confirmation: 'Êtes-vous sûr(e) de vouloir supprimer cette publication ? <strong>Toutes réponses et médias associés seront définitivement supprimés</strong>.',
      },
    },
  },
  props: {
    canManageCommunityEntity: {
      type: Boolean,
      required: true,
    },
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    pinLabel() {
      return this.post.pinned ? this.$t('unpin') : this.$t('pin_to_top');
    },
    subscriptionLabel() {
      return this.post.subscribed ? this.$t('unsubscribe') : this.$t('subscribe');
    },
    readonlyLabel() {
      return this.post.settings && this.post.settings.readonly
        ? this.$t('enable_replies')
        : this.$t('disable_replies');
    },
  },
  methods: {
    ...mapMutations('community', [
      'showPostForm',
    ]),
    ...mapActions('community', [
      'togglePinPost',
      'toggleSubscriptionPost',
      'toggleReadonlyPost',
      'deletePost',
    ]),
    togglePin() {
      const load = this.$buefy.loading.open({ container: this.$refs.list });
      this.togglePinPost({ post: this.post })
        .finally(() => (load.close()));
    },
    toggleSubscription() {
      const load = this.$buefy.loading.open({ container: this.$refs.list });
      this.toggleSubscriptionPost({ post: this.post })
        .finally(() => (load.close()));
    },
    toggleReadonly() {
      const load = this.$buefy.loading.open({ container: this.$refs.list });
      this.toggleReadonlyPost({ post: this.post })
        .finally(() => (load.close()));
    },
    deletePostDialog() {
      this.showAsyncConfirm({
        message: this.$t('delete_confirmation'),
        cancelText: this.$t('cancel'),
      }, ({ done }) => {
        const load = this.$buefy.loading.open();
        return this.deletePost({ uuid: this.post.uuid })
          .then(() => {
            if (this.$route.name === 'community_group_post') {
              this.$router.push({ name: 'community_group_posts' });
            }

            done();
          })
          .finally(() => load.close());
      });
    },
    report() {
      let modal = this.$buefy.modal.open({
        parent: this,
        component: CommunityAbuseForm,
        canCancel: false,
        hasModalCard: true,
        props: {
          context: 'post',
          contextUUID: this.post.uuid,
        },
        events: {
          cancel() {
            modal.close();
            modal = null;
          },
          done() {
            modal.close();
            modal = null;
          },
        },
      });
    },
  },
};
</script>

<template>
  <b-dropdown position="is-bottom-left">
    <template #trigger>
      <b-button
        class="px-0"
        type="is-text"
        size="is-small"
        icon-left="ellipsis-v"
      />
    </template>
    <b-dropdown-item
      v-if="isStoreAdmin"
      @click="togglePin"
    >
      <b-icon icon="thumbtack" />
      {{ pinLabel }}
    </b-dropdown-item>
    <b-dropdown-item @click="toggleSubscription">
      <b-icon
        :pack="post.subscribed ? 'far' : 'far'"
        :icon="post.subscribed ? 'bell-slash' : 'bell'"
      />
      {{ subscriptionLabel }}
    </b-dropdown-item>
    <b-dropdown-item
      v-if="canManageCommunityEntity"
      @click="toggleReadonly"
    >
      <b-icon :icon="post.settings && post.settings.readonly ? 'comment' : 'comment-slash'" />
      {{ readonlyLabel }}
    </b-dropdown-item>
    <b-dropdown-item @click="report">
      <b-icon pack="far" icon="flag" />
      {{ $t('report') }}
    </b-dropdown-item>
    <template v-if="canManageCommunityEntity">
      <b-dropdown-item separator />
      <b-dropdown-item @click="showPostForm({ post })">
        <b-icon icon="pen" />
        {{ $t('edit') }}
      </b-dropdown-item>
      <b-dropdown-item
        class="has-text-danger"
        @click="deletePostDialog"
      >
        <b-icon icon="trash" />
        {{ $t('delete') }}
      </b-dropdown-item>
    </template>
  </b-dropdown>
</template>
