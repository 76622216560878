<script>
import { getLocal } from '@shared/services/Utils';
import utilsMixin from '@shared/mixins/utils';
import RegisterFormStepEmail from '@school/components/RegisterFormStepEmail.vue';
import RegisterFormStepInfos from '@school/components/RegisterFormStepInfos.vue';

export default {
  mixins: [utilsMixin],
  provide() {
    return {
      store: this.store,
      buyable: this.buyable,
      price: this.price,
      authCustomer: this.authCustomer,
    };
  },
  components: {
    RegisterFormStepEmail,
    RegisterFormStepInfos,
  },
  props: {
    isEmbed: {
      type: Boolean,
      default: false,
    },
    canStay: {
      type: Boolean,
      default: true,
    },
    buyable: {
      type: Object,
      required: true,
    },
    buyableName: {
      type: String,
      default: null,
    },
    hasModal: {
      type: Boolean,
      default: true,
    },
    hasCloseBtn: {
      type: Boolean,
      default: true,
    },
    hasPaymentPlans: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    messages() {
      return {
        frequencies: {
          MONTHLY: {
            name: this.$t('monthly_frequency_name'),
            description: this.$t('monthly_frequency_description'),
          },
          EVERY_TWO_WEEKS: {
            name: this.$t('biweekly_frequency_name'),
            description: this.$t('biweekly_frequency_description'),
          },
          WEEKLY: {
            name: this.$t('weekly_frequency_name'),
            description: this.$t('weekly_frequency_description'),
          },
        },
      };
    },
    store() {
      return this.$store.state.store.data;
    },
    hasPercentPlan() {
      return this.$store.getters['store/hasPercentPlan'];
    },
    isNotPercentPlan() {
      return this.$store.getters['store/isNotPercentPlan'];
    },
    authCustomer() {
      return this.$store.getters['auth/customer'];
    },
    hasDiscountPrice() {
      return this.buyable.discount_price != null;
    },
    price() {
      return this.hasDiscountPrice
        ? this.buyable.discount_price
        : this.buyable.price;
    },
    oldPrice() {
      const buyablePrice = this.buyable.price;
      const oldChosenPrice = this.chosenPaymentPlan ? this.chosenPaymentPlan.amount : buyablePrice;
      return this.newPrice != null ? oldChosenPrice : buyablePrice;
    },
    chosenPrice() {
      return this.chosenPaymentPlan ? this.chosenPaymentPlan.amount : this.price;
    },
    displayPrice() {
      return this.newPrice != null ? this.newPrice : this.chosenPrice;
    },
  },
  data() {
    const isNotPercentPlan = this.$store.getters['store/isNotPercentPlan'];

    return {
      isLoading: false,
      isEnrolling: false,
      step: 'email',
      newPrice: null,
      chosenPaymentPlan: null,
      priceSelectorIsOpen: isNotPercentPlan && this.hasPaymentPlans,
      tempCustomer: null,
      customer: {
        confirmedIntent: null,
        email: {},
        infos: {},
      },
    };
  },
  methods: {
    togglePriceSelector() {
      this.priceSelectorIsOpen = !this.priceSelectorIsOpen;
      this.$nextTick(() => this.$emit('change'));
    },
    handleNext() {
      let handlePromise;

      if (this.step === 'email') {
        handlePromise = this.handleEmailStep();
      } else {
        handlePromise = this.handleInfosStep();
      }

      handlePromise.then(() => this.$nextTick(() => this.$emit('change')));
    },
    goToInfos() {
      this.step = 'infos';
      this.priceSelectorIsOpen = false;
      this.$gtag.checkoutProgress({
        coupon: this.customer.infos.discount_code,
        items: [{
          id: this.buyable.uuid,
          name: this.buyable.name,
          quantity: 1,
          price: this.displayPrice,
        }],
      });
      this.$fbq.lead({
        content_name: this.buyable.name,
        value: this.chosenPrice,
      });
    },
    handleComplete() {
      if (this.isEmbed) {
        this.$buefy.dialog.confirm({
          message: this.buyable.buyable_type === 'TRAINING'
            ? this.$t('training_already_owned')
            : this.$t('pack_already_owned'),
          confirmText: this.buyable.buyable_type === 'TRAINING'
            ? this.$t('view_training')
            : this.$t('view_pack'),
          cancelText: this.$t('stay_here'),
          focusOn: 'cancel',
          onCancel: () => this.$emit('close'),
          onConfirm: () => this.$emit('complete'),
        });
      } else {
        this.$buefy.notification.open({
          type: 'is-success',
          message: this.buyable.buyable_type === 'TRAINING'
            ? this.$t('training_already_owned')
            : this.$t('pack_already_owned'),
        });
        this.$emit('complete');
      }
    },
    handleEnroll() {
      const { redirect } = this.buyable.feature_options;

      if (this.hasPercentPlan || !redirect) {
        if (this.isEmbed) {
          const func = this.$buefy.dialog[this.canStay ? 'confirm' : 'alert'];
          func({
            message: this.buyable.buyable_type === 'TRAINING'
              ? this.$t('welcome_to_training')
              : this.$t('welcome'),
            confirmText: this.buyable.buyable_type === 'TRAINING'
              ? this.$t('view_training')
              : this.$t('view_trainings'),
            cancelText: this.$t('stay_here'),
            onCancel: () => this.$emit('close'),
            onConfirm: () => this.$emit('enroll'),
          });
        } else {
          this.$buefy.toast.open({
            type: 'is-success',
            message: this.$t('welcome'),
          });
          this.$emit('enroll');
        }
      }

      this.$gtag.purchase({
        items: [{
          id: this.buyable.uuid,
          name: this.buyable.name,
          quantity: 1,
          price: this.displayPrice,
        }],
        value: this.displayPrice,
      });
      this.$fbq.purchase({
        content_ids: [this.buyable.uuid],
        content_name: this.buyable.name,
        num_items: 1,
        value: this.displayPrice,
      });

      if (this.isNotPercentPlan && redirect) {
        if (this.isEmbed) {
          this.$emit('enroll');
          return;
        }

        window.location.href = redirect;
      }
    },
    handleEmailStep(customerEmailInfos = null) {
      customerEmailInfos = customerEmailInfos || this.customer.email;
      this.isLoading = true;
      return this.$store.dispatch('auth/register', {
        autoLogin: false,
        ...(this.buyable.buyable_type === 'TRAINING' ? { training_uuid: this.buyable.uuid } : {}),
        ...(this.buyable.buyable_type === 'BUNDLE' ? { bundle_uuid: this.buyable.uuid } : {}),
        ...customerEmailInfos,
      })
        .then((customer) => {
          let result;
          this.tempCustomer = customer;

          switch (this.tempCustomer.enrollment_status) {
            case 'COMPLETED':
              this.handleComplete();
              break;
            case 'TRAINING_CLOSED':
              this.$buefy.notification.open({
                type: 'is-warning',
                message: this.$t('enrollment_closed'),
              });
              break;
            case 'CREATED':
            case 'IN_PROGRESS':
              if (this.chosenPrice <= 0) {
                if (this.tempCustomer.firstname) {
                  result = this.enroll();
                }
              }

              this.goToInfos(this.tempCustomer);
              break;
            case 'ATTACHED':
              if (this.chosenPrice > 0 || !this.tempCustomer.firstname) {
                this.goToInfos(this.tempCustomer);
                break;
              }

              result = this.enroll();
              break;
            case 'MAX_STUDENT_NUMBER_REACHED':
              this.$buefy.notification.open({
                type: 'is-danger',
                message: this.$t('training_full'),
              });
              break;

            default:
              break;
          }

          return result;
        })
        .finally(() => (this.isLoading = false));
    },
    enroll(data = {}) {
      this.isLoading = true;
      this.isEnrolling = true;

      const oldFirstname = this.tempCustomer && this.tempCustomer.firstname;
      const oldLastname = this.tempCustomer && this.tempCustomer.lastname;
      const affiliate_codes = this.hasPercentPlan ? undefined : getLocal(
        this.$constants.LSKEY_AFFILIATE_CODES,
        true,
        undefined,
      );

      if (oldFirstname) {
        delete data.firstname;
        delete data.lastname;
        delete data.password;
      }

      if (this.displayPrice > 0 && this.buyable.feature_options.billing_address.enabled) {
        if (!data.name) {
          data.name = oldFirstname
            ? `${oldFirstname} ${oldLastname}`
            : `${data.firstname} ${data.lastname}`;
        }
      }

      return this.$store.dispatch('auth/enroll', {
        buyable_type: this.buyable.buyable_type,
        customer_uuid: this.tempCustomer.uuid,
        ...(this.buyable.buyable_type === 'TRAINING' ? { training_uuid: this.buyable.uuid } : {}),
        ...(this.buyable.buyable_type === 'BUNDLE' ? { bundle_uuid: this.buyable.uuid } : {}),
        affiliate_codes,
        payment_schedule_id: this.chosenPaymentPlan ? this.chosenPaymentPlan.id : undefined,
        ...data,
      })
        .then((data) => (data.requires_action ? data : this.handleEnroll()))
        .finally(() => {
          this.isLoading = false;
          this.isEnrolling = false;
        });
    },
    sendCheckoutStats() {
      this.$gtag.checkoutProgress({
        coupon: this.customer.infos.discount_code,
        items: [{
          id: this.buyable.uuid,
          name: this.buyable.name,
          quantity: 1,
          price: this.displayPrice,
        }],
      });
      this.$fbq.initiateCheckout({
        content_ids: [this.buyable.uuid],
        num_items: 1,
        value: this.displayPrice,
      });
    },
    handleInfosStep() {
      if (this.displayPrice <= 0) {
        this.isLoading = true;
        this.isEnrolling = true;
        this.sendCheckoutStats();
        this.enroll(this.customer.infos);
        return Promise.resolve();
      }

      if (!this.$refs.infosStep.stripeIsCompleted) {
        return Promise.resolve();
      }

      const customerInfos = {
        ...this.customer.infos,
        discount_code: this.customer.infos.discount_code || undefined,
      };

      this.isLoading = true;
      this.isEnrolling = true;

      this.sendCheckoutStats();

      return this.$refs.infosStep.$refs.card.createPaymentMethod()
        .then((res) => this.enroll({
          ...customerInfos,
          payment_method_id: res.paymentMethod.id,
        }))
        .then((res) => {
          if (!res) {
            return null;
          }

          if (res.requires_action) {
            return this.handlePaymentAction(res.client_secret, customerInfos);
          }

          return null;
        })
        .finally(() => {
          this.isLoading = false;
          this.isEnrolling = false;
        });
    },
    handlePaymentAction(clientSecret, customerInfos) {
      this.isLoading = true;
      this.isEnrolling = true;

      return this.$refs.infosStep.$refs.card.handleCardAction(clientSecret).then((res) => {
        const { paymentIntent } = res;

        if (paymentIntent.status === 'requires_confirmation') {
          return this.enroll({ ...customerInfos, payment_intent_id: paymentIntent.id });
        }

        return null;
      });
    },
    onPayPalOrder(order_id) {
      const customerInfos = {
        ...this.customer.infos,
        order_id,
        discount_code: this.customer.infos.discount_code || undefined,
      };

      this.isLoading = true;
      this.isEnrolling = true;

      this.sendCheckoutStats();
      this.enroll(customerInfos)
        .finally(() => {
          this.isLoading = false;
          this.isEnrolling = false;
        });
    },
  },
  i18n: {
    messages: {
      en: {
        monthly_frequency_name: 'monthly',
        monthly_frequency_description: 'every 30 days',
        biweekly_frequency_name: 'bi-weekly',
        biweekly_frequency_description: 'every 14 days',
        weekly_frequency_name: 'weekly',
        weekly_frequency_description: 'every 7 days',
        training_already_owned: 'You already own this training!',
        pack_already_owned: 'You already own this bundle!',
        view_training: 'Discover the training',
        view_pack: 'Discover the bundle',
        stay_here: 'Stay here',
        welcome_to_training: 'Welcome to the training!',
        welcome: 'Welcome!',
        view_trainings: 'Discover trainings',
        free: 'Free',
        payments_made: 'Payments are processed',
        hide_prices: 'hide prices',
        show_prices: 'show prices',
        single_payment: '1 single payment of {price}',
        multiple_payments: '{count} payments of {amount}',
        processing: 'Processing...',
        enrollment_closed: 'Enrollments are currently closed.',
        training_full: 'The training is full.',
      },
      fr: {
        monthly_frequency_name: 'mensuel',
        monthly_frequency_description: 'tous les 30 jours',
        biweekly_frequency_name: 'bimensuel',
        biweekly_frequency_description: 'tous les 14 jours',
        weekly_frequency_name: 'hebdomadaire',
        weekly_frequency_description: 'tous les 7 jours',
        training_already_owned: 'Vous possédez déjà cette formation !',
        pack_already_owned: 'Vous possédez déjà ce pack !',
        view_training: 'Voir la formation',
        view_pack: 'Voir le pack',
        stay_here: 'Rester ici',
        welcome_to_training: 'Bienvenue dans la formation !',
        welcome: 'Bienvenue !',
        view_trainings: 'Voir les formations',
        free: 'Gratuit',
        payments_made: 'Paiements effectués',
        hide_prices: 'masquer les prix',
        show_prices: 'afficher les prix',
        single_payment: '1 seul paiement de {price}',
        multiple_payments: '{count} paiements de {amount}',
        processing: 'Traitement en cours...',
        enrollment_closed: 'Les inscriptions sont actuellement fermées.',
        training_full: 'La formation est complète.',
      },
    },
  },
};
</script>

<template>
  <component :is="hasModal ? 'b-modal' : 'div'" class="compmodal" width="520px" :can-cancel="['escape', 'outside']"
    @close="$emit('close')" active>
    <form class="compmodal_box is-relative box p-0 mb-0" @submit.prevent="dataIsValid(isLoading) && handleNext()">
      <button v-if="hasCloseBtn" type="button" class="compmodal_close delete is-absolute" @click="$emit('close')" />

      <header class="compmodal_header is-flex p-8 has-background-light is-custom">
        <figure v-if="buyable.picture" class="compmodal_img image flex-none mt-1 is-hidden-mobile">
          <img class="bradius-4 w-full h-auto mx-auto" :src="`${$env.apiResourcesURL}/${buyable.picture}`"
            :alt="buyableName || buyable.name">
        </figure>

        <div class="ml-4">
          <h1 class="title is-custom is-4 is-size-6-touch mb-0">
            {{ buyableName || buyable.name }}
          </h1>
          <h2 class="is-size-8 has-text-weight-normal">
            {{ store.name }}
          </h2>
          <p class="mt-3 is-size-6 is-size-8-touch has-text-primary is-custom">
            <template v-if="displayPrice > 0">
              <template v-if="chosenPaymentPlan">
                {{ $t('multiple_payments', { count: chosenPaymentPlan.nb_schedule, amount: $options.filters.formatPrice(displayPrice) }) }}
              </template>
              <template v-else>
                {{ displayPrice | formatPrice }}
              </template>
            </template>
            <template v-else>
              {{ $t('free') }}
            </template>
            <span v-if="newPrice != null || (!chosenPaymentPlan && hasDiscountPrice)"
              class="is-size-8 has-text-black is-cutoms opacity-70 tdecoration-line-through">
              <template v-if="oldPrice > 0">
                <template v-if="chosenPaymentPlan">
                  {{ $t('multiple_payments', { count: chosenPaymentPlan.nb_schedule, amount: $options.filters.formatPrice(oldPrice) }) }}
                </template>
                <template v-else>
                  {{ oldPrice | formatPrice }}
                </template>
              </template>
              <template v-else>
                {{ $t('free') }}
              </template>
            </span>
          </p>
          <p v-if="!chosenPaymentPlan && hasDiscountPrice && buyable.discount_price_reason"
            class="opacity-70 is-size-8">
            {{ buyable.discount_price_reason }}
          </p>
          <p v-if="chosenPaymentPlan" class="opacity-70 is-size-8">
            {{ $t('payments_made') }}
            {{ $t(messages.frequencies[chosenPaymentPlan.frequency].description) }}
          </p>
        </div>
      </header>
      <main class="compmodal_main p-8">
        <template v-if="isNotPercentPlan && hasPaymentPlans && buyable.payment_schedules.length > 0">
          <p class="content mb-3 has-text-right tdecoration-underline">
            <a href="#" @click="togglePriceSelector">
              {{ priceSelectorIsOpen ? $t('hide_prices') : $t('show_prices') }}
            </a>
          </p>
          <div v-if="priceSelectorIsOpen" class="panel">
            <div class="panel-block p-3">
              <b-radio :native-value="null" v-model="chosenPaymentPlan" class="mr-0">
                <span class="has-text-weight-bold">
                  {{ $t('single_payment', { price: $options.filters.formatPrice(price) }) }}
                </span>
              </b-radio>
            </div>
            <div v-for="plan in buyable.payment_schedules" :key="plan.id" class="panel-block p-3">
              <b-radio :native-value="plan" v-model="chosenPaymentPlan">
                <span class="has-text-weight-bold">
                  {{ $t('multiple_payments', { count: plan.nb_schedule, amount: $options.filters.formatPrice(plan.amount) }) }}
                </span>
                <br>
                <span class="is-size-8 is-size-9-mobile">
                  {{ $t('payments_made') }} {{ $t(messages.frequencies[plan.frequency].description) }}
                </span>
              </b-radio>
            </div>
          </div>
        </template>

        <RegisterFormStepEmail v-if="step == 'email'" :is-loading="isLoading || isEnrolling"
          @update="customer.email = $event" />
        <RegisterFormStepInfos v-else-if="step == 'infos'" ref="infosStep" :is-loading="isLoading || isEnrolling"
          :temp-customer="tempCustomer" :payment-plan="chosenPaymentPlan" @new-price="newPrice = $event"
          @update="customer.infos = $event" @paypal-order="onPayPalOrder" />
      </main>

      <div v-show="isEnrolling" class="compmodal_loading is-size-4 has-text-weight-bold">
        {{ $t('processing') }}
        <span class="loader is-primary is-size-2 mt-5" />
      </div>
    </form>
  </component>
</template>

<style lang="scss" scoped>
.compmodal {
  &_close {
    top: pxToRem(8);
    right: pxToRem(8);
  }

  &_img {
    width: 112px;
  }

  &_box {
    overflow: hidden;
  }

  &_loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($white, 0.95);
  }

  @include mobile {
    &_box {
      overflow: auto;
      min-height: 100%;
      border-radius: 0;
    }

    &_header,
    &_main {
      padding-left: pxToRem(12) !important;
      padding-right: pxToRem(12) !important;
    }

    ::v-deep .modal-content,
    ::v-deep .animation-content {
      max-height: none;
      min-height: 100vh;
      width: 100%;
    }
  }
}
</style>
