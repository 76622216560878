<script>
import TrainingItem from '@school/components/TrainingItem.vue';

export default {
  components: { TrainingItem },
  props: {
    total: {
      type: Number,
      required: true,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    hasTitle: {
      type: Boolean,
      default: true,
    },
    hasPrice: {
      type: Boolean,
      default: true,
    },
    trainings: {
      type: Array,
      required: true,
    },
  },
  i18n: {
    messages: {
      en: {
        trainings: 'Trainings',
        no_trainings_yet: 'There are no trainings yet!',
        no_trainings_match_search: 'No trainings match your search.',
      },
      fr: {
        trainings: 'Formations',
        no_trainings_yet: 'Il n\'y a pas encore de formations !',
        no_trainings_match_search: 'Aucune formation ne correspond à votre recherche.',
      },
    },
  },
};
</script>

<template>
  <section>
    <h2 v-if="hasTitle" class="title is-custom is-size-5-touch">
      {{ $t('trainings') }}
    </h2>

    <p v-if="total <= 0" class="box">
      {{ $t('no_trainings_yet') }}
    </p>
    <template v-else-if="trainings.length">
      <TrainingItem
        v-for="one in trainings"
        :key="one.uuid"
        :class="{ 'is-small': isSmall }"
        :training="one"
        :hasPrice="hasPrice"
      />
    </template>
    <p v-else class="box">
      {{ $t('no_trainings_match_search') }}
    </p>
  </section>
</template>
