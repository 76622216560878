<script>
import { inViewport } from '@shared/services/Utils';
import communityMixin, { commentItem } from '@shared/mixins/community';
import BaseAvatar from '@shared/components/BaseAvatar.vue';
import CardCommentForm from '@shared/components/Community/CardCommentForm.vue';
import CommentItemContextMenu from '@shared/components/Community/CommentItemContextMenu.vue';

export default {
  name: 'CardCommentItem',
  mixins: [communityMixin('comment'), commentItem],
  components: {
    BaseAvatar,
    CardCommentForm,
    CommentItemContextMenu,
  },
  methods: {
    answer() {
      if (this.isChild) {
        this.$parent.answer();
        return;
      }

      this.canComment = true;
      this.$nextTick(() => {
        if (!inViewport(this.$refs.form)) {
          this.$scrollTo(this.$refs.form);
        }

        this.$refs.form.querySelector('textarea')?.focus();
      });
    },
  },
  i18n: {
    messages: {
      en: {
        reply: 'Reply',
        published_on: 'Published on',
        comments: 'Comments',
      },
      fr: {
        reply: 'Répondre',
        published_on: 'Publiée le',
        comments: 'Commentaires',
      },
    },
  },
};
</script>

<template>
  <article class="media">
    <div class="media-left mr-2">
      <BaseAvatar
        :avatar="comment.owner.avatar"
        :displayName="displayName"
        :size="32"
      />
    </div>
    <div class="media-content">
      <CardCommentForm
        v-if="isEditing"
        :post="post"
        :parentUUID="parent && parent.uuid"
        :comment="comment"
        @update="isEditing = false"
        @cancel="isEditing = false"
      />
      <template v-else>
        <div class="is-inline-block has-background-light py-2 px-4 px-2-touch bradius-8">
          <p class="is-size-8 is-size-9-touch">
            <strong>
              {{ displayName }}
            </strong>
          </p>
          <div
            class="content is-small is-size-9-touch wspace-preline break-words"
            v-html="content"
          />
        </div>
        <p class="is-size-9 mt-2 mt-1-mobile">
          <b-tooltip position="is-right">
            <template #content>
              {{ $t('published_on') }} {{ comment.published_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
            </template>
            {{ comment.published_at | momentFromUTC | moment('from') }}
          </b-tooltip>
          <template v-if="!isChild">
            •
            <b-icon pack="far" icon="comment" /> {{ $t('comments') }} ({{ comment.children_count }})
          </template>
          <template v-if="canAnswer && !isStandAlone">
            •
            <a
              class="color-inherit tdecoration-underline"
              href="#"
              @click.prevent="answer"
            >
              {{ $t('reply') }}
            </a>
          </template>
        </p>
      </template>

      <template v-if="!isChild">
        <CardCommentItem
          v-for="child in children"
          :key="child.uuid"
          class="mt-3 mt-1-mobile"
          :post="post"
          :parent="comment"
          :comment="child"
        />

        <slot
          name="childrenPagination"
          :links="comment.children.links"
        />
      </template>

      <div
        v-if="$slots.default"
        v-show="canComment"
        ref="form"
        class="mt-3"
      >
        <slot />
      </div>
    </div>
    <nav class="media-right">
      <CommentItemContextMenu
        :post="post"
        :parent="parent"
        :comment="comment"
        :isChild="isChild"
        :canManageCommunityEntity="canManageCommunityEntity"
        @edit="isEditing = true"
      />
    </nav>
  </article>
</template>
