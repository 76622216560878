<script>
import debounce from 'lodash/debounce';
import APIGroup from '@shared/services/API/Community/Group';

export default {
  props: {
    group: {
      type: Object,
      default: null,
    },
  },
  i18n: {
    messages: {
      en: {
        add_member: 'Add a member',
        filter_label: 'Filter by first name, last name or email',
        no_results: 'There are no results for this search.',
        added: 'Added!',
        add: 'Add',
        previous: 'Previous',
        next: 'Next',
        close: 'Close',
      },
      fr: {
        add_member: 'Ajouter un membre',
        filter_label: 'Filtrez par prénom, nom ou email',
        no_results: 'Il n\'y a pas de résultat pour cette recherche.',
        added: 'Ajouté !',
        add: 'Ajouter',
        previous: 'Précédent',
        next: 'Suivant',
        close: 'Fermer',
      },
    },
  },
  data() {
    return {
      q: '',
      isLoading: false,
      availableMembers: {
        data: [],
      },
      newMember: {
        user: null,
        customer: null,
      },
    };
  },
  computed: {
    addMemberTitle() {
      return this.$t('add_member');
    },
    filterLabel() {
      return this.$t('filter_label');
    },
    closeButtonText() {
      return this.$t('close');
    },
  },
  methods: {
    addMember(member) {
      member.is_loading = true;
      this.$store.dispatch('community/addMember', { member })
        .then(() => {
          member.is_member = true;
          this.$showMessage.success();
          this.$emit('add', member);
        })
        .finally(() => {
          member.is_loading = false;
        });
    },
    search: debounce(function() {
      this.loadMembers();
    }, 500),
    loadMembers(url = null) {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      const q = this.q || undefined;
      APIGroup.getAvailableMembers(url || this.group.uuid, { q })
        .then(this.setMembers)
        .finally(() => {
          this.isLoading = false;
        });
    },
    setMembers(data) {
      data.data = data.data.map((v) => ({
        ...v,
        is_member: false,
        is_loading: false,
      }));

      this.availableMembers = data;
    },
  },
  created() {
    this.loadMembers();
  },
};
</script>

<template>
  <AppCardModal
    class="is-relative"
    hasCloseBtn
    @close="$emit('cancel')"
  >
    <template #title>
      {{ addMemberTitle }}
    </template>
    <template #body>
      <GoProWrapper planLock="EXPERT" :hasSep="false">
        <div class="mh-360">
          <form @submit.prevent="search">
            <b-field :label="filterLabel">
              <b-input
                v-model="q"
                expanded
                maxlength="32"
                @input="search"
              />
            </b-field>
          </form>

          <div class="is-relative pb-5">
            <ul v-if="availableMembers.data.length > 0" class="mt-5">
              <li
                v-for="member in availableMembers.data"
                :key="member.uuid"
                class="box flex-between-center"
              >
                <div>
                  <p>
                    {{ member.firstname }} {{ member.lastname }}
                  </p>
                  <p class="mt-2">
                    <span
                      class="tag"
                      :class="{
                        'is-dark': member.type === 'USER',
                        'is-primary': member.type === 'CUSTOMER',
                      }"
                      v-t="`community.member_type.${member.type}`"
                    />
                  </p>
                </div>
                <b-button
                  class="ml-5"
                  type="is-primary"
                  :disabled="member.is_member"
                  :loading="member.is_loading"
                  @click="addMember(member)"
                >
                  <template v-if="member.is_member">
                    {{ $t('added') }}
                  </template>
                  <template v-else>
                    {{ $t('add') }}
                  </template>
                </b-button>
              </li>
            </ul>
            <p v-else>
              {{ $t('no_results') }}
            </p>
            <b-loading :active="isLoading" :is-full-page="false" />
          </div>

          <div
            v-if="availableMembers.meta && availableMembers.meta.last_page > 1"
            class="buttons is-centered mt-5 pb-5"
          >
            <b-button
              :disabled="!availableMembers.links.prev"
              @click="loadMembers(availableMembers.links.prev)"
            >
              {{ $t('previous') }}
            </b-button>
            <b-button
              :disabled="!availableMembers.links.next"
              @click="loadMembers(availableMembers.links.next)"
            >
              {{ $t('next') }}
            </b-button>
          </div>
        </div>
      </GoProWrapper>
    </template>
    <template #foot>
      <b-button type="is-text" @click="$emit('cancel')">
        {{ closeButtonText }}
      </b-button>
    </template>
  </AppCardModal>
</template>
