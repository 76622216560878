import ContentBlockHTML from '@shared/components/ContentBlocks/ContentBlockHTML.vue';
import ContentBlockMarkdown from '@shared/components/ContentBlocks/ContentBlockMarkdown.vue';
import ContentBlockRichText from '@shared/components/ContentBlocks/ContentBlockRichText.vue';
import ContentBlockToggle from '@shared/components/ContentBlocks/ContentBlockToggle.vue';
import ContentBlockSeparator from '@shared/components/ContentBlocks/ContentBlockSeparator.vue';
import ContentBlockResource from '@shared/components/ContentBlocks/ContentBlockResource.vue';
import ContentBlockURL from '@shared/components/ContentBlocks/ContentBlockURL.vue';
import ContentBlockTrainingItemURL from '@shared/components/ContentBlocks/ContentBlockTrainingItemURL.vue';
import ContentBlockOEmbed from '@shared/components/ContentBlocks/ContentBlockOEmbed.vue';
import ContentBlockTiktokOEmbed from '@shared/components/ContentBlocks/ContentBlockTiktokOEmbed.vue';
import ContentBlockImage from '@shared/components/ContentBlocks/ContentBlockImage.vue';
import ContentBlockVideo from '@shared/components/ContentBlocks/ContentBlockVideo.vue';
import ContentBlockAudio from '@shared/components/ContentBlocks/ContentBlockAudio.vue';
import ContentBlockPDFViewer from '@shared/components/ContentBlocks/ContentBlockPDFViewer.vue';
import ContentBlockEmbed from '@shared/components/ContentBlocks/ContentBlockEmbed.vue';
import ContentBlockSimpleEmbed from '@shared/components/ContentBlocks/ContentBlockEmbedSimple.vue';
import ContentBlockHeading from '@shared/components/ContentBlocks/ContentBlockHeading.vue';
import ContentBlockCodeBlock from '@shared/components/ContentBlocks/ContentBlockCodeBlock.vue';
import ContentBlockNotification from '@shared/components/ContentBlocks/ContentBlockNotification.vue';

export default {
  video: {
    icon: 'file-video',
    pack: 'fas',
    text: 'content_blocks.video',
    component: ContentBlockVideo,
  },
  audio: {
    icon: 'file-audio',
    pack: 'fas',
    text: 'content_blocks.audio',
    component: ContentBlockAudio,
  },
  richtext: {
    icon: 'paragraph',
    pack: 'fas',
    text: 'content_blocks.richtext',
    component: ContentBlockRichText,
  },
  separator: {
    icon: 'minus',
    pack: 'fas',
    text: 'content_blocks.separator',
    component: ContentBlockSeparator,
  },
  image: {
    icon: 'file-image',
    pack: 'fas',
    text: 'content_blocks.image',
    component: ContentBlockImage,
  },
  heading: {
    icon: 'heading',
    pack: 'fas',
    text: 'content_blocks.heading',
    component: ContentBlockHeading,
  },
  notification: {
    icon: 'info-circle',
    pack: 'fas',
    text: 'content_blocks.notification',
    component: ContentBlockNotification,
  },
  markdown: {
    icon: 'markdown',
    pack: 'fab',
    text: 'content_blocks.markdown.title',
    notification: 'content_blocks.markdown.notification',
    notificationClass: 'is-info',
    component: ContentBlockMarkdown,
  },
  resource: {
    size: 'is-12',
    icon: 'file',
    pack: 'fas',
    text: 'content_blocks.resource',
    component: ContentBlockResource,
  },
  pdf_viewer: {
    icon: 'file-pdf',
    pack: 'fas',
    text: 'content_blocks.pdf_viewer',
    component: ContentBlockPDFViewer,
  },
  url: {
    icon: 'link',
    pack: 'fas',
    text: 'content_blocks.url',
    component: ContentBlockURL,
  },
  training_item_url: {
    icon: 'external-link-square-alt',
    pack: 'fas',
    text: 'content_blocks.training_item_url',
    component: ContentBlockTrainingItemURL,
  },
  giphy: {
    icon: 'images',
    pack: 'fas',
    text: 'content_blocks.giphy',
    component: ContentBlockEmbed,
  },
  calendly: {
    icon: 'calendar-plus',
    pack: 'fas',
    text: 'content_blocks.calendly',
    component: ContentBlockSimpleEmbed,
  },
  typeform: {
    icon: 'poll-h',
    pack: 'fas',
    text: 'content_blocks.typeform',
    component: ContentBlockSimpleEmbed,
  },
  youtube: {
    icon: 'youtube',
    pack: 'fab',
    text: 'content_blocks.youtube',
    component: ContentBlockEmbed,
  },
  youtube_live: {
    icon: 'video',
    pack: 'fas',
    text: 'content_blocks.youtube_live',
    component: ContentBlockEmbed,
  },
  vimeo: {
    icon: 'vimeo',
    pack: 'fab',
    text: 'content_blocks.vimeo',
    component: ContentBlockEmbed,
  },
  dailymotion: {
    icon: 'dailymotion',
    pack: 'fab',
    text: 'content_blocks.dailymotion',
    component: ContentBlockEmbed,
  },
  twitch: {
    icon: 'twitch',
    pack: 'fab',
    text: 'content_blocks.twitch',
    component: ContentBlockEmbed,
  },
  soundcloud: {
    icon: 'soundcloud',
    pack: 'fab',
    text: 'content_blocks.soundcloud',
    component: ContentBlockEmbed,
  },
  gdoc: {
    icon: 'file-word',
    pack: 'fas',
    text: 'content_blocks.gdoc',
    component: ContentBlockEmbed,
  },
  gsheet: {
    icon: 'file-excel',
    pack: 'fas',
    text: 'content_blocks.gsheet',
    component: ContentBlockEmbed,
  },
  gform: {
    icon: 'poll',
    pack: 'fas',
    text: 'content_blocks.gform.title',
    notification: 'content_blocks.gform.notification',
    notificationClass: 'is-info',
    component: ContentBlockHTML,
  },
  gcalendar: {
    icon: 'calendar-alt',
    pack: 'fas',
    text: 'content_blocks.gcalendar',
    component: ContentBlockEmbed,
  },
  gslide: {
    icon: 'file-powerpoint',
    pack: 'fas',
    text: 'content_blocks.gslide',
    component: ContentBlockEmbed,
  },
  html: {
    icon: 'code',
    pack: 'fas',
    text: 'content_blocks.html.title',
    notification: 'content_blocks.html.notification',
    notificationClass: 'is-info',
    component: ContentBlockHTML,
  },
  genially: {
    icon: 'magic',
    pack: 'fas',
    text: 'content_blocks.genially.title',
    notification: 'content_blocks.genially.notification',
    notificationClass: 'is-info',
    component: ContentBlockHTML,
  },
  toggle: {
    icon: 'chevron-right',
    pack: 'fas',
    text: 'content_blocks.toggle',
    component: ContentBlockToggle,
  },
  slideshare: {
    icon: 'slideshare',
    pack: 'fab',
    text: 'content_blocks.slideshare',
    component: ContentBlockOEmbed,
  },
  instagram: {
    icon: 'instagram',
    pack: 'fab',
    text: 'content_blocks.instagram',
    component: () => import('@shared/components/ContentBlocks/ContentBlockEmbedo.vue'),
  },
  twitter: {
    icon: 'twitter',
    pack: 'fab',
    text: 'content_blocks.twitter',
    component: () => import('@shared/components/ContentBlocks/ContentBlockEmbedo.vue'),
  },
  pinterest: {
    icon: 'pinterest',
    pack: 'fab',
    text: 'content_blocks.pinterest',
    component: () => import('@shared/components/ContentBlocks/ContentBlockEmbedo.vue'),
  },
  figma: {
    icon: 'figma',
    pack: 'fab',
    text: 'content_blocks.figma',
    component: ContentBlockEmbed,
  },
  github: {
    icon: 'github',
    pack: 'fab',
    text: 'content_blocks.github',
    component: ContentBlockOEmbed,
  },
  codeblock: {
    icon: 'file-code',
    pack: 'fas',
    text: 'content_blocks.codeblock',
    component: ContentBlockCodeBlock,
  },
  jsfiddle: {
    icon: 'jsfiddle',
    pack: 'fab',
    text: 'content_blocks.jsfiddle',
    component: ContentBlockEmbed,
  },
  codepen: {
    icon: 'codepen',
    pack: 'fab',
    text: 'content_blocks.codepen',
    component: ContentBlockEmbed,
  },
  codesandbox: {
    icon: 'cube',
    pack: 'fas',
    text: 'content_blocks.codesandbox',
    component: ContentBlockEmbed,
  },
  chatgpt: {
    icon: 'comments',
    pack: 'fas',
    text: 'content_blocks.chatgpt',
    component: ContentBlockEmbed,
  },
  tiktok: {
    icon: 'tiktok',
    pack: 'fab',
    text: 'content_blocks.tiktok',
    component: ContentBlockTiktokOEmbed,
  },
};
