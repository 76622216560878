import _get from 'lodash/get';

export default {
  methods: {
    handleAuthError(error, api, email, redirect = () => {}) {
      console.log('here');
      const verificationMessage = `
        <div class="content">
          <p>${this.$t('auth.verification.message')}</p>
          <p>
            <strong>${this.$t('auth.verification.resend_info')}</strong>
          </p>
        </div>
      `;

      const sentVerificationMessage = `
        <div class="content">
          <p>${this.$t('auth.verification.sent_message')}</p>
          <p>${this.$t('auth.verification.new_link_notice')}</p>
        </div>
      `;

      if (_get(error, 'response.data.code') === 'EMAIL_NOT_VERIFIED') {
        this.$buefy.dialog.confirm({
          title: this.$t('auth.verification.title'),
          message: verificationMessage,
          size: 'is-small',
          confirmText: this.$t('shared_messages.actions.resend'),
          cancelText: this.$t('shared_messages.back'),
          focusOn: 'cancel',
          closeOnConfirm: false,
          onConfirm: (_, modal) => {
            const loader = this.$buefy.loading.open();
            api.sendVerificationEmail(email)
              .then(() => {
                this.$buefy.dialog.alert({
                  title: this.$t('auth.verification.sent_title'),
                  message: sentVerificationMessage,
                  size: 'is-small',
                });
                modal.close();
              })
              .finally(() => loader.close());
          },
        });
        redirect();
      } else {
        this.$errorHandlers.axios(error);
      }
    },
  },
};
