<script>
import { storeForms } from '@app/mixins/forms';
import TiptapEditor from '@shared/components/TiptapEditor.vue';

export default {
  mixins: [storeForms],
  components: {
    TiptapEditor,
  },
  data() {
    return {
      store: {
        community_options: {
          description: '',
        },
      },
    };
  },
  computed: {
    descriptionLabel() {
      return this.$t('community_description_form.description_label');
    },
    updateButtonLabel() {
      return this.$t('community_description_form.update_button');
    },
  },
  methods: {
    onSave() {
      this.handle(() => this.$emit('done'));
    },
  },
  i18n: {
    messages: {
      en: {
        community_description_form: {
          description_label: 'Write a presentation of your community here. This page will be the first one your learners will see when arriving on the community. Use this text to welcome your learners, announce your news...',
          update_button: 'Update',
        },
      },
      fr: {
        community_description_form: {
          description_label: 'Rédigez ici une présentation de votre communauté. Cette page sera la première que vos apprenants verront en arrivant sur la communauté. Profitez de ce texte pour accueillir vos apprenants, annoncer vos nouveautés...',
          update_button: 'Mettre à jour',
        },
      },
    },
  },
};
</script>

<template>
  <form @submit.prevent="dataIsValid(isLoading) && onSave()">
    <b-field :label="descriptionLabel">
      <TiptapEditor
        :value="store.community_options.description"
        @input="store.community_options.description = $event"
      />
    </b-field>
    <div class="field">
      <b-button type="is-primary" native-type="submit">
        {{ updateButtonLabel }}
      </b-button>
    </div>
  </form>
</template>
