var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mw-full is-inline-block px-1 pb-2 has-text-centered",style:({
    width: _vm.params['style[width]'],
    maxWidth: '100%',
  })},[_c('b-button',{style:({
      height: 'auto',
      minHeight: _vm.params['style[height]'] || 'auto',
      whiteSpace: 'initial',
      borderRadius: _vm.params['style[borderRadius]']
    }),attrs:{"type":"is-primary is-custom","expanded":""},on:{"click":_vm.buy}},[_vm._v(" "+_vm._s(_vm.text)+" "),(_vm.params.showPrice)?[(_vm.finalPrice > 0)?[_vm._v(" ("+_vm._s(_vm._f("formatPrice")(_vm.finalPrice))+") ")]:[_vm._v(" "+_vm._s(_vm.$t('embed_button_free'))+" ")]]:_vm._e()],2),(_vm.params.showPrice && _vm.buyable.discount_price != null)?_c('p',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$t('embed_button_instead_of'))+" "),_c('span',{staticClass:"tdecoration-line-through"},[(_vm.buyable.price > 0)?[_vm._v(" "+_vm._s(_vm._f("formatPrice")(_vm.buyable.price))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('embed_button_free'))+" ")]],2)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }