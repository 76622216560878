<script>
import utilsMixin from '@shared/mixins/utils';
import communityMixin, { postItem } from '@shared/mixins/community';
import BaseAvatar from '@shared/components/BaseAvatar.vue';
import PostItemContextMenu from '@shared/components/Community/PostItemContextMenu.vue';
import CommentsListWrap from '@shared/components/Community/CommentsListWrap.vue';
import UploadedMediaCard from '@shared/components/UploadedMediaCard.vue';

export default {
  mixins: [utilsMixin, communityMixin('post'), postItem],
  components: {
    BaseAvatar,
    PostItemContextMenu,
    CommentsListWrap,
    UploadedMediaCard,
  },
  computed: {
    commentsLinkText() {
      return this.$t('comments_link', { count: this.post.comments_count });
    },
  },
  i18n: {
    messages: {
      en: {
        published_at: 'Published on ',
        comments_link: 'Replies ({count})',
        scheduled: 'Scheduled',
        pinned: 'Pinned',
      },
      fr: {
        published_at: 'Publiée le ',
        comments_link: 'Réponses ({count})',
        scheduled: 'Programmée',
        pinned: 'Épinglée',
      },
    },
  },
};
</script>

<template>
  <article class="card bradius-0-mobile">
    <div class="card-content p-3">
      <div class="media">
        <div class="media-left mr-2">
          <BaseAvatar
            :avatar="post.owner.avatar"
            :displayName="displayName"
          />
        </div>
        <div class="media-content">
          <p class="has-text-weight-bold is-size-6 is-size-8-mobile">
            {{ displayName }}
            <span v-if="isStoreAdmin" class="has-text-dark is-size-8">
              ({{ post.owner.firstname }} {{ post.owner.lastname }})
            </span>
          </p>
          <p class="subtitle is-9">
            <router-link
              class="has-text-dark"
              :to="{
                name: 'community_group_post',
                params: {postUUID: post.uuid},
              }"
            >
              <b-tooltip position="is-right">
                <template #content>
                  {{ $t('published_at') }} {{ post.published_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
                </template>
                {{ post.published_at | momentFromUTC | moment('from') }}
              </b-tooltip>
            </router-link>
            •
            <a
              class="color-inherit tdecoration-underline"
              href="#"
              @click.prevent="$scrollTo($refs.comments.$el)"
            >
              {{ commentsLinkText }}
            </a>
          </p>
        </div>
        <nav class="media-right">
          <span
            v-if="isScheduled"
            class="tag is-black is-size-9-mobile mr-5"
          >
            {{ $t('scheduled') }}
            <b-icon class="ml-2" icon="clock" />
          </span>
          <span
            v-if="post.pinned"
            class="tag is-black is-size-9-mobile mr-5"
          >
            {{ $t('pinned') }}
            <b-icon class="ml-2" icon="thumbtack" />
          </span>
          <PostItemContextMenu
            :canManageCommunityEntity="canManageCommunityEntity"
            :post="post"
          />
        </nav>
      </div>
      <div class="content break-words" v-html="content" />
      <UploadedMediaCard v-if="post.media.url" :media="post.media" />
    </div>
    <CommentsListWrap ref="comments" :post="post" />
  </article>
</template>
