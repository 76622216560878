<script>
export default {
  i18n: {
    messages: {
      fr: {
        notifications_by_email: 'Notifications par email',
        notification_options_description: 'Ces options nous permettent de vous avertir par email quand :',
        notification_comment_reply: 'Quelqu\'un a répondu à un de vos commentaires',
        notification_password_changed: 'Votre mot de passe a été changé',
        notification_and_more: 'Et plus encore...',
        all_notifications: 'Toutes les notifications',
        enable_disable_specific_notifications: 'Activez/Désactivez des notifications spécifiques',
        new_comment: 'Nouveau commentaire de leçon',
        password_changed: 'Votre mot de passe a été changé',
        training_item_unblocked: 'Nouvelle(s) leçon(s) disponible(s)',
        weekly_recap: 'Récapitulatif hebdomadaire de votre progression',
      },
      en: {
        notifications_by_email: 'Email Notifications',
        notification_options_description: 'These options allow us to notify you by email when:',
        notification_comment_reply: 'Someone has replied to one of your comments',
        notification_password_changed: 'Your password has been changed',
        notification_and_more: 'And more...',
        all_notifications: 'All notifications',
        enable_disable_specific_notifications: 'Enable/Disable specific notifications',
        new_comment: 'New comment in a lesson',
        password_changed: 'Your password changed',
        training_item_unblocked: 'New available lesson(s)',
        weekly_recap: 'Your weekly progress recap',
      },
    },
  },
  data() {
    return {
      isLoading: false,
      accept_email_notifications: true,
      email_settings: {},
    };
  },
  computed: {
    store() {
      return this.$store.state.store.data;
    },
    authCustomer() {
      return this.$store.getters['auth/customer'];
    },
  },
  methods: {
    handleAllEmailNotifs() {
      this.$store.dispatch('auth/update', {
        accept_email_notifications: this.accept_email_notifications,
      })
        .then(() => this.$showMessage.success())
        .catch((error) => {
          this.accept_email_notifications = !this.accept_email_notifications;
          this.$errorHandlers.axios(error);
        });
    },
    handleEmailNotifSettings(notifType) {
      const email_settings = { ...this.email_settings };
      this.$store.dispatch('auth/update', { email_settings })
        .then(() => this.$showMessage.success())
        .catch((error) => {
          this.email_settings[notifType] = !this.email_settings[notifType];
          this.$errorHandlers.axios(error);
        });
    },
  },
  created() {
    this.accept_email_notifications = this.authCustomer.accept_email_notifications;
    this.email_settings = { ...this.authCustomer.email_settings };
  },
};
</script>

<template>
  <div class="box content is-size-8-mobile">
    <h1 class="is-size-5-mobile">
      {{ $t('notifications_by_email') }}
    </h1>
    <p>{{ $t('notification_options_description') }}</p>
    <ul>
      <li>{{ $t('notification_comment_reply') }}</li>
      <li>{{ $t('notification_password_changed') }}</li>
      <li>{{ $t('notification_and_more') }}</li>
    </ul>
    <p>
      <b-switch
        v-model="accept_email_notifications"
        @input="handleAllEmailNotifs()"
      >
        {{ $t('all_notifications') }}
      </b-switch>
    </p>
    <template v-if="accept_email_notifications">
      <h2 class="is-size-4 is-size-7-mobile">
        {{ $t('enable_disable_specific_notifications') }}
      </h2>
      <p v-for="notifType in ['new_comment', 'password_changed', 'training_item_unblocked', 'weekly_recap']" :key="notifType">
        <b-switch
          v-model="email_settings[notifType]"
          @input="handleEmailNotifSettings(notifType)"
        >
          {{ $t(notifType) }}
        </b-switch>
      </p>
    </template>
  </div>
</template>
