<script>
export default {
  head() {
    return {
      title: this.$t('pages.onboarding.title'),
    };
  },
  i18n: {
    messages: {
      fr: {
        pages: {
          onboarding: {
            title: 'Lancer ma communauté',
            welcome: {
              title: 'Bienvenue dans votre communauté !',
              subtitle: 'Pour permettre à vos apprenants de trouver un appui, pour enseigner autrement, ou pour mettre en place des mécaniques de jeux... Il y a 1000 bonnes raisons de créer une communauté autour de vos formations !',
            },
            steps: {
              step1: {
                title: 'Étape 1',
                description: 'Complétez la page d\'accueil de votre communauté',
                link: 'Voir plus >',
              },
              step2: {
                title: 'Étape 2',
                description: 'Créez vos premiers Espaces & Collections',
                link: 'Voir plus >',
              },
              step3: {
                title: 'Étape 3',
                description: 'Invitez vos apprenants à rejoindre vos Espaces',
                link: 'Voir plus >',
              },
            },
            additional: {
              title: 'Pour aller plus loin...  📚',
              sections: {
                setup: {
                  title: '👉 Paramétrer ma communauté Teachizy',
                  links: {
                    collections: {
                      text: 'Comment puis-je organiser ma communauté en créant des collections ?',
                      cta: 'Consulter >',
                    },
                    launch: {
                      text: 'Comment lancer efficacement ma communauté auprès de mes apprenants ?',
                      cta: 'Consulter >',
                    },
                    rights: {
                      text: 'Quel sonts les droits des différents membres de mon équipe sur la communauté ?',
                      cta: 'Consulter >',
                    },
                  },
                },
                spaces: {
                  title: '👉 Gérer mes espaces et collections',
                  links: {
                    customize: {
                      text: 'Comment personnaliser un espace ?',
                      cta: 'Consulter >',
                    },
                    modify: {
                      text: 'Comment modifier un espace ?',
                      cta: 'Consulter >',
                    },
                    status: {
                      text: 'Dans quel statut puis-je mettre mon espace ?',
                      cta: 'Consulter >',
                    },
                  },
                },
                moderation: {
                  title: '👉 Animer et modérer ma communauté',
                  links: {
                    posts: {
                      text: 'Comment créer, épingler et modifier une publication ?',
                      cta: 'Consulter >',
                    },
                    content: {
                      text: 'À quel rythme publier dans mes espaces et quels types de contenus ?',
                      cta: 'Consulter >',
                    },
                    moderate: {
                      text: 'Comment puis je modérer les contributions de mes membres ?',
                      cta: 'Consulter >',
                    },
                  },
                },
                members: {
                  title: '👉 Gérer les membres de ma communauté',
                  links: {
                    invite: {
                      text: 'Comment inviter un.e membre à un rejoindre un espace ?',
                      cta: 'Consulter >',
                    },
                    profile: {
                      text: 'Comment un.e membre peut-il modifier son pseudonyme et sa photo ?',
                      cta: 'Consulter >',
                    },
                    interactions: {
                      text: 'Comment générer des interactions sur ma communauté ?',
                      cta: 'Consulter >',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};
</script>

<template>
  <div>
    <section
      class="
        box is-primary has-background-primary
        has-text-centered has-text-white p-10
      "
    >
      <h1 class="title has-text-white mb-10">
        {{ $t('pages.onboarding.welcome.title') }}
      </h1>
      <p class="subtitle has-text-white">
        {{ $t('pages.onboarding.welcome.subtitle') }}<br>
        Suivez le guide 👇
      </p>
    </section>

    <section class="box p-0 is-clipped">
      <div class="image is-16by9">
        <iframe class="pabsolute-0 w-full h-full" src="https://www.youtube.com/embed/s_D1Svbxk3Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
      </div>
    </section>

    <section class="columns">
      <article class="column is-4">
        <div class="box content">
          <h2>{{ $t('pages.onboarding.steps.step1.title') }}</h2>
          <p>
            {{ $t('pages.onboarding.steps.step1.description') }}
          </p>
          <a href="https://aide.teachizy.fr/fr/article/comment-personnaliser-le-texte-daccueil-de-la-communaute-6kjsmr" target="_blank">{{ $t('pages.onboarding.steps.step1.link') }}</a>
        </div>
      </article>
      <article class="column is-4">
        <div class="box content">
          <h2>{{ $t('pages.onboarding.steps.step2.title') }}</h2>
          <p>
            {{ $t('pages.onboarding.steps.step2.description') }}
          </p>
          <a href="https://aide.teachizy.fr/fr/article/comment-creer-et-parametrer-un-espace-fkjni1" target="_blank">{{ $t('pages.onboarding.steps.step2.link') }}</a>
        </div>
      </article>
      <article class="column is-4">
        <div class="box content">
          <h2>{{ $t('pages.onboarding.steps.step3.title') }}</h2>
          <p>
            {{ $t('pages.onboarding.steps.step3.description') }}
          </p>
          <a href="https://aide.teachizy.fr/fr/article/comment-inviter-un-apprenant-a-rejoindre-ma-communaute-14mxk58" target="_blank">{{ $t('pages.onboarding.steps.step3.link') }}</a>
        </div>
      </article>
    </section>
    <section class="box content">
      <h2 class="has-text-success has-text-centered mt-4 mb-10">
        {{ $t('pages.onboarding.additional.title') }}
      </h2>
      <p class="has-text-weight-bold has-text-centered mt-10 mb-4">
        {{ $t('pages.onboarding.additional.sections.setup.title') }}
      </p>
      <ul>
        <li>{{ $t('pages.onboarding.additional.sections.setup.links.collections.text') }} <a href="https://aide.teachizy.fr/fr/article/comment-puis-je-organiser-ma-communaute-en-creant-des-collections-1bi6x51/" target="_blank">{{ $t('pages.onboarding.additional.sections.setup.links.collections.cta') }}</a></li>
        <li>{{ $t('pages.onboarding.additional.sections.setup.links.launch.text') }} <a href="https://www.teachizy.fr/blog/comment-lancer-efficacement-ma-communaute-aupres-de-mes-apprenants" target="_blank">{{ $t('pages.onboarding.additional.sections.setup.links.launch.cta') }}</a></li>
        <li>{{ $t('pages.onboarding.additional.sections.setup.links.rights.text') }} <a href="https://aide.teachizy.fr/fr/article/quel-sonts-les-droits-des-differents-membre-de-mon-equipe-sur-la-communaute-8ypv6" target="_blank">{{ $t('pages.onboarding.additional.sections.setup.links.rights.cta') }}</a></li>
      </ul>
      <p class="has-text-weight-bold has-text-centered mt-10 mb-4">
        {{ $t('pages.onboarding.additional.sections.spaces.title') }}
      </p>
      <ul>
        <li>{{ $t('pages.onboarding.additional.sections.spaces.links.customize.text') }} <a href="https://aide.teachizy.fr/fr/article/comment-puis-je-personnaliser-lapparence-de-ma-communaute-nl9l1e" target="_blank">{{ $t('pages.onboarding.additional.sections.spaces.links.customize.cta') }}</a></li>
        <li>{{ $t('pages.onboarding.additional.sections.spaces.links.modify.text') }} <a href="https://aide.teachizy.fr/fr/article/comment-modifier-un-espace-wksh0w" target="_blank">{{ $t('pages.onboarding.additional.sections.spaces.links.modify.cta') }}</a></li>
        <li>{{ $t('pages.onboarding.additional.sections.spaces.links.status.text') }} <a href="https://aide.teachizy.fr/fr/article/dans-quel-statut-puis-je-mettre-mon-espace-fqng96" target="_blank">{{ $t('pages.onboarding.additional.sections.spaces.links.status.cta') }}</a></li>
      </ul>
      <p class="has-text-weight-bold has-text-centered mt-10 mb-4">
        {{ $t('pages.onboarding.additional.sections.moderation.title') }}
      </p>
      <ul>
        <li>{{ $t('pages.onboarding.additional.sections.moderation.links.posts.text') }} <a href="https://aide.teachizy.fr/fr/article/comment-creer-epingler-et-modifier-une-publication-xyyhia" target="_blank">{{ $t('pages.onboarding.additional.sections.moderation.links.posts.cta') }}</a></li>
        <li>{{ $t('pages.onboarding.additional.sections.moderation.links.content.text') }} <a href="https://www.teachizy.fr/blog/a-quel-rythme-et-quoi-publier-dans-ma-communaute" target="_blank">{{ $t('pages.onboarding.additional.sections.moderation.links.content.cta') }}</a></li>
        <li>{{ $t('pages.onboarding.additional.sections.moderation.links.moderate.text') }} <a href="https://aide.teachizy.fr/fr/article/comment-puis-je-moderer-les-contributions-de-mes-membres-190auid" target="_blank">{{ $t('pages.onboarding.additional.sections.moderation.links.moderate.cta') }}</a></li>
      </ul>
      <p class="has-text-weight-bold has-text-centered mt-10 mb-4">
        {{ $t('pages.onboarding.additional.sections.members.title') }}
      </p>
      <ul>
        <li>{{ $t('pages.onboarding.itional.sections.members.links.invite.text') }} <a href="https://aide.teachizy.fr/fr/article/comment-inviter-un-apprenant-a-rejoindre-ma-communaute-14mxk58" target="_blank">{{ $t('pages.onboarding.additional.sections.members.links.invite.cta') }}</a></li>
        <li>{{ $t('pages.onboarding.additional.sections.members.links.profile.text') }} <a href="https://aide.teachizy.fr/fr/article/comment-une-membre-peut-il-modifier-son-pseudonyme-et-sa-photo-ogx5r9" target="_blank">{{ $t('pages.onboarding.additional.sections.members.links.profile.cta') }}</a></li>
        <li>{{ $t('pages.onboarding.additional.sections.members.links.interactions.text') }} <a href="https://www.teachizy.fr/blog/comment-generer-des-interactions-sur-ma-communaute" target="_blank">{{ $t('pages.onboarding.additional.sections.members.links.interactions.cta') }}</a></li>
      </ul>
    </section>
  </div>
</template>
