<script>
import ContentBlockMixin from '@shared/mixins/ContentBlock';
import ContentBlockNotificationView from '@shared/components/ContentBlocks/Views/Notification.vue';

export default {
  mixins: [ContentBlockMixin],
  components: { ContentBlockNotificationView },
  static: {
    data: { title: '', text: '', type: 'is-info' },
  },
  data: () => ({ isForm: true }),
  computed: {
    typeLabel() {
      return this.$t('content_block_notification.type_label');
    },
    titleLabel() {
      return this.$t('content_block_notification.title_label');
    },
    contentLabel() {
      return this.$t('content_block_notification.content_label');
    },
  },
  i18n: {
    messages: {
      en: {
        content_block_notification: {
          type_label: 'Information type',
          title_label: 'Information title (optional)',
          content_label: 'Content',
          type_options: {
            informative: 'Informative',
            success: 'Success',
            warning: 'Warning',
            error: 'Error',
          },
        },
      },
      fr: {
        content_block_notification: {
          type_label: "Type d'information",
          title_label: "Intitulé de l'information (facultatif)",
          content_label: 'Contenu',
          type_options: {
            informative: 'Informatif',
            success: 'Succès',
            warning: 'Avertissement',
            error: 'Erreur',
          },
        },
      },
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <b-field :label="typeLabel">
        <b-select v-model="newData.type" expanded>
          <option value="is-info">{{ $t('content_block_notification.type_options.informative') }}</option>
          <option value="is-success">{{ $t('content_block_notification.type_options.success') }}</option>
          <option value="is-warning">{{ $t('content_block_notification.type_options.warning') }}</option>
          <option value="is-danger">{{ $t('content_block_notification.type_options.error') }}</option>
        </b-select>
      </b-field>
      <b-field :label="titleLabel">
        <b-input v-model="newData.title" />
      </b-field>
      <b-field :label="contentLabel">
        <b-input v-model="newData.text" type="textarea" required />
      </b-field>
    </template>
    <template #view>
      <ContentBlockNotificationView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
