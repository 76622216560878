<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
  i18n: {
    messages: {
      fr: {
        forgot_password_title: 'Mot de passe oublié',
        forgot_password_success_message: 'Votre demande a bien été prise en compte, veuillez vérifier votre boîte mail. Pensez à vérifier vos spams si vous ne trouvez pas le mail.',
        forgot_password_error_message: 'Nous n\'avons pas trouvé de compte correspondant.',
        email_address: 'Adresse e-mail',
        send_button: 'Envoyer',
        login_button: 'Se connecter',
      },
      en: {
        forgot_password_title: 'Forgot your password',
        forgot_password_success_message: 'Your request has been received, please check your email inbox. Remember to check your spam folder if you can\'t find the email.',
        forgot_password_error_message: 'We couldn\'t find a matching account.',
        email_address: 'Email address',
        send_button: 'Send',
        login_button: 'Log in',
      },
    },
  },
  data() {
    return {
      email: '',
      isLoading: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    handle() {
      this.isLoading = true;
      this.$store.dispatch('auth/askNewPassword', this.email)
        .then(() => {
          this.$buefy.dialog.alert({
            message: this.$t('forgot_password_success_message'),
            onConfirm: () => this.$router.push({ query: {} }),
          });
        })
        .catch(() => this.$buefy.dialog.alert({
          type: 'is-danger',
          message: this.$t('forgot_password_error_message'),
        }))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <section class="box form">
    <form class="p-5" novalidate @submit.prevent="dataIsValid(isLoading) && handle()">
      <h1 class="title is-custom is-4">
        {{ $t('forgot_password_title') }}
      </h1>

      <b-field
        :label="$t('email_address')"
        v-bind="$getErrorProps($v.email, ['required', 'email'])"
      >
        <b-input v-model="email" type="email" />
      </b-field>

      <b-field>
        <b-button type="is-primary is-custom" native-type="submit" :loading="isLoading" expanded>
          {{ $t('send_button') }}
        </b-button>
      </b-field>

      <b-field class="has-text-centered">
        <b-button type="is-text is-custom" @click="$emit('switch')">
          {{ $t('login_button') }}
        </b-button>
      </b-field>
    </form>
  </section>
</template>
