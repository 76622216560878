var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.isFetching)?_c('b-skeleton',{attrs:{"height":"400"}}):_c('b-table',{staticClass:"box",attrs:{"loading":_vm.isLoading,"data":_vm.sales.data,"per-page":_vm.sales.meta.per_page,"total":_vm.sales.meta.total,"backend-pagination":"","paginated":""},on:{"page-change":function($event){return _vm.getSales($event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('affiliate_no_sales'))+" ")]},proxy:true}])},[_c('b-table-column',{attrs:{"field":"training.name","label":_vm.$t('affiliate_product')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.training.name)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"created_at","label":_vm.$t('affiliate_purchased'),"width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('p',[_c('b-tooltip',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("momentFromUTC")(row.created_at),'DD/MM/YY [à] HH:mm'))+" ")]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("momentFromUTC")(row.created_at),'from'))+" ")])],1),(row.transaction_type === 'MULTIPLE')?_c('p',{staticClass:"mt-2 has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('affiliate_paid_multiple_times'))+" ")]):_vm._e()])]}}])}),_c('b-table-column',{attrs:{"field":"transaction_amount","label":_vm.$t('affiliate_price'),"numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(row.transaction_amount))+" ")]}}])}),_c('b-table-column',{attrs:{"field":"affiliation_amount","label":_vm.$t('affiliate_commission'),"numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('strong',{staticClass:"has-text-success"},[_vm._v(" "+_vm._s(_vm._f("formatPrice")(row.affiliation_amount))+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }