<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import {
  Placeholder,
  Heading,
  Blockquote,
  HardBreak,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Table,
  TableHeader,
  TableCell,
  TableRow,
} from 'tiptap-extensions';
import TiptapLinkForm from '@shared/components/TiptapLinkFormModal.vue';
import EmojiBox from '@shared/components/EmojiBox.vue';

const levels = [2, 3, 4, 5, 6];

export default {
  components: {
    EditorContent,
    EditorMenuBar,
    EmojiBox,
  },
  i18n: {
    messages: {
      en: {
        link: {
          select_text: 'Select text to add a link',
          edit: 'Edit link',
          add: 'Add link',
        },
        heading_level: 'Heading level {level}',
        bold: 'Bold',
        italic: 'Italic',
        strike: 'Strike',
        underline: 'Underline',
        code: 'Code',
        paragraph: 'Paragraph',
        bullet_list: 'Bullet list',
        ordered_list: 'Ordered list',
        emoji: 'Emoji',
        blockquote: 'Quote',
        horizontal_rule: 'Separator',
        table: 'Table',
        table_actions: {
          delete: 'Delete table',
          insert_column_before: 'Insert column before',
          insert_column_after: 'Insert column after',
          delete_column: 'Delete column',
          insert_row_before: 'Insert row before',
          insert_row_after: 'Insert row after',
          delete_row: 'Delete row',
          merge_cells: 'Merge cells',
        },
        undo: 'Undo',
        redo: 'Redo',
      },
      fr: {
        link: {
          select_text: 'Sélectionnez du texte pour ajouter un lien',
          edit: 'Modifier le lien',
          add: 'Ajouter un lien',
        },
        heading_level: 'Titre de niveau {level}',
        bold: 'Gras',
        italic: 'Italique',
        strike: 'Barrer',
        underline: 'Souligner',
        code: 'Code',
        paragraph: 'Paragraphe',
        bullet_list: 'Liste à puces',
        ordered_list: 'Liste ordonnée',
        emoji: 'Emoji',
        blockquote: 'Citation',
        horizontal_rule: 'Séparateur',
        table: 'Tableau',
        table_actions: {
          delete: 'Supprimer tableau',
          insert_column_before: 'Insérer colonne à gauche',
          insert_column_after: 'Insérer colonne à droite',
          delete_column: 'Supprimer colonne',
          insert_row_before: 'Insérer ligne au-dessus',
          insert_row_after: 'Insérer ligne en-dessous',
          delete_row: 'Supprimer ligne',
          merge_cells: 'Fusionner cellule',
        },
        undo: 'Annuler',
        redo: 'Rétablir',
      },
    },
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 256,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    hidden: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      levels,
      emojiDropdownPosition: 'is-bottom-left',
      link: {
        url: null,
        menuIsActive: false,
      },
      editor: new Editor({
        autoFocus: this.autoFocus,
        extensions: [
          new Placeholder({
            emptyEditorClass: 'is-editor-empty',
            emptyNodeClass: 'is-empty',
            emptyNodeText: this.placeholder,
            showOnlyWhenEditable: true,
            showOnlyCurrent: true,
          }),
          new Heading({ levels }),
          new Blockquote(),
          new BulletList(),
          new HardBreak(),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new Link({ target: '_blank', openOnClick: false }),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Table({ resizable: true }),
          new TableHeader(),
          new TableCell(),
          new TableRow(),
        ],
        content: this.value,
        onUpdate: ({ getHTML }) => this.$emit('input', getHTML().replace('<p></p>', '')),
      }),
    };
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    setValue(val) {
      this.editor.setContent(val);
    },
    openLinkMenu(attrs, { isActive, commands }) {
      if (!isActive.link() && this.editor.state.selection.empty) {
        this.$buefy.dialog.alert(this.$t('link.select_text'));
        return;
      }

      const modal = this.$buefy.modal.open({
        parent: this,
        width: 480,
        props: { href: attrs.href },
        events: {
          save(href) {
            console.log(commands.link, href);
            commands.link({ href });
            modal.close();
          },
        },
        component: TiptapLinkForm,
      });
    },
    addEmoji(value) {
      const transaction = this.editor.state.tr.insertText(` ${value}`);
      this.editor.view.dispatch(transaction);
      this.$refs.emojiDropdown.toggle();
      this.editor.focus();
    },
    onEmojiDropdownToggle(isActive) {
      if (isActive) {
        this.emojiDropdownPosition = (
          (
              this.$refs.emojiDropdown?.$el.getBoundingClientRect().right
              + 280
          ) > window.innerWidth
        ) ? 'is-bottom-left' : 'is-bottom-right';
      }
    },
    onClick() {
      this.editor.focus();
    },
  },
  watch: {
    placeholder(val) {
      this.editor.extensions.options.placeholder.emptyNodeText = val;
    },
  },
};
</script>

<template>
  <div class="editor has-background-white">
    <editor-menu-bar class="editor_menu" :editor="editor">
      <template #default="{ commands, isActive, getMarkAttrs }">
        <div class="buttons mb-3 has-background-white p-2">
          <template v-for="level in levels">
            <b-button
              v-if="!hidden.includes(`h${level}`)"
              :key="level"
              type="is-white"
              size="is-small"
              class="mr-1 has-text-weight-bold is-size-9-mobile"
              :class="{ 'is-active': isActive.heading({ level }) }"
              :title="$t('heading_level', { level })"
              :label="`H${level}`"
              @click="commands.heading({ level })"
            />
          </template>
          <b-button
            v-if="!hidden.includes('bold')"
            type="is-white"
            size="is-small"
            class="mr-1 is-size-9-mobile"
            :class="{ 'is-active': isActive.bold() }"
            :title="$t('bold')"
            icon-left="bold"
            @click="commands.bold"
          />
          <b-button
            v-if="!hidden.includes('italic')"
            type="is-white"
            size="is-small"
            class="mr-1 is-size-9-mobile"
            :class="{ 'is-active': isActive.italic() }"
            :title="$t('italic')"
            icon-left="italic"
            @click="commands.italic"
          />
          <b-button
            v-if="!hidden.includes('strike')"
            type="is-white"
            size="is-small"
            class="mr-1 is-size-9-mobile"
            :class="{ 'is-active': isActive.strike() }"
            :title="$t('strike')"
            icon-left="strikethrough"
            @click="commands.strike"
          />
          <b-button
            v-if="!hidden.includes('underline')"
            type="is-white"
            size="is-small"
            class="mr-1 is-size-9-mobile"
            :class="{ 'is-active': isActive.underline() }"
            :title="$t('underline')"
            icon-left="underline"
            @click="commands.underline"
          />
          <b-button
            v-if="!hidden.includes('link')"
            type="is-white"
            size="is-small"
            class="mr-1 is-size-9-mobile"
            :class="{ 'is-active': isActive.link() }"
            :title="isActive.link() ? $t('link.edit') : $t('link.add')"
            icon-left="link"
            @click="openLinkMenu(getMarkAttrs('link'), { isActive, commands })"
          />
          <b-button
            v-if="!hidden.includes('code')"
            type="is-white"
            size="is-small"
            class="mr-1 is-size-9-mobile"
            :class="{ 'is-active': isActive.code() }"
            :title="$t('code')"
            icon-left="code"
            @click="commands.code"
          />
          <b-button
            v-if="!hidden.includes('paragraph')"
            type="is-white"
            size="is-small"
            class="mr-1 is-size-9-mobile"
            :class="{ 'is-active': isActive.paragraph() }"
            :title="$t('paragraph')"
            icon-left="paragraph"
            @click="commands.paragraph"
          />
          <b-button
            v-if="!hidden.includes('bullet_list')"
            type="is-white"
            size="is-small"
            class="mr-1 is-size-9-mobile"
            :class="{ 'is-active': isActive.bullet_list() }"
            :title="$t('bullet_list')"
            icon-left="list-ul"
            @click="commands.bullet_list"
          />
          <b-button
            v-if="!hidden.includes('ordered_list')"
            type="is-white"
            size="is-small"
            class="mr-1 is-size-9-mobile"
            :class="{ 'is-active': isActive.ordered_list() }"
            :title="$t('ordered_list')"
            icon-left="list-ol"
            @click="commands.ordered_list"
          />
          <b-dropdown
            v-if="!hidden.includes('emoji')"
            ref="emojiDropdown"
            :position="emojiDropdownPosition"
            append-to-body
            @active-change="onEmojiDropdownToggle"
          >
            <template #trigger>
              <b-button
                type="is-white"
                class="mr-1 is-size-9-mobile"
                :title="$t('emoji')"
                icon-left="smile"
                icon-pack="far"
              />
            </template>
            <b-dropdown-item custom>
              <EmojiBox class="h-200 w-280" @select="addEmoji" />
            </b-dropdown-item>
          </b-dropdown>
          <b-button
            v-if="!hidden.includes('blockquote')"
            type="is-white"
            size="is-small"
            class="mr-1 is-size-9-mobile"
            :class="{ 'is-active': isActive.blockquote() }"
            :title="$t('blockquote')"
            icon-left="quote-left"
            icon-right="quote-right"
            @click="commands.blockquote"
          />
          <b-button
            v-if="!hidden.includes('horizontal_rule')"
            type="is-white"
            size="is-small"
            class="mr-1 is-size-9-mobile"
            :title="$t('horizontal_rule')"
            icon-left="minus"
            @click="commands.horizontal_rule"
          />
          <b-button
            v-if="!hidden.includes('table')"
            type="is-white"
            size="is-small"
            class="mr-1 is-size-9-mobile"
            :title="$t('table')"
            icon-left="table"
            @click="commands.createTable({rowsCount: 3, colsCount: 3, withHeaderRow: false })"
          />

          <template v-if="isActive.table()">
            <b-button
              size="is-small"
              class="mr-1 is-size-9-mobile"
              @click="commands.deleteTable"
            >
              {{ $t('table_actions.delete') }}
            </b-button>
            <b-button
              size="is-small"
              class="mr-1 is-size-9-mobile"
              @click="commands.addColumnBefore"
            >
              {{ $t('table_actions.insert_column_before') }}
            </b-button>
            <b-button
              size="is-small"
              class="mr-1 is-size-9-mobile"
              @click="commands.addColumnAfter"
            >
              {{ $t('table_actions.insert_column_after') }}
            </b-button>
            <b-button
              size="is-small"
              class="mr-1 is-size-9-mobile"
              @click="commands.deleteColumn"
            >
              {{ $t('table_actions.delete_column') }}
            </b-button>
            <b-button
              size="is-small"
              class="mr-1 is-size-9-mobile"
              @click="commands.addRowBefore"
            >
              {{ $t('table_actions.insert_row_before') }}
            </b-button>
            <b-button
              size="is-small"
              class="mr-1 is-size-9-mobile"
              @click="commands.addRowAfter"
            >
              {{ $t('table_actions.insert_row_after') }}
            </b-button>
            <b-button
              size="is-small"
              class="mr-1 is-size-9-mobile"
              @click="commands.deleteRow"
            >
              {{ $t('table_actions.delete_row') }}
            </b-button>
            <b-button
              size="is-small"
              class="mr-1 is-size-9-mobile"
              @click="commands.toggleCellMerge"
            >
              {{ $t('table_actions.merge_cells') }}
            </b-button>
          </template>

          <b-button
            type="is-white"
            size="is-small"
            class="mr-1 is-size-9-mobile"
            :title="$t('undo')"
            icon-left="undo"
            @click="commands.undo"
          />

          <b-button
            type="is-white"
            size="is-small"
            class="mr-1 is-size-9-mobile"
            :title="$t('redo')"
            icon-left="redo"
            @click="commands.redo"
          />
        </div>
      </template>
    </editor-menu-bar>

    <editor-content
      class="editor_content content"
      :style="{minHeight: `${this.height}px`}"
      :editor="editor"
      @click.native="onClick"
    />
  </div>
</template>

<style lang="scss" scoped>
.editor {
  overflow: hidden;
  border: 1px solid $theme_color_black_70;
  border-radius: $theme_radius_4;

  &_menu {
    width: 100%;
    border-bottom: 2px solid $theme_color_black_10;
  }

  &_content {
    overflow: auto;
    max-height: 400px;
    padding: pxToRem(8px) pxToRem(16px);
    background: $theme_color_white;
  }

  ::v-deep .ProseMirror-focused {
    border: 0;
    outline: none;
  }

  ::v-deep p.is-editor-empty:first-child::before {
    content: attr(data-empty-text);
    float: left;
    color: $theme_color_black_30;
    pointer-events: none;
    height: 0;
  }
}
</style>
