<script>
import { phone } from '@shared/config/vuelidate';
import { toBase64 } from '@shared/services/Utils';
import DeleteAccountSection from '@school/components/DeleteAccountSection.vue';

export default {
  components: { DeleteAccountSection },
  i18n: {
    messages: {
      fr: {
        displayed_nickname: 'Pseudonyme affiché dans la Communauté',
        leave_empty: 'Laissez vide pour que votre prénom soit utilisé',
        first_name: 'Prénom',
        last_name: 'Nom',
        photo: 'Photo',
        select_image: 'Sélectionnez une image',
        delete: 'supprimer',
        phone_number: 'Numéro de téléphone',
        birth_date: 'Date de naissance',
        email_change_notice: 'Veuillez contacter votre formateur si vous souhaitez modifier votre adresse email.',
        marketing_default: 'Je veux être tenu.e au courant des mises à jour et des offres',
        update: 'Mettre à jour',
        file_larger_than: 'Le fichier est plus grand que {size}',
      },
      en: {
        displayed_nickname: 'Nickname displayed in the Community',
        leave_empty: 'Leave empty to use your first name',
        first_name: 'First Name',
        last_name: 'Last Name',
        photo: 'Photo',
        select_image: 'Select an image',
        delete: 'delete',
        phone_number: 'Phone Number',
        birth_date: 'Birth Date',
        email_change_notice: 'Please contact your trainer if you need to change your email address.',
        marketing_default: 'I want to be informed about new features and offers',
        update: 'Update',
        file_larger_than: 'The file is larger than {size}',
      },
    },
  },
  data() {
    return {
      isLoading: false,
      customer: {
        display_name: '',
        firstname: '',
        lastname: '',
        birthday: '',
        picture: '',
        phone: '',
        accept_marketing: null,
      },
    };
  },
  computed: {
    store() {
      return this.$store.state.store.data;
    },
    authCustomer() {
      return this.$store.getters['auth/customer'];
    },
  },
  validations: {
    customer: {
      phone: {
        phone,
      },
    },
  },
  created() {
    this.customer.display_name = this.authCustomer.display_name;
    this.customer.firstname = this.authCustomer.firstname;
    this.customer.lastname = this.authCustomer.lastname;
    this.customer.picture = this.authCustomer.picture;
    this.customer.birthday = (this.authCustomer.birthday && this.$moment(this.authCustomer.birthday).toDate()) || null;
    this.customer.phone = this.authCustomer.phone;
    this.customer.accept_marketing = this.authCustomer.accept_marketing;
  },
  methods: {
    handle() {
      const customer = { ...this.customer };

      if (customer.birthday) {
        customer.birthday = this.$moment(customer.birthday).format('YYYY-MM-DD');
      }

      if (customer.picture && customer.picture.startsWith('http')) {
        delete customer.picture;
      }

      this.isLoading = true;
      this.$store.dispatch('auth/update', customer)
        .then(() => this.$showMessage.success())
        .finally(() => (this.isLoading = false));
    },
    handleUpload(file) {
      if (file.size > this.$constants.MAX_IMAGE_SIZE) {
        this.$buefy.dialog.alert(this.$t('file_larger_than', {
          size: '5 Mo',
        }));
        return;
      }

      const loader = this.$buefy.loading.open({ container: this.$refs.form });
      toBase64(file)
        .then((base64) => (this.customer.picture = base64))
        .finally(() => loader.close());
    },
  },
};
</script>

<template>
  <div>
    <form ref="form" class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
      <div class="columns is-multiline">
        <div class="column is-12">
          <b-field :label="$t('displayed_nickname')">
            <b-input
              v-model="customer.display_name"
              maxlength="128"
              :has-counter="false"
              :placeholder="$t('leave_empty')"
            />
          </b-field>
        </div>
        <div class="column is-6">
          <b-field :label="$t('first_name')">
            <b-input v-model="customer.firstname" maxlength="128" :has-counter="false" required />
          </b-field>
        </div>
        <div class="column is-6">
          <b-field :label="$t('last_name')">
            <b-input v-model="customer.lastname" maxlength="128" :has-counter="false" />
          </b-field>
        </div>
        <div class="column is-12">
          <b-field :label="$t('photo')">
            <b-upload @input="handleUpload" expanded>
              <b-button tag="a" class="button" icon-left="file-image" outlined>
                {{ $t('select_image') }}
              </b-button>
            </b-upload>
          </b-field>
          <figure v-if="customer.picture" class="image bradius-4 mw-200">
            <img class="w-auto" :src="customer.picture" />
          </figure>
          <p v-if="customer.picture">
            <b-button
              type="is-text"
              class="has-text-danger tdecoration-underline"
              @click="customer.picture = ''">
              {{ $t('delete') }}
            </b-button>
          </p>
        </div>
        <div class="column is-6">
          <b-field
            :label="$t('phone_number')"
            v-bind="$getErrorProps($v.customer.phone, ['phone'])"
          >
            <b-input v-model="customer.phone" type="tel" placeholder="06XXXXXXXX" />
          </b-field>
        </div>
        <div class="column is-6">
          <b-field :label="$t('birth_date')">
            <b-datepicker
              position="is-bottom-left"
              v-model="customer.birthday"
              icon="calendar-alt"
              trap-focus
            />
          </b-field>
        </div>
        <div class="column is-12">
          <b-field type="is-info" :message="$t('email_change_notice')">
            <b-input v-model="authCustomer.email" disabled />
          </b-field>
        </div>
        <div v-if="store.accept_marketing_enabled" class="column is-12">
          <b-checkbox class="is-size-8" v-model="customer.accept_marketing">
            <template v-if="store.accept_marketing_text">
              {{ store.accept_marketing_text }}
            </template>
            <template v-else>
              {{ $t('marketing_default') }}
            </template>
          </b-checkbox>
        </div>
      </div>

      <b-field class="has-text-right">
        <b-button type="is-primary is-custom" native-type="submit" :loading="isLoading">
          {{ $t('update') }}
        </b-button>
      </b-field>
    </form>

    <DeleteAccountSection />
  </div>
</template>
