<script>
import debounce from 'lodash/debounce';
import utilsMixin from '@shared/mixins/utils';
import APIStore from '@app/services/API/Store';

const FILTERS_MAP = {
  q: 'q',
  sort_by: 'sort',
  order_by: 'order',
  page: 'page',
  training_uuid: 'uuid',
};

export default {
  name: 'Sales',
  mixins: [utilsMixin],
  head: {
    title: 'Ventes',
  },
  data() {
    return {
      isExporting: false,
      params: this.getQueryParams(),
      sales: { data: [], links: {}, meta: {} },
    };
  },
  computed: {
    store() {
      return this.$store.getters['auth/store'];
    },
  },
  watch: {
    '$route.query': {
      handler() {
        this.params = this.getQueryParams();
        this.fetchResults();
      },
    },
  },
  methods: {
    getQueryParams() {
      const { query } = this.$route;
      return {
        q: query[FILTERS_MAP.q] || '',
        sort_by: query[FILTERS_MAP.sort_by] || 'created_at',
        order_by: query[FILTERS_MAP.order_by] || 'desc',
        page: query[FILTERS_MAP.page] || 1,
      };
    },
    search(clearSortColumn = false) {
      if (clearSortColumn) {
        this.$refs.table.currentSortColumn = {};
      }

      const params = { ...this.params };
      params.q === '' && delete params.q;
      params.sort_by === '' && delete params.sort_by;

      this.$router.replace({
        query: {
          [FILTERS_MAP.q]: params.q,
          [FILTERS_MAP.sort_by]: params.sort_by,
          [FILTERS_MAP.order_by]: params.order_by,
          [FILTERS_MAP.page]: params.page,
        },
      });
    },
    fetchResults() {
      if (this.fetchPromise) {
        this.fetchPromise.cancel();
        this.fetchPromise = null;
      }

      const params = { ...this.params };
      params.q === '' && delete params.q;
      params.sort_by === '' && delete params.sort_by;

      const loader = this.$buefy.loading.open({ container: this.$refs.box });
      this.fetchPromise = APIStore.getSales(this.store.uuid, params)
        .then((data) => (this.sales = data))
        .finally(() => loader.close());

      return this.fetchPromise;
    },
    onSearch: debounce(function(value) {
      this.params.q = value.trim();
      this.search();
    }, 300),
    onSort(sort, order) {
      this.params.sort_by = sort;
      this.params.order_by = order;
      this.search();
    },
    onPageChange(page) {
      this.params.page = page;
      window.scroll(0, 0);
      this.search();
    },
    toggleOrder() {
      this.params.order_by = this.params.order_by !== 'desc' ? 'desc' : 'asc';
      this.search(true);
    },
    exportCSV() {
      this.isExporting = true;
      APIStore.exportSales(this.$store.getters['auth/store'].uuid)
        .then(() => this.$buefy.dialog.alert(`
          Un export CSV listant TOUTES vos ventes
          vous a été envoyé par email.
        `))
        .finally(() => (this.isExporting = false));
    },
  },
  mounted() {
    this.search();
  },
};
</script>

<template>
  <div class="scomp">
    <div class="level is-mobile">
      <div class="level-left">
        <h1 class="title is-size-4-mobile">
          Ventes
        </h1>
      </div>
      <div class="level-right">
        <b-tooltip label="Compatible Excel &amp; Google Sheet" position="is-left">
          <b-button
            type="is-primary"
            icon-left="file-export"
            @click="exportCSV"
            :loading="isExporting">
            Export CSV
          </b-button>
        </b-tooltip>
      </div>
    </div>

    <div class="columns is-hidden-mobile">
      <div class="column">
        <b-field label="Filtrer par">
          <b-input placeholder="email, prénom, nom, formation ou pack" expanded @input="onSearch" />
        </b-field>
      </div>
      <div class="column is-narrow">
        <b-field label="Trier par">
          <b-select v-model="params.sort_by" @input="search(true)">
            <option value="email">Email</option>
            <option value="firstname">Prénom</option>
            <option value="lastname">Nom</option>
            <option value="training_name">Produit acheté</option>
            <option value="created_at">Date d'inscription</option>
            <option value="remaining_price">Reste à payer</option>
            <option value="total_price">Montant total</option>
          </b-select>
          <p class="control">
            <b-tooltip :label="params.order_by == 'asc' ? 'croissant' : 'décroissant'">
              <b-button
                :icon-left="params.order_by == 'asc' ? 'arrow-up' : 'arrow-down'"
                @click="toggleOrder"
              />
            </b-tooltip>
          </p>
        </b-field>
      </div>
    </div>

    <div class="mb-3 level is-align-items-flex-end">
      <div class="level-left">
        <p v-if="sales.meta.total != null" class="has-text-right">
          Résultat :
          <strong>
            {{ sales.meta.total }} vente{{ sales.meta.total > 1 ? 's' : '' }}
          </strong>
        </p>
      </div>
    </div>

    <div ref="box" class="box is-relative mxw-full">
      <b-table
        ref="table"
        :data="sales.data"
        :total="sales.meta.total"
        :per-page="sales.meta.per_page"
        backend-sorting
        paginated
        backend-pagination
        @sort="onSort"
        @page-change="onPageChange">
        <b-table-column cell-class="py-5 is-size-8" v-slot="{ row }" field="created_at" label="Date" sortable>
          <b-tooltip :label="$moment.utc(row.created_at).local().format('DD/MM/YY [à] HH:mm')">
            {{ row.created_at | momentFromUTC | moment('from') }}
          </b-tooltip>
        </b-table-column>
        <b-table-column cell-class="scomp_smalltd py-5" v-slot="{ row }" field="firstname" label="Acheteur" sortable>
          <div>
            {{ row.customer_firstname }} {{ row.customer_lastname }} <br>
            <strong>{{ row.customer_email }}</strong>
          </div>
        </b-table-column>
        <b-table-column cell-class="scomp_smalltd py-5 break-words" v-slot="{ row }" field="training_name" label="Produit acheté" sortable>
          <div>
            {{ row.training_uuid ? 'Formation' : 'Pack' }} <br>
            <strong>{{ row.training_uuid ? row.training_name : row.bundle_name }}</strong>
          </div>
        </b-table-column>
        <b-table-column cell-class="scomp_smalltd py-5 break-words" v-slot="{ row }" field="remaining_price" label="Reste à payer" sortable numeric>
          <div>
            {{ row.remaining_price | formatPrice }}
          </div>
        </b-table-column>
        <b-table-column cell-class="scomp_smalltd py-5 break-words" v-slot="{ row }" field="total_price" label="Montant total" sortable numeric>
          <div>
            <b>{{ row.total_price | formatPrice }}</b>
            <br>
            <span class="is-size-8">
              <em v-if="row.payment_method_used == 'STRIPE'">Par Carte</em>
              <em v-else-if="row.payment_method_used == 'PAYPAL'">Par PayPal</em>
            </span>
          </div>
        </b-table-column>

        <template #empty>
          <p class="p-5 has-text-centered">
            Il n'y pas de résultat pour cette recherche
          </p>
        </template>
      </b-table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.scomp {
  ::v-deep &_smalltd {
    max-width: 200px;
    word-wrap: break-word !important;
    word-break: break-word !important;
  }

  @include mobile {
    ::v-deep &_smalltd {
      max-width: none;
    }
  }
}
</style>
