<script>
import ContentBlockMixin from '@shared/mixins/ContentBlock';
import APIStore from '@app/services/API/Store';
import OEmbed from './Views/OEmbed.vue';

export default {
  mixins: [ContentBlockMixin],
  components: { OEmbed },
  static: {
    data: {
      url: '',
      caption: '',
      html: '',
    },
  },
  data: () => ({ isForm: true }),
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    html() {
      return this.data.html;
    },
    linkLabel() {
      return this.$t('link_label');
    },
    captionLabel() {
      return this.$t('caption_label');
    },
  },
  methods: {
    handle() {
      return APIStore.getOEmbed(this.authStore.uuid, this.newData.url)
        .then((data) => Object.assign(this.newData, data.data));
    },
  },
  i18n: {
    messages: {
      en: {
        link_label: 'Link (https://awebsite.com) *',
        caption_label: 'Caption',
      },
      fr: {
        link_label: 'Lien (https://unsite.fr) *',
        caption_label: 'Légende',
      },
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <b-field :label="linkLabel">
        <b-input v-model="newData.url" pattern="^https?:\/\/.+" required />
      </b-field>
      <b-field :label="captionLabel">
        <b-input v-model="newData.caption" />
      </b-field>
    </template>
    <template #view>
      <OEmbed v-bind="viewProps" :data="newData" />
    </template>
  </ContentBlockBase>
</template>
