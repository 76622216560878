<script>
export default {
  data: () => ({ isOffline: false }),
  methods: {
    reload: () => window.location.reload(),
  },
  created() {
    window.addEventListener('online', () => (this.isOffline = false));
    window.addEventListener('offline', () => (this.isOffline = true));
  },
  i18n: {
    messages: {
      en: {
        offline_message: 'You are currently offline',
        reload_page: 'Reload page',
      },
      fr: {
        offline_message: 'Vous êtes actuellement hors ligne',
        reload_page: 'Recharger la page',
      },
    },
  },
};
</script>

<template>
  <div
    v-if="isOffline"
    class="
      ocomp notification is-danger is-size-8-mobile m-2 p-3
      is-flex is-align-items-center is-justify-content-center
    "
  >
    {{ $t('offline_message') }}
    <b-button
      class="valign-middle ml-3 is-hidden-mobile"
      type="is-white"
      @click.prevent="reload"
    >
      {{ $t('reload_page') }}
    </b-button>
  </div>
</template>

<style lang="scss" scoped>
.ocomp {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
}
</style>
