<script>
import ContentBlockMixin from '@shared/mixins/ContentBlock';
import ContentBlockHeadingView from '@shared/components/ContentBlocks/Views/Heading.vue';

export default {
  mixins: [ContentBlockMixin],
  components: { ContentBlockHeadingView },
  static: {
    data: { text: '', level: 2 },
  },
  data: () => ({ isForm: true }),
  computed: {
    levelLabel() {
      return this.$t('content_block_heading.level');
    },
    titleLabel() {
      return this.$t('content_block_heading.title');
    },
  },
  i18n: {
    messages: {
      en: {
        content_block_heading: {
          level: 'Level',
          title: 'Title',
          heading: 'Heading',
        },
      },
      fr: {
        content_block_heading: {
          level: 'Niveau',
          title: 'Titre',
          heading: 'Titre',
        },
      },
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <div class="columns">
        <div class="column is-3">
          <b-field :label="levelLabel">
            <b-select v-model="newData.level" expanded>
              <option
                v-for="i in 5"
                :key="i"
                :value="i + 1"
                :class="`is-size-${i + 2}`"
              >
                {{ $t('content_block_heading.heading') }} {{ i + 1 }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field :label="titleLabel">
            <b-input v-model="newData.text" required />
          </b-field>
        </div>
      </div>
    </template>
    <template #view>
      <ContentBlockHeadingView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
