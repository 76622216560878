<script>
import utilsMixin from '@shared/mixins/utils';
import VueSimpleEmbed from '@shared/components/VueSimpleEmbed.vue';

export default {
  mixins: [utilsMixin],
  components: { VueSimpleEmbed },
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
  computed: {
    altText() {
      return this.$t('uploaded_media_card.image_alt');
    },
    attachmentText() {
      return this.$t('uploaded_media_card.attachment', { size: this.$options.filters.formatSizeToMB(this.media.size) });
    },
    downloadButtonText() {
      return this.$t('uploaded_media_card.download');
    },
  },
  i18n: {
    messages: {
      en: {
        uploaded_media_card: {
          image_alt: 'image',
          attachment: 'Attachment ({size} MB)',
          download: 'Download',
        },
      },
      fr: {
        uploaded_media_card: {
          image_alt: 'image',
          attachment: 'Pièce jointe ({size} Mo)',
          download: 'Télécharger',
        },
      },
    },
  },
};
</script>

<template>
  <figure
    v-if="media.type === 'image'"
    key="image"
    class="image"
  >
    <img
      :src="media.url"
      :alt="altText"
      :width="media.width"
      :height="media.height"
    >
  </figure>
  <figure
    v-else-if="media.type === 'video'"
    key="video"
    class="image"
  >
    <vue-plyr v-if="media.source != 'embed'">
      <video
        :src="media.url"
        :width="media.width"
        :height="media.height"
      />
    </vue-plyr>
    <VueSimpleEmbed
      v-else
      :url="media.url"
      parser="plyr"
    />
  </figure>
  <figure
    v-else-if="media.type === 'audio'"
    key="audio"
    class="box p-0 image"
  >
    <vue-plyr v-if="media.source != 'embed'">
      <audio :src="media.url" />
    </vue-plyr>
    <VueSimpleEmbed
      v-else
      :url="media.url"
    />
  </figure>
  <figure
    v-else-if="media.type === 'resource'"
    key="resource"
    class="box py-2 pl-4 pr-2 image flex-between-center"
  >
    {{ attachmentText }}
    <a
      class="button is-custom ml-5"
      :href="media.url"
      target="_blank"
      rel="noreferrer noopener"
      download="download"
    >
      {{ downloadButtonText }}
    </a>
  </figure>
</template>
