<script>
import APIStore from '@school/services/API/Store';

export default {
  name: 'Activation',
  head() {
    return {
      title: this.$t('activation_title'),
    };
  },
  data() {
    return {
      isLinkValid: true,
    };
  },
  computed: {
    authCustomer() {
      return this.$store.getters['auth/customer'];
    },
  },
  created() {
    const { query } = this.$route;

    if (!query.email || !query.t) {
      this.isLinkValid = false;
      return;
    }

    APIStore.verifyEmail(query)
      .then(() => {
        if (this.authCustomer) {
          this.$store.dispatch('auth/fetch');
        }

        this.$buefy.dialog.alert(this.$t('activation_success_message'));
      })
      .catch((error) => this.$errorHandlers.axios(error))
      .finally(() => {
        this.authCustomer
          ? this.$router.push({ name: 'dashboard' })
          : this.$router.push({ name: 'login' });
      });
  },
  i18n: {
    messages: {
      en: {
        activation_title: 'Activation',
        activation_heading: 'Account Activation',
        activation_success_message: 'Your account is activated! You can now log in to your space.',
        activation_invalid_link: 'This activation link does not exist. Did you correctly copy the activation link sent to you by email?',
        activation_contact_info: 'If you encounter any issues, you can contact us by email at <strong>allo@teachizy.fr</strong>,<br>specifying the email address associated with your account.',
      },
      fr: {
        activation_title: 'Activation',
        activation_heading: 'Activation du compte',
        activation_success_message: 'Votre compte est activé ! Vous pouvez maintenant vous connecter à votre espace.',
        activation_invalid_link: 'Ce lien d\'activation n\'existe pas. Avez-vous bien copié le lien d\'activation qui vous a été envoyé par email ?',
        activation_contact_info: 'Si vous rencontrez des problèmes, vous pouvez nous contacter par email à <strong>allo@teachizy.fr</strong>,<br>en précisant l\'adresse email associée à votre compte.',
      },
    },
  },
};
</script>

<template>
  <section class="page section content is-large has-text-centered">
    <h1>{{ $t('activation_heading') }}</h1>
    <div v-if="isLinkValid" class="page_loading mt-10 mx-auto">
      <b-skeleton :count="3" size="is-large" />
    </div>
    <div v-else>
      <p>{{ $t('activation_invalid_link') }}</p>

      <p v-html="$t('activation_contact_info')"></p>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.page {
  &_loading {
    width: 50%;
  }
}
</style>
