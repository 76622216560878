<script>
import utilsMixin from '@shared/mixins/utils';

export default {
  mixins: [utilsMixin],
  props: {
    customer: {
      type: Object,
      default: null,
    },
    training: {
      type: Object,
      required: true,
    },
  },
  computed: {
    price() {
      return this.training.discount_price == null
        ? this.training.price
        : this.training.discount_price;
    },
    reduction() {
      return 100 - Math.round((this.training.discount_price / this.training.price) * 100);
    },
  },
  methods: {
    addToCart(isSinglePrice = true) {
      const options = isSinglePrice ? undefined : 1;
      this.$router.push({ query: { acheter: 1, options } });

      if (!isSinglePrice) {
        return;
      }

      this.$gtag.addToCart({
        items: [{
          id: this.training.uuid,
          name: this.training.name,
          quantity: 1,
          value: this.price,
        }],
      });
      this.$fbq.addToCart({
        content_ids: [this.training.uuid],
        content_name: this.training.name,
        value: this.price,
      });
    },
    theme() {
      return this.$t('theme');
    },
  },
  i18n: {
    messages: {
      en: {
        theme: 'Access',
        access: 'Access',
        pay_in_installments: 'Pay in installments',
        or: 'OR',
        get_for: 'Get for {price}',
        access_free: 'Access for free',
        instead_of: 'Instead of {price}',
        discount_percentage: '{reduction}% discount',
      },
      fr: {
        theme: 'Accéder',
        access: 'Accéder',
        pay_in_installments: 'Payer en plusieurs fois',
        or: 'OU',
        get_for: 'Obtenir pour {price}',
        access_free: 'Accéder gratuitement',
        instead_of: 'Au lieu de {price}',
        discount_percentage: '{reduction}% de réduction',
      },
    },
  },
};
</script>

<template>
  <div class="comptbb">
    <template v-if="customer && training.customer_type == 'STUDENT'">
      <b-button
        class="comptbb_cta"
        type="is-primary is-custom"
        size="is-large"
        tag="router-link"
        :to="{ name: 'mytraining', params: { slug: training.slug } }"
        expanded
      >
        {{ $t('access') }}
      </b-button>
    </template>
    <template v-else>
      <template v-if="price > 0 && training.payment_schedules.length">
        <b-button
          class="comptbb_cta"
          type="is-primary is-custom"
          size="is-large"
          expanded
          @click="addToCart(false)"
        >
          {{ $t('pay_in_installments') }}
        </b-button>
        <p class="p-2 has-text-weight-bold">
          {{ $t('or') }}
        </p>
      </template>
      <b-button
        class="comptbb_cta"
        type="is-primary is-custom"
        size="is-large"
        expanded
        @click="addToCart"
      >
        <template v-if="price > 0">
          {{ $t('get_for', { price: $options.filters.formatPrice(price) }) }}
        </template>
        <template v-else>
          {{ $t('access_free') }}
        </template>
      </b-button>
      <template v-if="training.discount_price != null">
        <p class="mt-5 is-size-7 is-size-9-mobile">
          <span class="tdecoration-line-through">
            {{ $t('instead_of', { price: $options.filters.formatPrice(training.price) }) }}
          </span>
          <br>
          {{ $t('discount_percentage', { reduction }) }}
        </p>
        <p v-if="training.discount_price_reason" class="opacity-70 is-size-8">
          {{ training.discount_price_reason }}
        </p>
      </template>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.comptbb {
  @include mobile {
    &_cta {
      font-size: $size-7;
    }
  }
}
</style>
